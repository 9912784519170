.v2cursorLists {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1009;
    pointer-events: none;

    &__item {
        position: absolute;
        width: 260px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0 0 2px 0 #d8dce4;
        transition: $trans;
        pointer-events: visible;

        &:not(._show) {
            opacity: 0;

            &._bottom {
                transform: translate(0, 3px);
            }

            &._top {
                transform: translate(0, -3px);
            }
        }

        &::before {
            position: absolute;
            z-index: 3;
            width: 12px;
            height: 12px;
            background: #fff;
            box-shadow: 0 0 2px 0 #d8dce4;
            content: "";
            pointer-events: none;
        }

        &::after {
            position: absolute;
            z-index: 4;
            width: 40px;
            height: 12px;
            background: #fff;
            content: "";
            pointer-events: none;
        }

        &._bottom::before {
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &._bottom::after {
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

        &._top::before {
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%) rotate(45deg);
        }

        &._top::after {
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

        &Button {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            padding: 16px;
            background: linear-gradient(to top, #fff, rgba(#fff, 0));
            border-radius: 0 0 16px 16px;
            transition: $trans;
            pointer-events: none;

            & .v2button {
                pointer-events: visible;
            }
        }
    }
}
