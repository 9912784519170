.windowList {
    @extend %shadow;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    background: #fff;
    position: relative;
    z-index: 1;

    &__inner {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 14px;
        z-index: 1;
    }

    &__head {
        width: 100%;
        height: 60px;
        padding: 12px 14px;
        border-bottom: 1px solid $colorBlue;
    }

    &__search {
        width: 100%;
        height: 100%;
    }

    &__content {
        @extend %noscroll;
        width: 100%;
        overflow-y: auto;
        border-radius: 0 0 14px 14px;
        position: relative;
        transition: $trans;
        z-index: 1;

        &Inner {
            width: 100%;
            transition: $trans;
        }
    }

    &._choice &__contentInner {
        padding-bottom: 74px;
    }

    &._choice._withLink &__contentInner {
        padding-bottom: 94px;
    }

    &._withLink &__contentInner {
        padding-bottom: 32px;
    }

    &__items {
        width: 100%;
        position: relative;
        transition: $trans;

        &._static {
            display: flex;
            flex-direction: column;
        }
    }

    &:not(._ready) &__items {
        opacity: 0;
    }

    &__items._static &__item {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__item {
        width: 100%;
        align-items: center;
        padding: 12px 16px;
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;
        background: #fff;
        z-index: 1;
        border-bottom: 1px solid transparent;

        &:not(._show) {
            opacity: 0;
        }

        &._current {
            background: $colorBlueBack;
            z-index: 2;
        }

        &:not(._last) {
            border-bottom-color: $colorBlue;
        }

        &Preview {
            width: 36px;
            height: 36px;
            border-radius: 8px;
            justify-content: center;
            align-items: center;
            background: $colorBlueBack;
            transition: $trans;
            position: relative;
            z-index: 1;
            overflow: hidden;

            &Icon {
                width: 100%;
                height: 100%;
                object-fit: contain;

                & .avatar__icon {
                    width: 100%;
                    height: 100%;
                }

                & .avatar__item {
                    font-size: 16px;
                }
            }
        }

        &._current &Preview {
            background: #fff;
        }

        &Content {
            width: calc(100% - 36px - 30px);
            padding: 0 12px 0 12px;
            font-size: 13.5px;
            line-height: 1.3;
            font-weight: 500;
            color: $colorDark2;

            & span {
                font-weight: 600;
            }
        }

        &Action {
            width: 30px;
            height: 30px;
        }
    }

    &._cars &__item {
        &Preview {
            &Icon {
                width: 30px;
                height: 30px;
            }
        }
    }

    &__items._static &__item {
        position: relative;

        &:not(._show) {
            display: none;
        }

        &._current {
            order: -1;
        }
    }

    &__foot {
        position: absolute;
        z-index: 4;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px 16px 16px 16px;
        background: linear-gradient(to bottom, rgba(#fff, 0) 0%, rgba(#fff, 1) 35%, rgba(#fff, 1) 100%);
        transition: $trans;

        &:not(._show) {
            opacity: 0;
            transform: scale(1.05);
            pointer-events: none;
        }
    }

    &._withLink &__foot {
        bottom: 44px;
        padding-bottom: 0;
    }

    &__button {
        width: 100%;
        height: 42px;
        margin-top: auto;
    }

    &__link {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 44px;
        padding: 0 16px;
        z-index: 3;
        justify-content: center;
        align-items: center;
        background: #fff;
        background: linear-gradient(to bottom, rgba(#fff, 0) 0%, rgba(#fff, 1) 35%, rgba(#fff, 1) 100%);
        transition: $trans;
        pointer-events: none;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: #fff;
            opacity: 0;
            transition: $trans;
        }

        &Inner {
            font-size: 14.5px;
            color: $colorMain;
            pointer-events: visible;
        }
    }

    &._choice &__link {
        pointer-events: visible;

        &::before {
            opacity: 1;
        }
    }
}