.v2switch {
    display: block;
    width: 100%;
    height: 100%;

    &__inner {
        position: relative;
        width: 42px;
        height: 26px;
        background: $colorBlue;
        border-radius: 360px;
        transition: $trans;

        @include hover {
            &:hover {
                // background: #ccdfe9;
            }
        }

        @include button;
    }

    &._green &__inner {
        background: #e1e7ee;
    }

    &._mediumSize &__inner {
        width: 36px;
        height: 22px;
    }

    &__point {
        position: absolute;
        top: 50%;
        left: 6px;
        width: 14px;
        height: 14px;
        background: #fff;
        border-radius: 50%;
        transform: translate(0, -50%);
        transition: $trans;
    }

    &._mediumSize &__point {
        left: 5px;
        width: 12px;
        height: 12px;
    }

    &__input {
        display: none;
    }

    &__input:checked ~ &__inner {
        background: $colorMain;
    }

    &._green &__input:checked ~ &__inner {
        background: $colorSuccess;
    }

    &__input:checked ~ &__inner &__point {
        left: calc(100% - 6px);
        transform: translate(-100%, -50%);
    }

    &._mediumSize &__input:checked ~ &__inner &__point {
        left: calc(100% - 5px);
    }
}
