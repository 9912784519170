.chatFile {
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 12px;
    transition: $trans;

    @include forType("app", "light") {
        background: $colorBlueBack;
    }

    @include forType("app", "dark") {
        background: #3e454c;
    }

    @include theme("light") {
        background: #fff;
    }

    @include theme("dark") {
        background: $colorDarkTheme2;
    }

    &._white {
        background: #fff !important;
        border: 1px solid rgba($colorBlue, 0.5);
    }

    &:not(._inMessage) {
        @include forType("crm", "light") {
            background: $colorBlueBack;
        }

        @include forType("crm", "dark") {
            background: #fff;
        }
    }

    &._inMessage {
        @include theme("light") {
            background: none;
        }

        @include theme("dark") {
            background: none;
        }
    }

    .chatWindow._fix &:not(._inMessage) {
        @include theme("light") {
            background: #fafafa;
        }

        @include theme("dark") {
            background: $colorDarkTheme2;
        }
    }

    &:not(._inMessage):hover {
        // background: #eef1f5;
    }

    &__delete {
        position: absolute;
        top: 4px;
        right: 4px;
        z-index: 2;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 6px;
        transform: translate(50%, -50%);
        visibility: hidden;
        opacity: 0;
        transition: $trans;

        @include media(0, $mediaM) {
            top: 6px;
            right: 6px;
            width: 24px;
            height: 24px;
            visibility: visible;
            opacity: 1;
        }

        @include theme("light") {
            background: #fff;
            box-shadow: 0 0 2px 0 rgb(173 183 197 / 64%);
        }

        @include theme("dark") {
            background: $colorDarkTheme3;
            box-shadow: 0 0 2px 0 rgba(#000, 0.5);
        }

        &Icon {
            width: 14px;
            height: 14px;

            @include icon($colorAlert);
        }
    }

    &:hover &__delete {
        visibility: visible;
        opacity: 1;
    }

    &__preview {
        position: relative;
        z-index: 2;
        width: 32px;
        height: 32px;
        overflow: hidden;
        border-radius: 10px;

        @include forType("app", "light") {
            background: #fff;
        }

        @include forType("app", "dark") {
            background: #1c1f21;
        }

        @include forType("crm", "light") {
            background: #fff;
        }

        @include media(0, $mediaM) {
            width: 38px;
            height: 38px;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: none;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.3);
            content: "";

            @include forType("app") {
                display: none;
            }
        }
    }

    &._inMessage &__preview {
        background: #fff;

        @include forType("crm", "light") {
            background: #fff;
        }

        @include forType("crm", "dark") {
            background: #fff;
        }

        @include forType("app", "light") {
            background: $colorBlueBack;
        }

        .chatMessage._me & {
            @include forType("app", "light") {
                background: #fff;
            }
        }

        .chatWindow._fix & {
            @include forType("crm", "light") {
                background: #fff;
            }

            @include forType("crm", "dark") {
                background: #fff;
            }
        }

        &::before {
            display: none;
        }
    }

    &._inMessage._me &__preview {
        @include forType("crm", "light") {
            background: #fff;
        }

        @include forType("crm", "dark") {
            background: #fff;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        width: 18px;
        height: 18px;
        transform: translate(-50%, -50%);

        @include icon(rgba(#fff, 0.9));

        @include media(0, $mediaM) {
            width: 22px;
            height: 22px;
        }
    }

    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 22px;
        height: 22px;
        transform: translate(-50%, -50%);

        @include icon(#b5bdc5);
    }

    &__info {
        width: calc(100% - 32px);
        padding-left: 8px;

        @include media(0, $mediaM) {
            width: calc(100% - 38px);
        }
    }

    .chatMessage._me &._inMessage &__size {
        padding-right: 26px;
    }

    .chatMessage._me &._inMessage &__name {
        padding-right: 4px;
    }

    &__name {
        max-width: 80px;
        margin-bottom: 2px;
        overflow: hidden;
        font-weight: 600;
        font-size: 13px;
        line-height: 1.2;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include media(0, $mediaM) {
            max-width: 100%;
            padding-right: 10px;
            font-size: 15px;
        }

        @include theme("light") {
            color: $colorDark;
        }

        @include theme("dark") {
            color: #fff;
        }

        .chatMessage._me & {
            color: #fff;
        }
    }

    &._inMessage &__name,
    &._default &__name {
        max-width: 100%;
    }

    &__size {
        max-width: 100%;
        font-weight: 300;
        font-size: 12px;

        @include media(0, $mediaM) {
            font-size: 13.5px;
        }

        @include theme("light") {
            color: #777;
        }

        @include theme("dark") {
            color: $colorDarkThemeText;
        }

        .chatMessage._me & {
            color: #fff;
        }
    }

    &:not(._me) &__audio {
        &Button {
            @include theme("light") {
                background: $colorMain;
            }

            @include theme("dark") {
                background: rgba(#f5f7fa, 0.48);
            }

            &Icon {
                @include theme("light") {
                    @include icon(#fff);
                }

                @include theme("dark") {
                    @include icon($colorDark);
                }
            }
        }

        &Progress {
            &Line {
                @include theme("light") {
                    background: rgba(#3e454c, 0.24);
                }

                @include theme("dark") {
                    background: rgba(#fff, 0.24);
                }

                &Active {
                    @include theme("light") {
                        background: $colorDark;
                    }

                    @include theme("dark") {
                        background: #fff;
                    }

                    &Dot {
                        @include theme("light") {
                            background: $colorDark;
                        }

                        @include theme("dark") {
                            background: #fff;
                        }
                    }
                }
            }

            &Duration {
                @include theme("light") {
                    color: $colorDark;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }
        }
    }

    &__audio {
        position: relative;
        align-items: center;
        width: 100%;
        padding: 8px 0 8px 40px;

        &Input {
            display: none;
        }

        &Button {
            position: relative;
            position: absolute;
            top: 50%;
            left: 0;
            width: 32px;
            height: 32px;
            background: #fff;
            border-radius: 50%;
            transform: translate(0, -50%);

            &Icon {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 14px;
                height: 14px;
                transform: translate(-50%, -50%);
                transition: $trans;

                @include icon($colorMain);

                &:not(._show) {
                    opacity: 0;
                }

                &._pause {
                    &:not(._show) {
                        transform: translate(-50%, -50%) rotate(-90deg);
                    }
                }

                &._play {
                    margin-left: 1px;

                    &:not(._show) {
                        transform: translate(-50%, -50%) rotate(90deg);
                    }
                }
            }
        }

        &Progress {
            width: 100%;
            min-width: 132px;
            padding-right: 6px;

            &Line {
                width: 100%;
                height: 2px;
                background: rgba(#fff, 0.32);
                border-radius: 360px;

                &Active {
                    position: relative;
                    width: 0;
                    height: 100%;
                    background: #fff;
                    border-radius: 360px;

                    &Dot {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        z-index: 2;
                        width: 6px;
                        height: 6px;
                        background: #fff;
                        border-radius: 50%;
                        transform: translate(100%, -50%);
                    }
                }
            }

            &Duration {
                margin: 6px 0 -6px;
                color: #fff;
                font-size: 11px;
            }
        }
    }
}
