.alert {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 12px 16px 12px 62px;
    border: 2px solid transparent;
    border-radius: 12px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    user-select: none;

    @include theme('light') {
        border-color: #fff;
    }

    @include theme('dark') {
        border-color: $colorDarkTheme2;
    }

    &._error {
        @include theme('light') {
            border-color: #fff;
        }

        @include theme('dark') {
            border-color: #270702;
        }
    }

    @include forType('crm') {
        border-width: 0;
        padding: 18px 50px 18px 18px;
        min-height: 76px;
        justify-content: center;
        border-radius: 17px;

        @include media(0, $mediaM) {
            border-radius: 20px;
            padding: 20px;
        }
    }

    @include forType('app') {
        border-width: 0;
        padding: 18px 50px 18px 18px;
        min-height: 76px;
        justify-content: center;
        border-radius: 17px;

        @include media(0, $mediaM) {
            border-radius: 20px;
            padding: 20px;
        }
    }

    &._prompt {
        @include forType('crm') {
            padding-left: 24px;
            padding-right: 36px;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 3px;
                height: calc(100% - 40px);
                transform: translate(0, -50%);
                background: #FFB300;
                border-radius: 0 10px 10px 0;
                z-index: 2;
            }
        }

        @include forType('app') {
            padding-left: 24px;
            padding-right: 44px;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 3px;
                height: calc(100% - 40px);
                transform: translate(0, -50%);
                background: #FFB300;
                border-radius: 0 10px 10px 0;
                z-index: 2;
            }
        }
    }

    @include media(0, $mediaM) {
        border-radius: 20px;
        padding: 16px 16px 16px 72px;
    }

    @keyframes tremor {
        0% {
            transform: translate(0, 0);
        }

        25% {
            transform: translate(-3px, 0);
        }

        50% {
            transform: translate(0, 0);
        }

        75% {
            transform: translate(3px, 0);
        }

        100% {
            transform: translate(0, 0);
        }
    }

    &._success {
        @include theme('light') {
            background: $colorBlueActive;
        }

        @include theme('dark') {
            background: $colorDarkTheme3;
        }

        @include forType('crm') {
            background: $colorMain;
        }

        @include forType('app') {
            background: $colorMain;
        }
    }

    &._error {
        @include theme('light') {
            background: #fff3f3;
        }

        @include theme('dark') {
            background: #3c0c03;
        }

        @include forType('crm') {
            background: #d6624a;
        }

        @include forType('app') {
            background: #d6624a;
        }
    }

    &._prompt {
        background: #fff;
    }

    &._isTremor {
        animation: tremor .2s 4 ease-out;
    }

    &._noIcon {
        padding-left: 16px;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 13px;
        width: 36px;
        height: 36px;
        object-fit: contain;
        transform: translate(0, -50%);

        @include forType('crm') {
            left: 16px;
            width: 36px;
            height: 36px;

            @include media(0, $mediaM) {
                width: 44px;
                height: 44px;
            }
        }

        @include forType('app') {
            left: 16px;
            width: 36px;
            height: 36px;

            @include media(0, $mediaM) {
                width: 44px;
                height: 44px;
            }
        }

        @include media(0, $mediaM) {
            width: 44px;
            height: 44px;
        }
    }

    &__close {
        position: absolute;
        top: 6px;
        right: 6px;
        z-index: 2;
        width: 12px;
        height: 12px;
        cursor: pointer;

        @include theme('light') {
            @include icon($colorDark);
        }

        @include theme('dark') {
            @include icon(#fff);
        }

        @include forType('crm') {
            width: 14px;
            height: 14px;
            right: 14px;
            top: 14px;
            @include icon(#fff);

            @include media(0, $mediaM) {
                width: 20px;
                height: 20px;
                top: 20px;
                right: 20px;
            }
        }

        @include forType('app') {
            width: 14px;
            height: 14px;
            right: 18px;
            top: 18px;
            @include icon(#fff);

            @include media(0, $mediaM) {
                width: 20px;
                height: 20px;
                top: 20px;
                right: 20px;
            }
        }

        @include mediaMin($mediaM+1px) {
            &:hover {
                @include icon($colorMain);
            }
        }

        @include media(0, $mediaM) {
            width: 16px;
            height: 16px;
            top: 12px;
            right: 12px;
        }
    }

    &._prompt &__close {
        @include forType('crm') {
            @include icon($colorDark);
        }

        @include forType('app') {
            @include icon($colorDark);
        }
    }

    &__content {
        width: 100%;
    }

    &._withActions &__content {
        @extend %row;
        align-items: center;
    }

    &__title {
        width: 100%;
        padding-right: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.4;

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }

        @include forType('crm') {
            color: #fff;
            font-size: 14.5px;
            line-height: 1.7;
            font-weight: 600;
            padding-right: 0;

            @include media(0, $mediaM) {
                font-size: 18px;
                max-width: 320px;
                padding-right: 20px;
            }
        }

        @include forType('app') {
            color: #fff;
            font-size: 14.5px;
            line-height: 1.7;
            font-weight: 600;

            @include media(0, $mediaM) {
                font-size: 18px;
                max-width: 320px;
                padding-right: 20px;
            }
        }

        @include media(0, $mediaM) {
            font-size: 18px;
            max-width: 200px;
        }

        & b {
            font-weight: 900;
        }
    }

    &._prompt &__title {
        @include forType('crm') {
            color: $colorDark;
        }
    }

    &__actions {
        width: calc(100% + 26px);
        align-items: center;
        margin-top: 12px;
    }

    &__action {
        align-items: center;
        justify-content: center;
        padding: 8px 12px 9px 12px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
        transition: $trans;
        user-select: none;
        @include button();
        position: relative;
        z-index: 1;
        overflow: hidden;

        &._continue {
            background: $colorMain;
            color: #fff;
        }

        &._cancel {
            background: #F1F5FF;
            color: $colorMain;
        }

        &+& {
            margin-left: 6px;
        }

        &Loader {
            background: inherit;

            &Icon {
                width: 18px;
                height: 18px;
            }
        }
    }

    &__time {
        position: absolute;
        bottom: 18px;
        right: 18px;
        font-size: 12px;
        font-weight: 500;
        color: #fff;

        @include media(0, $mediaM) {
            bottom: 20px;
            right: 20px;
            font-size: 16px;
        }
    }

    &__description {
        max-width: 100%;
        margin-top: 3px;
        font-size: 13px;
        line-height: 1.65;
        font-weight: 500;
        color: $colorDark;

        & span {
            font-weight: 600;
            color: $colorMain;
        }
    }
}