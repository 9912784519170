.contract {
    width: 100%;
    height: 36px;
    border-radius: 10px;
    position: relative;
    align-items: center;
    padding: 8px 14px;
    z-index: 1;
    transition: $trans;

    @include theme('light') {
        background: #EEF2FF;
    }

    @include theme('dark') {
        background: #293653;
    }

    &__icon {
        width: 16px;
        height: 16px;

        @include theme('light') {
            @include icon($colorMain);
        }

        @include theme('dark') {
            @include icon(#AEC2FF);
        }
    }

    &__name {
        width: calc(100% - 16px);
        padding-left: 6px;
        font-size: 14px;
        line-height: 1.3;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 1px;
        transition: $trans;

        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: #AEC2FF;
        }
    }
}