.pdfFnsReceipt {
    width: 100%;

    &__head {
        width: 100%;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba($colorDark2, .2);
    }

    &__title {
        max-width: 100%;
        font-size: 20px;
        font-weight: 900;
        color: $colorDark2;
        margin-bottom: 10px;
    }

    &__date {
        font-size: 14px;
        line-height: 1.3;
        color: $colorGreyDark;
    }

    &__executor {
        width: 100%;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba($colorDark2, .2);

        &Name {
            max-width: 100%;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 1.3;
            color: $colorDark2;
        }
    }

    &__info {
        width: 100%;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba($colorDark2, .2);

        &Block {
            width: calc(60% - 6px);

            &:last-child:not(:first-child) {
                width: 40%;
                align-items: flex-end;
                text-align: right;
            }
        }

        &Text {
            max-width: 100%;
            font-size: 14px;
            line-height: 1.3;
            color: $colorDark2;

            &+& {
                margin-top: 6px;
            }

            &._head {
                font-weight: 600;
            }
        }
    }

    &__total {
        width: 100%;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba($colorDark2, .2);

        &Text {
            font-size: 16px;
            font-weight: 900;
            color: $colorDark2;
        }
    }

    &__qr {
        width: 100%;
        align-items: center;

        & canvas {
            width: 140px !important;
            height: 140px !important;
        }
    }
}