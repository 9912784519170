.logoAnimate {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: contain;
        transform: translate3d(0, 0, 0);
        will-change: transform;
    }
}