.icon {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: $trans;

    &:not(._show) {
        opacity: 0;
    }

    & svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    & ellipse,
    & path,
    & circle,
    & line,
    & polyline {
        transition: $trans;
    }

    &__theme {
        @include theme("light") {
            fill: #fff;
        }

        @include theme("dark") {
            fill: #000;
        }
    }
}
