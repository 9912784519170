.v2popupSign {
    width: 100%;

    &__inner {
        width: calc(100% + 64px);
        margin: 22px -32px 32px;
        border-top: 1px solid $colorBlue;
        border-bottom: 1px solid $colorBlue;
    }

    &__table {
        width: 100%;
        height: 270px;

        & .v2table__row {
            padding-right: 32px !important;
            padding-left: 32px !important;
        }
    }

    &__codes {
        align-items: center;
        width: 100%;
        margin: 24px 0 32px;

        &Box {
            margin-right: 16px;
        }

        &Support {
            max-width: 100%;
            margin-bottom: 8px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 15px;
        }

        &Btn {
            position: relative;
            color: $colorMain;
            font-size: 14px;
            transition: $trans;

            @include button(0.97);

            &Loader {
                left: 100%;
                width: auto;
                width: 16px;
                height: 16px;
                margin-left: 5px;

                @include loader($colorMain);
            }
        }
    }

    &__actions {
        position: absolute;
        right: 32px;
        bottom: 32px;
        z-index: 2;
        align-items: center;
        height: 48px;

        &Auto {
            position: relative;
            margin-right: 20px;
            padding-left: 42px;

            &Switch {
                position: absolute;
                top: 0;
                left: 0;
            }

            &Title {
                max-width: 100%;
                margin-bottom: 5px;
                padding-top: 1px;
                color: $colorDark2;
                font-weight: 600;
                font-size: 14px;
            }

            &Description {
                max-width: 100%;
                color: $colorDark2;
                font-weight: 300;
                font-size: 13px;
                line-height: 1.4;
            }
        }

        &Dots {
            align-items: center;
        }

        &Dot {
            width: 8px;
            height: 8px;
            background: $colorMain;
            border-radius: 50%;
            transition: $trans;

            &:not(._current) {
                opacity: 0.32;
            }

            & + & {
                margin-left: 6px;
            }
        }
    }

    &__foot {
        width: 100%;

        // padding: 32px;
    }

    &__button {
        width: 280px;

        &._full {
            width: 100%;
        }
    }
}
