.newYearCursor {
    position: fixed;
    z-index: 99999999;
    width: 32px;
    height: 32px;
    user-select: none;
    pointer-events: none;

    @include mob {
        opacity: 0;
    }

    &:not(._show) {
        opacity: 0;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;

        &._pointer {
            top: 2px;
            left: -7px;
        }

        &:not(._show) {
            display: none;
        }
    }
}
