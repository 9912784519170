.appGalery {
    width: 100%;
    height: 100%;
    position: relative;

    &__inner {
        width: 100%;
        height: 100%;
        justify-content: center;
    }

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 38px;
        height: 38px;
        border-radius: 10px;
        border: 1px solid transparent;
        justify-content: center;
        align-items: center;
        z-index: 2;

        @include theme('light') {
            background: #fff;
            border-color: #e3e3e3;
        }

        @include theme('dark') {
            background: #25292C;
            border-color: #1C1F21;
        }

        &Icon {
            width: 20px;
            height: 20px;

            @include theme('light') {
                @include icon($colorDark2);
            }

            @include theme('dark') {
                @include icon(#fff);
            }
        }
    }

    &__image {
        width: 100%;
        max-height: calc(100% - 100px);
        object-fit: contain;
        position: relative;
        z-index: 1;
    }
}