.v2popupChatTemplateFoot {
    width: 100%;
    padding: 24px;
    border-top: 1px solid $colorBlue;

    &__inner {
        align-items: center;
    }

    &__button {
        width: 220px;
    }

    &__info {
        width: calc(100% - 220px);
        padding-left: 16px;

        &Support {
            margin-bottom: 6px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 15px;
        }

        &Result {
            width: 100%;

            &Inner {
                max-width: 100%;
                overflow: hidden;
                color: $colorMain;
                font-size: 14px;
                line-height: 1.4;
                white-space: nowrap;
                text-overflow: ellipsis;

                &._grey {
                    color: $colorGreyDark;
                }
            }
        }
    }
}
