.calendar {
    width: 100%;

    &__head {
        align-items: center;
        width: 100%;
        height: 20px;
        margin-bottom: 30px;
    }

    &._filter &__head {
        margin-bottom: 12px;
    }

    &__title {
        height: 100%;
        position: relative;
        max-width: 60%;

        &Inner {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            white-space: nowrap;
            font-weight: 600;
            font-size: 19px;
            align-items: center;
            transition: $trans;
            opacity: 0;

            @include media(0, $mediaM) {
                font-size: 20px;
            }

            @include theme('dark') {
                color: #fff;
            }

            @include theme('light') {
                color: $colorDark;
            }

            &._prev {
                transform: translate(0, -20px);
            }

            &._next {
                transform: translate(0, 20px);
            }

            &._show {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }

    &._pay &__titleInner {
        font-size: 18px;
    }

    &__selects {
        align-items: center;
    }

    &__select {
        &._months {
            width: 90px;
            margin-right: 8px;
        }
    }

    &__actions {
        align-items: center;
        margin: 0 -6px 0 auto;
    }

    &__action {
        width: 18px;
        height: 18px;
        transition: $trans;

        @include mediaMin($mediaM + 1px) {
            &:hover {
                opacity: .5;
            }
        }

        @include media(0, $mediaM) {
            width: 20px;
            height: 20px;
        }

        @include theme('dark') {
            @include icon(#fff);
        }

        @include theme('light') {
            @include icon($colorDark);
        }

        &+& {
            margin-left: 14px;
        }
    }

    &._pay &__action {
        width: 16px;
        height: 16px;
    }

    &._filter &__action {
        width: 14px;
        height: 14px;
    }

    &._filter &__action+&__action {
        margin-left: 8px;
    }

    &__days {
        width: 100%;
        margin-bottom: 14px;
    }

    &._filter &__days {
        margin-bottom: 6px;
    }

    &__day {
        align-items: center;
        text-align: center;
        width: calc(100% / 7);
        font-size: 16px;
        font-weight: 500;

        @include media(0, $mediaM) {
            font-size: 17px;
        }

        @include theme('dark') {
            color: rgba(#fff, .7);
        }

        @include theme('light') {
            color: #3e454c;
        }
    }

    &__inner {
        width: 100%;
        position: relative;
    }

    &._filter &__inner,
    &._pay &__inner {
        transition: $trans;
    }

    &__box {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__month {
        flex-wrap: wrap;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 50px);
        padding: 0 25px;
        margin-left: -25px;
        transition: $trans;

        @include media(0, $mediaM) {
            width: calc(100% + #{$paddingMobile} * 2);
            padding: 0 20px;
            margin-left: -20px;
        }

        &._prev {
            transform: translate(-100%, 0)
        }

        &._next {
            transform: translate(100%, 0)
        }

        &._show {
            transform: translate(0, 0);
        }

        &Day {
            display: block;
            width: calc(100% / 7);
            height: 38px;
            padding: 1.5px;

            @include media(0, $mediaM) {
                height: 12vw;
                max-height: 50px;
            }

            &._other:not(._isAllDates) {
                pointer-events: none;
            }

            &Input {
                display: none;
            }

            &View {
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-size: 16px;
                font-weight: 500;
                border-radius: 8px;
                transition: $trans;
                border: 1px solid transparent;
                @include button(.95);

                @include media(0, $mediaM) {
                    font-size: 19px;
                    border-radius: 10px;
                }

                @include theme('light') {
                    background: $colorGreyLight;
                    color: $colorDark2;
                }

                @include theme('dark') {
                    background: $colorDarkTheme2;
                    color: #fff;
                }

                @include forType('crm', 'light') {
                    background: $colorBlueBack;
                }

                @include forType('crm', 'dark') {
                    background: $colorDarkTheme2;
                }
            }

            &._other &View {
                background: none;

                @include theme('dark') {
                    color: rgba(#fff, .7);
                }

                @include theme('light') {
                    color: #9da8b4;
                }
            }

            &._current &View {
                font-weight: 600;

                @include theme('dark') {
                    color: $colorMainTheme;
                }

                @include theme('light') {
                    color: $colorMain;
                }
            }

            &._disabled &View {
                color: #9da8b4;
                pointer-events: none;
            }

            &:not(._disabled) &View {
                @include mediaMin($mediaM + 1px) {
                    &:hover {
                        @include theme('dark') {
                            background: $colorMainDark;
                            border-color: $colorMainDark;
                            color: #fff;
                        }

                        @include theme('light') {
                            background: $colorMainDark;
                            border-color: $colorMainDark;
                            color: #fff;
                        }
                    }
                }
            }

            &:not(._disabled) &Input:checked~&View {
                font-weight: 600;

                @include theme('dark') {
                    background: $colorMain;
                    border-color: $colorMain;
                    color: #fff;
                }

                @include theme('light') {
                    background: $colorMain;
                    border-color: $colorMain;
                    color: #fff;
                }
            }

            &._beetween &View {
                @include theme('light') {
                    border-color: $colorMain;
                    background: #fff;
                    color: $colorMain;
                }

                @include theme('dark') {
                    border-color: $colorDarkThemeText;
                    background: $colorDarkTheme2;
                    color: $colorDarkThemeText;
                }
            }
        }
    }

    &._filter &__month {
        width: calc(100% + #{$paddingMobile} * 2);
        padding: 0 20px;
        margin-left: -20px;
    }
}