.pdfPayAct {
    width: 100%;

    &__title {
        width: 100%;
        font-size: 20px;
        line-height: 1.3;
        font-weight: 900;
        color: $colorDark2;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($colorDark2, .2);
        margin-bottom: 20px;
    }

    &__infos {
        width: 100%;
        margin-bottom: 20px;
    }

    &__info {
        width: 100%;

        &+& {
            margin-top: 16px;
        }

        &Support {
            width: 100px;
            font-size: 14px;
            line-height: 1.3;
            color: $colorGreyDark;
        }

        &Value {
            width: calc(100% - 100px);
            padding-left: 20px;
            font-size: 14px;
            line-height: 1.3;
            color: $colorDark2;
        }
    }

    &__table {
        width: 100%;
        border: 1px solid rgba($colorDark2, .2);
        margin-bottom: 10px;

        &Row {
            width: 100%;
            align-items: stretch;

            &+& {
                border-top: 1px solid rgba($colorDark2, .2);
            }
        }

        &Row._head &Col {
            font-weight: 600;
        }

        &Col {
            padding: 10px;
            font-size: 14px;
            color: $colorDark2;

            &+& {
                border-left: 1px solid rgba($colorDark2, .2);
            }

            &._number {
                width: 6%;
            }

            &._name {
                width: 50%;
            }

            &._counter {
                width: 12%;
            }

            &._counterName {
                width: 7%;
            }

            &._price {
                width: 12%;
            }

            &._total {
                width: 13%;
            }
        }
    }

    &__amount {
        width: 100%;
        align-items: flex-end;
        margin-bottom: 32px;

        &Row {
            &+& {
                margin-top: 4px;
            }
        }

        &Col {
            width: 84px;
            text-align: right;
            font-size: 14px;
            line-height: 1.3;
            font-weight: 900;
            color: $colorDark2;

            &._support {
                width: 150px;
            }
        }
    }

    &__total {
        width: 100%;
        padding-bottom: 14px;
        border-bottom: 1px solid rgba($colorDark2, .2);
        margin-bottom: 32px;

        &Block {
            width: 100%;

            &+& {
                margin-top: 16px;
            }
        }

        &Text {
            width: 100%;
            font-size: 14px;
            line-height: 1.3;
            color: $colorDark2;

            &._main {
                font-size: 15px;
                font-weight: 900;
            }

            &+& {
                margin-top: 4px;
            }
        }
    }

    &__sign {
        width: 100%;
        justify-content: space-between;
        align-items: stretch;

        &Block {
            width: 45%;
            min-height: 170px;
        }

        &Title {
            font-size: 15px;
            line-height: 1.3;
            font-weight: 900;
            color: $colorDark2;
            text-transform: uppercase;
            margin-bottom: 4px;
        }

        &Description {
            font-size: 14px;
            line-height: 1.3;
            color: $colorDark2;
        }

        &Foot {
            width: 100%;
            margin-top: auto;
        }

        &Sign {
            width: 100%;
            align-items: center;

            &Value {
                width: 100%;
                height: 18px;
                text-align: center;
                border-bottom: 1px solid rgba($colorDark2, .2);
                padding-bottom: 6px;
                font-size: 12px;
                font-weight: 600;
                color: $colorDark2;
                margin-bottom: 4px;
            }

            &Support {
                font-size: 9.5px;
                font-weight: 300;
                color: $colorDark2;
            }
        }

        &Role {
            width: 150px;
            margin-bottom: 20px;
        }

        &Box {
            width: 100%;
        }
    }
}