.alerts {
    width: 100%;

    &__item {
        // height: 88px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 4;
        width: 100%;
        transition: .5s ease-in-out;

        &Inner {
            width: 100%;
            height: 100%;
            transform: translate(280px, 0);
            transition: .5s ease-in-out;
            will-change: transform;
            pointer-events: visible;

            @include forType('crm') {
                transform: translate(320px, 0);

                @include media(0, $mediaM) {
                    transform: translate(100vw, 0);
                }
            }

            @include forType('app') {
                transform: translate(320px, 0);

                @include media(0, $mediaM) {
                    transform: translate(100vw, 0);
                }
            }

            @include media(0, $mediaM) {
                transform: translate(100vw, 0);
            }
        }

        &._show &Inner {
            transform: translate(0, 0);

            @include forType('crm') {
                transform: translate(0, 0);
            }

            @include forType('app') {
                transform: translate(0, 0);
            }

            @include media(0, $mediaM) {
                transform: translate(0, 0);
            }
        }

        &Box {
            width: 100%;
            height: 100%;
            // background: #fff;
            border-radius: 12px;
        }
    }
}