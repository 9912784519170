.filter {
    width: 100%;
    position: relative;
    z-index: 1;

    &__top {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 20px;
        overflow: hidden;
        pointer-events: none;
        z-index: 2;

        &::before {
            @extend %shadow;
            content: "";
            position: absolute;
            bottom: -7px;
            left: 50%;
            transform: translate(-50%, 0) rotate(45deg);
            width: 14px;
            height: 14px;
            z-index: 2;

            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: $colorDarkThemeBorder;
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -13px;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 30px;
            height: 24px;
            z-index: 3;

            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: $colorDarkThemeBorder;
            }
        }
    }

    &._right &__top {
        &::before {
            left: auto;
            right: 32px;
            transform: translate(0, 0) rotate(45deg);
        }

        &::after {
            left: auto;
            right: 24px;
            transform: translate(0, 50%);
        }
    }

    &__wrap {
        @extend %shadow;
        @extend %noscroll;
        width: 100%;
        overflow: hidden;
        border-radius: 16px;
        transition: $trans;
        position: relative;
        z-index: 1;
    }

    &__box {
        @extend %noscroll;
        width: 100%;
        // max-height: 460px;
        overflow-y: auto;
        transition: $trans;
        position: relative;
        z-index: 1;

        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: $colorDarkTheme3;
        }
    }

    &__inner {
        width: 100%;
        padding: 16px 20px;
        position: relative;
        transition: $trans;
        overflow: hidden;
    }

    &._withButton &__inner {
        padding-bottom: 76px;
    }

    &__button {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px;
        z-index: 2;
        transition: $trans;
        opacity: 0;
        border-radius: 0 0 16px 16px;
        transform: translate(0, 100%);
        pointer-events: none;

        @include theme('light') {
            background: linear-gradient(to top, #fff 0, rgba(#fff, 0) 100%);
        }

        @include theme('dark') {
            background: linear-gradient(to top, $colorDarkTheme2 0, rgba($colorDarkTheme2, 0) 100%);
        }

        &._show {
            opacity: 1;
            transform: translate(0, 0);
        }

        &Inner {
            width: 100%;
            pointer-events: visible;
        }

        &Item {
            width: 100%;
            height: 50px;
        }
    }

    &__block {
        width: 100%;

        &+& {
            margin-top: 12px;
            padding-top: 12px;
            border-top: 1px solid transparent;

            @include theme('light') {
                border-color: #e0e4e9;
            }

            @include theme('dark') {
                border-color: $colorDarkTheme2;
            }
        }

        &Head {
            width: 100%;
            // margin-bottom: 8px;
        }

        &._dates &Name {
            width: calc(100% - 50px);
        }

        &Name {
            max-width: calc(100% - 50px);
            font-size: 17px;
            line-height: 1.3;
            font-weight: 900;
            position: relative;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            &Inner {
                width: 100%;
                margin-left: 4px;
                transition: $trans;
                opacity: 0;

                &._show {
                    opacity: 1;
                }

                &Item {
                    width: 100%;

                    &Elem {
                        font-size: 17px;
                        line-height: 1.3;
                        font-weight: 500;
                    }
                }
            }
        }

        &Counter {
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translate(0, -50%) scale(1.2);
            margin: 1px 0 0 4px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $colorMain;
            z-index: 2;
            transition: $trans;
            opacity: 0;
            will-change: transform;

            &._show {
                transform: translate(0, -50%);
                opacity: 1;
            }

            &Inner {
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: 11px;
                color: #fff;
            }
        }

        &Actions {
            align-items: center;
            margin: 2px 0 0 auto;

            &::after {
                content: "";
                border: 3px solid transparent;
                transform: rotate(-45deg);
                margin-top: -2px;
                transition: $trans;
                margin-right: 2px;

                @include theme('dark') {
                    border-bottom-color: $colorDarkThemeText;
                    border-left-color: $colorDarkThemeText;
                }

                @include theme('light') {
                    border-bottom-color: #929292;
                    border-left-color: #929292;
                }
            }
        }

        &._active &Actions::after {
            transform: rotate(135deg);
            margin-top: 5px;
        }

        &Clear {
            width: 20px;
            height: 20px;
            border-radius: 6px;
            margin-right: 12px;
            justify-content: center;
            align-items: center;
            transition: $trans;
            @include button(.95);

            @include theme('light') {
                box-shadow: 0 0 3px 0 rgba(173, 183, 197, 0.56);
            }

            @include theme('dark') {
                box-shadow: 0 0 3px 0 rgba(#000, .4);
            }

            &Icon {
                width: 12px;
                height: 12px;

                @include theme('light') {
                    @include icon($colorGreyDark);
                }

                @include theme('dark') {
                    @include icon(#fff);
                }
            }
        }

        &._empty &Clear {
            opacity: .48;
            // box-shadow: none;
        }

        &Content {
            width: 100%;
            // height: 0;
            transition: $trans;

            &Inner {
                width: 100%;
                transition: $trans;
                padding-top: 8px;
            }
        }

        &._checkbox &ContentInner {
            padding-top: 0;
        }

        &:not(._active) &ContentInner {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
            transform: translate(0, -8px);
        }
    }

    &__tabs {
        width: 100%;
        flex-wrap: wrap;
    }

    &__tab {
        margin: 6px 6px 0 0;
        height: 23px;
        @include button(.96);
        transition: $trans;

        &Input {
            display: none;
        }

        &View {
            height: 100%;
            padding: 4px 12px 5px 12px;
            border-radius: 360px;
            font-size: 14px;
            font-weight: 500;
            transition: $trans;
            opacity: 0;

            @include theme('light') {
                background: $colorGreyLight;
                color: $colorDark2;
            }

            @include theme('dark') {
                background: $colorDarkTheme2;
                color: #fff;
            }

            @include forType('crm', 'light') {
                background: #f5f7fa;
            }

            @include forType('crm', 'dark') {
                background: $colorDarkTheme2;
            }
        }

        &._isReady &View {
            opacity: 1;
        }

        &Input:checked~&View {
            background: $colorMain;
            color: #fff;
            font-weight: 600;

            @include forType('crm', 'light') {
                background: $colorMain;
            }

            @include forType('crm', 'dark') {
                background: $colorMain;
            }
        }
    }

    &__list {}

    &__switch {
        width: 38px;
        height: 20px;
    }
}