.emodziList {
    @extend %shadow;
    width: 100%;
    background: #fff;
    border-radius: 16px;

    &__inner {
        @extend %noscroll;
        width: 100%;
        max-height: 240px;
        overflow-y: auto;
    }

    &._minHeight &__inner {
        max-height: 160px;
    }

    &__items {
        width: 100%;
        flex-wrap: wrap;
        padding: 12px 12px 8px 12px;
    }

    &__item {
        width: calc(100% / 7);
        align-items: center;
        margin-bottom: 4px;

        &Inner {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: $colorBlueBack;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            transition: $trans;
            @include button(.94);
        }
    }
}