.authSuccess {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__content {
        align-items: center;
        width: 100%;
    }

    &__newYearIcon {
        width: 8rem;
        height: 8rem;
        margin: -4rem 0 1.5rem;
    }

    &__newYearTitle {
        height: 4.5rem;
        margin-bottom: 3rem;
    }

    &__preview {
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: center;
        width: 8rem;
        height: 8rem;
        margin-bottom: 1.5rem;
        overflow: hidden;
        border-radius: 2rem;

        @include theme("light") {
            background: $colorBlueBack;
        }

        @include theme("dark") {
            background: $colorDarkTheme3;
        }

        &Icon {
            width: 100%;
            height: 100%;
        }
    }

    &__name {
        max-width: 100%;
        margin-bottom: 1.5rem;
        font-weight: 900;
        font-size: 3.5rem;
        text-align: center;

        @include theme("light") {
            color: $colorDark2;
        }

        @include theme("dark") {
            color: #fff;
        }
    }

    &__description {
        max-width: 100%;
        margin-bottom: 4rem;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.4;
        text-align: center;

        @include theme("light") {
            color: $colorDark2;
        }

        @include theme("dark") {
            color: #fff;
        }
    }

    &__status {
        max-width: 100%;
        font-weight: 600;
        font-size: 1.4rem;
        letter-spacing: 0.1rem;
        text-align: center;
        animation: animateWrite 2s infinite ease-in-out;

        @include theme("light") {
            color: #9da8b4;
        }

        @include theme("dark") {
            color: $colorDarkThemeText;
        }

        @keyframes animateWrite {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 0.3;
            }

            100% {
                opacity: 1;
            }
        }
    }

    &__rocket {
        position: absolute;
        bottom: 5rem;
        left: 0;
        z-index: -1;
        width: 13rem;
        height: 13rem;
        transform: translate3d(-100px, 0, 0) rotate(90deg);
        animation: rocket 4s 1 ease-in-out;
        will-change: transform;

        @keyframes rocket {
            from {
                transform: translate3d(-12rem, 0, 0) rotate(90deg);
            }

            to {
                transform: translate3d(48rem, 0, 0) rotate(90deg);
            }
        }
    }
}
