.body {
    width: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
    overscroll-behavior: none;

    &__content {
        background: none !important;
    }

    &__content._ios::after {
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 18px;
        z-index: 9999999;
    }

    // & * {
    //     cursor: default !important;
    //     user-select: none !important;
    // }

    &._isDrag {
        user-select: none;

        & * {
            user-select: none;
        }
    }

    &__page {
        transition: $trans;
        z-index: 100;
        will-change: transform;

        &._current {
            z-index: 101;
        }

        &._order-chat._current {
            z-index: 105 !important;
        }

        &._app {
            padding: 0;
            width: 100%;
        }

        &._isPrev {
            opacity: 0;
        }

        &:not(._show) {
            opacity: 0;
            pointer-events: none;

            & * {
                pointer-events: none;
            }

            &._prev {
                transform: scale(.95);
            }

            &._next {
                transform: scale(1.05);
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
        }

        &._order-details,
        &._market-details {
            transition: none;
            transform: none !important;
            opacity: 1 !important;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                background: rgba(#000, .2);
                @include blur(7px);
                transition: $trans;
            }

            &:not(._show)::before {
                opacity: 0;
            }
        }
    }

    &__appPopup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;

        &._full {
            transition: $trans;

            &:not(._show) {
                opacity: 0;
                transform: scale(1.03);
            }
        }

        &:not(._show) {
            pointer-events: none;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: rgba(#000, .2);
            @include blur(7px);
            transition: $trans;
        }

        &._full::before {
            display: none;
        }

        &:not(._show)::before {
            opacity: 0;
        }
    }

    &__start,
    &__appGalery {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000001;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__appGalery {
        background: rgba(#000, .2);
        @include blur(7px);
    }

    &__serverNotAvailable {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        transition: $trans;

        &:not(._show) {
            transform: translate(0, 100%);
        }
    }
}