.serverNotAvailable {
    width: 100%;
    height: 32px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #FFAE64, #FF5722, #FFAE64);

    &__inner {
        align-items: center;
    }

    &__icon {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        @include icon(#fff);
    }

    &__title {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
    }

    &__btn {
        font-size: 15px;
        border-bottom: 1px solid rgba(#fff, .5);
        color: #fff;
        margin-left: 8px;
        transition: $trans;
        @include button(.96);
    }
}