.appPopupPoint {
    width: 100%;

    &__blocks {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: $trans;
        align-items: center;

        &:not(._show) {
            opacity: 0;

            &._next {
                transform: translate(64px, 0);
            }

            &._prev {
                transform: translate(-64px, 0);
            }
        }
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__title {
        @extend %title;
        text-align: center;
        margin-bottom: 7px;
    }

    &__description {
        @extend %text;
        text-align: center;
        margin-bottom: 16px;

        &._sub {
            align-items: center;
        }

        &Drag {
            width: 18px;
            height: 18px;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            margin: 1px 0 0 5px;

            @include theme('light') {
                background: #F5F7FA;
            }

            @include theme('dark') {
                background: #1C1F21;
            }

            &::before,
            &::after {
                content: "";
                width: 7px;
                height: 1px;
                border-radius: 360px;
                display: block;

                @include theme('light') {
                    background: #3E454C;
                }

                @include theme('dark') {
                    background: #fff;
                }
            }

            &::before {
                margin-bottom: 3px;
            }
        }
    }

    &__buttons {
        width: 100%;
    }

    &__button {
        width: 100%;
        height: 60px;
        border-radius: 16px;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        transition: $trans;

        @include theme('light') {
            background: #F0F3F7;
            color: $colorDark2;
        }

        @include theme('dark') {
            background: #1C1F21;
            color: #fff;
        }

        &._alert {
            @include theme('light') {
                background: #FFF2EF;
                color: $colorAlert;
            }

            @include theme('dark') {
                background: rgba($colorAlert, .15);
                color: $colorAlert;
            }

            &._active {
                font-weight: 600;

                @include theme('light') {
                    background: $colorAlert;
                    color: #fff;
                }

                @include theme('dark') {
                    background: $colorAlert;
                    color: #fff;
                }
            }
        }

        &+& {
            margin-top: 10px;
        }
    }

    &__points {
        width: 100%;
        border-radius: 12px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        counter-reset: pointCounter;

        &._margin {
            margin-bottom: 14px;
        }

        &Inner {
            width: 100%;
            position: relative;
            transition: $trans;
        }
    }

    &__point {
        width: 100%;
        height: 72px;
        counter-increment: pointCounter;
        border-radius: 2px;
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;

        @include theme('light') {
            background: #F5F7FA;
        }

        @include theme('dark') {
            background: #1C1F21;
        }

        &[data-notTrans] {
            transition: none;
        }

        &[data-blok] {
            z-index: 2;
        }

        &._current {
            @include theme('light') {
                background: #ECF1FF;
            }

            @include theme('dark') {
                background: #2f3a59;
            }
        }

        &Wrapper {
            width: 100%;
            height: 100%;
            position: relative;
            padding: 16px;

            &._withDrag {
                padding-right: 55px;
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
            position: relative;
            padding-left: 28px;

            &::before {
                display: flex;
                flex-direction: column;
                content: counter(pointCounter);
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 11px;
                color: #fff;
                font-weight: 900;

                @include theme('light') {
                    background: #9DA8B4;
                }

                @include theme('dark') {
                    background: #3E454C;
                }
            }
        }

        &._current &Inner::before {
            @include theme('light') {
                background: $colorMain;
            }

            @include theme('dark') {
                background: $colorMain;
            }
        }

        &Address {
            width: 100%;
            font-size: 16px;
            height: 19px;
            font-weight: 600;
            margin-bottom: 3px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &._current &Address {
            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: #AEC2FF;
            }
        }

        &Description {
            max-width: 100%;
            font-size: 14px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            & span {
                color: #9DA8B4;
            }
        }

        &Drag {
            position: absolute;
            width: 32px;
            height: 32px;
            top: 50%;
            transform: translate(0, -50%);
            right: 10px;
            z-index: 2;
            justify-content: center;
            align-items: center;

            &Icon {
                width: 18px;
                height: 10px;

                @include theme('light') {
                    @include icon($colorDark2);
                }

                @include theme('dark') {
                    @include icon(#fff);
                }
            }
        }
    }

    &__reasons {
        width: 100%;
    }

    &__reason {
        width: 100%;
        height: 60px;
        border-radius: 16px;
        padding: 16px;
        border: 1px solid transparent;
        transition: $trans;

        @include theme('light') {
            background: $colorBlueBack;
        }

        @include theme('dark') {
            background: #25292C;
            border-color: #2B2F33;
        }

        &._current {
            @include theme('light') {
                border-color: $colorSuccess;
            }

            @include theme('dark') {
                border-color: $colorSuccess;
            }
        }

        &+& {
            margin-top: 10px;
        }

        &Inner {
            width: 100%;
            height: 100%;
            position: relative;
            font-size: 18px;
            font-weight: 600;
            align-items: center;

            @include theme('light') {
                color: $colorDark;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Point {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 0;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            transition: $trans;

            @include theme('light') {
                background: #DFE5EB;
            }

            @include theme('dark') {
                background: #1C1F21;
            }

            &Icon {
                width: 18px;
                height: 18px;
                margin: auto;
                @include icon(#fff);
                opacity: 0;
                transition: $trans;
            }
        }

        &._current &Point {
            background: $colorSuccess;

            &Icon {
                opacity: 1;
            }
        }
    }
}