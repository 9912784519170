.v2animateChange {
    position: relative;
    z-index: 1;
    transition: $trans;

    &._full {
        height: 100% !important;
    }

    &._static {
        position: relative !important;
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 0;
        width: 22px;
        height: 22px;
        transform: translate(0, -50%);
    }

    &__item {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;
        }

        &:not(._show, ._end) {
            transform: translate(0, -4px);
        }

        &._hide:not(._end) {
            transform: translate(0, 4px);
        }
    }

    &._center > &__item {
        left: 50%;
        transform: translate(-50%, 0);

        &:not(._show, ._end) {
            transform: translate(-50%, -4px);
        }

        &._hide:not(._end) {
            transform: translate(-50%, 4px);
        }
    }

    &._centerVertical > &__item {
        top: 50%;
        transform: translate(0, -50%);

        &:not(._show, ._end) {
            transform: translate(0, calc(-50% - 4px));
        }

        &._hide:not(._end) {
            transform: translate(0, calc(-50% + 4px));
        }
    }

    &._vertical > &__item {
        &:not(._show, ._end) {
            transform: translate(-4px, 0);
        }

        &._hide:not(._end) {
            transform: translate(4px, 0);
        }
    }

    &._default > &__item {
        &:not(._show, ._end) {
            transform: translate(0, 0) !important;
        }

        &._hide:not(._end) {
            transform: translate(0, 0) !important;
        }
    }

    &._right &__item {
        right: 0;
        left: auto;
    }

    &._static &__item {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }
}
