.empty {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    &._block:not(._notBack):not(._public) {
        @include theme('light') {
            background: #f8f8f8;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        @include forType('crm', 'light') {
            background: #fbfcfd;
        }

        @include forType('crm', 'dark') {
            background: $colorDarkTheme2;
        }
    }

    &._block {
        border-radius: 18px;
        padding: 24px;

        &._whiteOpacity {
            background: rgba(#fff, .56);
        }

        &._notBack {
            @include theme('light') {
                background: none;
            }

            @include theme('dark') {
                background: none;
            }
        }

        &._notRad {
            border-radius: 0;
        }

        &._notPad {
            padding: 0;
        }
    }

    &._public {
        border-radius: 3rem;
        padding: 3rem;
        background: #EFF2F5;

        @include media(0, $mediaM) {
            border-radius: 4.5rem;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        &Item {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            align-items: center;
        }
    }

    &__icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-bottom: 8px;
    }

    &__title {
        max-width: 100%;
        font-size: 15.5px;
        line-height: 1.3;
        font-weight: 600;
        text-align: center;

        @include media(0, $mediaM) {
            font-size: 18px;
        }

        @include theme('light') {
            color: #b5bec9;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._public &__title {
        font-size: 2.7rem;

        @include media(0, $mediaM) {
            font-size: 3rem;
        }
    }

    &__content {
        max-width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4;
        margin-top: 3px;

        @include media(0, $mediaM) {
            font-size: 17px;
        }

        @include theme('light') {
            color: #b5bec9;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._block &__content {
        font-size: 14px;
    }

    &._public &__content {
        font-size: 2rem;

        @include media(0, $mediaM) {
            font-size: 2.1rem;
        }
    }

    &__button {
        transition: $trans;
        opacity: 0;
        height: 0;

        &._show {
            margin-top: 8px;
            height: auto;
            opacity: 1;
        }
    }
}