﻿@font-face {
    font-weight: 300;
    font-family: PTRoot;
    font-style: normal;
    src: local("PTRoot"), url("../fonts/PT-Root-UI/Ptrootuilight.woff");
}

@font-face {
    font-weight: 500;
    font-family: PTRoot;
    font-style: normal;
    src: local("PTRoot"), url("../fonts/PT-Root-UI/Ptrootui.woff");
}

@font-face {
    font-weight: 600;
    font-family: PTRoot;
    font-style: normal;
    src: local("PTRoot"), url("../fonts/PT-Root-UI/Ptrootuimedium.woff");
}

@font-face {
    font-weight: 900;
    font-family: PTRoot;
    font-style: normal;
    src: local("PTRoot"), url("../fonts/PT-Root-UI/Ptrootuibold.woff");
}

@font-face {
    font-weight: 300;
    font-family: Gilroy;
    font-style: normal;
    src: local("Gilroy"), url("../fonts/Gilroy/Gilroy-Light.ttf");
}

@font-face {
    font-weight: 400;
    font-family: Gilroy;
    font-style: normal;
    src: local("Gilroy"), url("../fonts/Gilroy/Gilroy-Regular.ttf");
}

@font-face {
    font-weight: 400;
    font-family: Gilroy;
    font-style: italic;
    src: local("Gilroy"), url("../fonts/Gilroy/Gilroy-RegularItalic.ttf");
}

@font-face {
    font-weight: 600;
    font-family: Gilroy;
    font-style: normal;
    src: local("Gilroy"), url("../fonts/Gilroy/Gilroy-Bold.ttf");
}

@font-face {
    font-weight: 600;
    font-family: Gilroy;
    font-style: italic;
    src: local("Gilroy"), url("../fonts/Gilroy/Gilroy-BoldItalic.ttf");
}
