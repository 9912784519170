.filterList {
    width: 100%;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    &__view {
        width: 100%;
        height: 36px;
        border-radius: 10px 10px 0 0;
        padding: 4px 12px;
        position: relative;
        align-items: center;
        transition: $trans;

        @include theme('light') {
            background: $colorBlueBack;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        &::after {
            content: "";
            position: absolute;
            border: 3px solid transparent;
            top: 50%;
            right: 12px;
            transform: translate(0, -50%) rotate(-45deg);
            margin-top: -1px;
            transition: $trans;

            @include theme('light') {
                border-bottom-color: #929292;
                border-left-color: #929292;
            }

            @include theme('dark') {
                border-bottom-color: $colorDarkThemeText;
                border-left-color: $colorDarkThemeText;
            }
        }
    }

    &._active &__view {
        &::after {
            transform: translate(0, -50%) rotate(135deg);
            margin-top: 3px;
        }
    }

    &__choice {
        align-items: center;
        white-space: nowrap;
        height: 100%;
        font-size: 14px;
        font-weight: 500;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }

        &:not(._empty) {
            font-weight: 600;

            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: $colorMainTheme;
            }
        }

        &Inner {
            &._parent {
                margin-left: 4px;
            }
        }

        &:not(._empty) &Inner {
            font-weight: 600;

            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: $colorMainTheme;
            }
        }
    }

    &__content {
        width: 100%;
        overflow: hidden;
        border-radius: 0 0 10px 10px;
        transition: $trans;

        @include theme('light') {
            background: #f5f7fa;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        &Inner {
            width: 100%;
            transition: $trans;
        }
    }

    &__search {
        width: 100%;
        padding: 0 12px 14px 12px;
    }

    &._empty &__drop {
        @include theme('light') {
            border-top-color: transparent;
        }

        @include theme('dark') {
            border-top-color: transparent;
        }
    }

    ._parentCalc &__drop,
    &._static &__drop {
        &Item {
            position: relative;
            top: 0;

            &:not(._show) {
                display: none;
            }
        }
    }

    &__drop {
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        max-height: 200px;
        border-top: 1px solid transparent;
        transition: $trans;
        @extend %noscroll;

        @include theme('light') {
            border-color: #e9e9e9;
        }

        @include theme('dark') {
            border-color: $colorDarkTheme3;
        }

        &Items {
            width: 100%;
            position: relative;
            transition: $trans;
        }

        &Items._static &Item {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }

        &Item {
            align-items: center;
            width: 100%;
            min-height: 50px;
            padding: 10px 12px;
            border-bottom: 1px solid transparent;
            position: absolute;
            top: 0;
            left: 0;
            transition: $trans;

            @include theme('light') {
                background: $colorBlueBack;
            }

            @include theme('dark') {
                background: $colorDarkTheme2;
            }

            &._current {
                @include theme('light') {
                    // background: $colorBlueActive;
                }

                @include theme('dark') {
                    // background: $colorDarkTheme2;
                }
            }

            &:not(._last) {
                @include theme('light') {
                    border-color: #e9e9e9;
                }

                @include theme('dark') {
                    border-color: $colorDarkTheme3;
                }
            }

            &:not(._show) {
                opacity: 0;
            }

            &Content {
                align-items: center;
                max-width: calc(100% - 50px);

                &Inner {
                    font-size: 14px;
                    line-height: 1.3;
                    font-weight: 500;
                    max-width: calc(100% - 40px);
                    transition: $trans;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;

                    & b {
                        font-weight: 600;
                    }

                    @include theme('light') {
                        color: $colorDark2;
                    }

                    @include theme('dark') {
                        color: #fff;
                    }
                }
            }

            &._current &ContentInner {
                @include theme('light') {
                    // color: $colorMain;
                }

                @include theme('dark') {
                    // color: $colorMain;
                }
            }

            &Preview {
                width: 32px;
                height: 32px;
                border-radius: 8px;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                position: relative;
                z-index: 1;
                overflow: hidden;

                @include theme('light') {
                    background: #fff;
                }

                @include theme('dark') {
                    background: $colorDarkTheme3;
                }

                &Icon {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                & .avatar__item {
                    font-size: 14px;
                }
            }

            &._car &Preview {
                .avatar__item {
                    width: 26px;
                    height: 26px;
                }
            }

            &Action {
                width: 30px;
                height: 30px;
                margin-left: auto;
            }
        }
    }
}