.back {
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    pointer-events: visible;
    transition: $trans;

    @include theme('light') {
        color: $colorMain;
    }

    @include theme('dark') {
        color: #AEC2FF;
    }

    &:not(._show) {
        opacity: 0;
        pointer-events: none;
    }

    &__icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        display: block;

        @include theme('light') {
            @include icon($colorMain);
        }

        @include theme('dark') {
            @include icon(#AEC2FF);
        }
    }
}