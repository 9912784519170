.linkBorder {
    display: block;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;
    border-bottom: 1px dashed transparent;
    transition: $trans;
    @include button();

    @include media(0, $mediaM) {
        font-size: 14px;
    }

    &._mediumFont {
        font-weight: 600;
    }

    @include theme('light') {
        color: $colorMain;
        border-color: $colorMain;

        @include mediaMin($mediaM + 1px) {
            &:hover {
                color: $colorMainDark;
                border-color: $colorMainDark;
            }
        }
    }

    @include theme('dark') {
        color: $colorMainTheme;
        border-color: $colorMainTheme;

        @include mediaMin($mediaM + 1px) {
            &:hover {
                color: $colorBorderHover;
                border-color: $colorBorderHover;
            }
        }
    }

    &._whiteColor {
        @include theme('light') {
            color: #fff;
            border-color: #fff;
        }

        @include theme('dark') {
            color: #fff;
            border-color: #fff;
        }
    }

    &._bigSize {
        font-size: 17px;
        font-weight: 500;
    }
}