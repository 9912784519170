.action {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: $trans;
    @include button(.95);
    z-index: 1;
    overflow: hidden;

    &._mediumSize {
        border-radius: 7px;
    }

    @include theme('light') {
        background: #fff;
        box-shadow: 0 0 3px 0 rgba(173, 183, 197, 0.56);
    }

    @include theme('dark') {
        background: $colorDarkTheme3;
        box-shadow: 0 0 3px 0 rgba(#000, 0.32);
    }

    &._notShadow {
        @include theme('light') {
            box-shadow: none;
        }

        @include theme('dark') {
            box-shadow: none;
        }
    }

    &._dark {
        @include theme('light') {
            background: $colorDark;
        }

        @include theme('dark') {
            background: $colorDarkTheme3;
        }
    }

    &__icon {
        width: 14px;
        height: 14px;
        pointer-events: none;
    }

    &._rotate &__icon {
        width: 17px;
        height: 17px;
        margin: -1px 0 0 1px;
    }

    &._animate &__icon {
        opacity: 0;
        transition: $trans;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &._add {
            transform: translate(-50%, calc(-50% - 4px));
        }

        &._delete {
            transform: translate(-50%, calc(-50% + 4px));
        }

        &._show {
            opacity: 1;
            transform: translate(-50%, -50%);
        }
    }

    &._mediumSize &__icon {
        width: 12px;
        height: 12px;
    }

    &._add &__icon {
        @include theme('light') {
            @include icon($colorMain);
        }

        @include theme('dark') {
            @include icon($colorMainTheme);
        }
    }

    &._delete &__icon {
        @include icon($colorAlert);
    }

    &._darkBlur._delete &__icon {
        @include icon($colorAlertLight);
    }

    &__loader {
        background: inherit;

        &Item {
            width: 20px;
            height: 20px;
        }
    }
}