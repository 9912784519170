.personalTariff {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #56c45b;

    &__icon {
        width: 10px;
        height: 10px;
        @include icon(#fff);
    }
}