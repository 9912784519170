.field {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 12px;
    transition: $trans;

    @include media(0, $mediaM) {
        border-radius: 16px;
    }

    @include theme('dark') {
        background: $colorDarkTheme3;
    }

    @include theme('light') {
        background: #fff;
    }

    @include forType('crm') {
        border: 0;
    }

    @include forType('app') {
        border-radius: 12px;
    }

    @include forType('app', 'light') {
        background: $colorBlueBack;
    }

    @include forType('app', 'dark') {
        background: #2B2F33;
    }

    &._mediumRadius {
        border-radius: 10px;
    }

    &._grey,
    &._grey &__list {
        @include theme('dark') {
            background: $colorDarkTheme3;
        }

        @include theme('light') {
            background: #f8f8f8;
        }

        @include forType('crm', 'dark') {
            background: $colorDarkTheme3;
        }

        @include forType('crm', 'light') {
            background: #f7f8fb;
        }
    }

    &._bank {
        @include theme('dark') {
            background: $colorDarkTheme2;
        }
    }

    &._notBack {
        @include theme('dark') {
            background: none;
        }

        @include theme('light') {
            background: none;
        }

        @include forType('crm', 'dark') {
            background: none;
        }

        @include forType('crm', 'light') {
            background: none;
        }
    }

    &._fileOfDoc {
        border-radius: 6px;

        &._white:not(._notBack)._editmode._isEdit {
            padding: 0;

            @include theme('light') {
                background: $colorDark;
            }

            @include theme('dark') {
                background: $colorDarkTheme3;
            }
        }

        &._dark:not(._notBack) {
            @include theme('light') {
                background: $colorBlueBack;
            }

            @include theme('dark') {
                background: $colorDarkTheme2;
            }
        }
    }

    &._orderField {
        border-radius: 0;
        border: 0;

        @include theme('dark') {
            background: none;
        }

        @include theme('light') {
            background: none;
        }
    }

    &._orderPoint {
        border-radius: 0;
        border: 0;

        @include theme('light') {
            background: #fafafa;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        @include forType('crm', 'light') {
            background: $colorBlueBack;
        }

        @include media(0, $mediaM) {
            @include theme('dark') {
                background: $colorDarkTheme3;
            }
        }

        &._start {
            border-radius: 8px 0 0 8px;
        }

        &._end {
            border-radius: 0 8px 8px 0;
        }

        &._full {
            border-radius: 8px;
        }
    }

    &._orderPoint._bigSize {
        @include media(0, $mediaM) {
            border-radius: 0;

            @include theme('light') {
                background: none;
            }

            @include theme('dark') {
                background: none;
            }
        }

        &._start {
            @include media(0, $mediaM) {
                border-radius: 0;
            }
        }

        &._end {
            @include media(0, $mediaM) {
                border-radius: 0;
            }
        }

        &._full {
            @include media(0, $mediaM) {
                border-radius: 0;
            }
        }
    }

    &._googleVision {
        border-radius: 8px;
    }

    &._select {
        border-radius: 0;
        border: 0;

        @include theme('light') {
            background: none;
        }

        @include theme('dark') {
            background: none;
        }
    }

    &._order,
    &._orderContacts {
        border: 0;

        @include media(0, $mediaM) {
            border: 1px solid transparent;

            @include theme('light') {
                border-color: #eee;
            }

            @include theme('dark') {
                border-color: $colorDarkTheme;
            }
        }
    }

    &._mediumSize {
        border-radius: 9px;

        @include media(0, $mediaM) {
            border-radius: 16px;
        }
    }

    &._error:not(._whiteBack):not(._notBack) {
        border-color: rgba($colorAlert, .2) !important;

        @include theme('light') {
            background: $colorAlertBack !important;
        }

        @include theme('dark') {
            background: rgba($colorAlert, .1) !important;
        }
    }

    &._focus._withFocus {
        @include theme('light') {
            border-color: $colorMain;
        }

        @include theme('dark') {
            border-color: $colorBorderHover;
        }
    }

    &._role {
        @include theme('light') {
            border-color: none;
            background: none;
        }

        @include theme('dark') {
            border-color: none;
            background: none;
        }
    }

    &._editmode._isEdit:not(._notBack) {
        @include theme('light') {
            background: $colorBlueActive;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        &._whiteBack {
            @include theme('light') {
                background: #fff;
            }
        }
    }

    &._cabinet {
        border-radius: 12px;
    }

    &._promo {
        @include media(0, $mediaM) {
            @include theme('light') {
                border-color: #eee;
            }

            @include theme('dark') {
                border-color: $colorDarkTheme;
            }
        }
    }

    &._bank {
        border-radius: 8px;
    }

    &._manualMain {
        border-radius: 6px;
    }

    &._manualField {
        border-radius: 6px;

        @include theme('light') {
            background: $colorBlueBack;
        }

        &._white,
        &._white._isEdit:not(._notBack) {
            @include theme('light') {
                background: #fff;
            }
        }
    }

    &._joinTemplates {
        padding: 0;
        border-radius: 0;
    }

    &._manualDoc {
        border-radius: 4px;
        border: 0;
    }

    &._manualServices {
        border-radius: 6px;
        border: 0;

        &._inSelect {
            @include theme('light') {
                background: none;
            }
        }
    }

    &._bankCard {
        border-radius: 9px;
    }

    &._manualCard {
        border-radius: 6px;
    }

    &._crewExecutor {
        border-radius: 5px;
    }

    &__input {
        @extend %noscroll;
    }

    &._area &__input {
        resize: none;
    }

    &._orderInfo {
        border-radius: 12px 12px 0 0;

        @include theme('light') {
            background: $colorBlueBack2;
        }
    }

    &__value {
        width: 100%;
        height: 100%;
        padding: 6px 16px;
        font-family: 'PTRoot';
        font-weight: 500;
        transition: $trans;
        font-size: 15.5px;
        line-height: 1.3;
        cursor: text;
        align-items: center;
        opacity: 1;

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }

        @include media(0, $mediaM) {
            font-size: 18px;
        }

        @include forType('crm') {
            font-size: 14px;
            padding: 6px 18px;
        }

        @include forType('app') {
            font-size: 16px;
            text-align: center;
            font-weight: 600;
            justify-content: center;
        }
    }

    &._mediumSize &__value {
        @include forType('crm') {
            font-size: 16px;
        }
    }

    &._editmode._isEdit &__value,
    &._editmode._isEdit._checkComplete._complete &__value {
        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: $colorMainTheme;
        }
    }

    &._area &__value {
        align-items: flex-start;
        padding-top: 14px;
        // white-space: normal;
    }

    &._center &__value {
        text-align: center;
        justify-content: center;
    }

    &._paddingIcon._center &__value {
        padding-right: 32px;
    }

    &._error &__value {
        color: $colorAlert !important;
    }

    &._cabinet &__value {
        font-size: 14px;
        padding: 6px 12px 6px 12px;
    }

    &._cabinet._editIcon &__value {
        padding-right: 26px;
    }

    &._bank &__value {
        padding: 2px 8px;
        font-size: 14px;
    }

    &._bank._numberCard &__value {
        font-size: 16px;
    }

    &._role &__value {
        font-size: 11.5px;
        padding: 4px 0 5px 0;
        font-weight: 600;
    }

    &._role._isMe &__value {
        color: $colorMain;
    }

    &._orderField &__value {
        padding: 0;
        font-size: 16.5px;

        @include media(0, $mediaM) {
            font-size: 17px;
        }
    }

    &._bigSize &__value {
        font-size: 16.5px;

        @include media(0, $mediaM) {
            font-size: 18px;
        }
    }

    &._orderField._preview &__value {
        @include media(0, $mediaM) {
            font-size: 19.5px;
            font-weight: 300;
        }
    }

    &._fileOfDoc &__value {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        justify-content: center;
        padding: 0 0 1px 0;

        @include theme('light') {
            color: $colorGreyDark;
        }

        @include theme('dark') {
            color: $colorGreyDark;
        }
    }

    &._editmode._isEdit._fileOfDoc._notBack &__value {
        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: $colorMainTheme;
        }
    }

    &._fileOfDoc._notBack._white &__value {
        @include theme('light') {
            color: #fff;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &._fileOfDoc._white &__value {
        @include theme('light') {
            color: #fff;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    .orderDetailsRoute &._orderField &__value {
        font-weight: 600;
    }

    &._orderField._editmode._isEdit._checkComplete._complete &__value {
        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: $colorMainTheme;
        }
    }

    &._orderService &__value {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

    &._orderPoint &__value {
        padding: 4px 10px;
        font-size: 14px;

        @include media(0, $mediaM) {
            font-size: 16px;
        }
    }

    &._orderPoint._bigSize &__value {
        @include media(0, $mediaM) {
            font-size: 18px;
            padding: 0;

            @include theme('light') {
                color: $colorGreyDark2;
            }

            @include theme('dark') {
                color: $colorGreyDark2;
            }
        }
    }

    &._select &__value {
        padding: 0;
        font-size: 14px;

        @include theme('light') {
            color: $colorGreyDark2;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }

        @include media(0, $mediaM) {
            font-size: 18px;
        }
    }

    &._checkComplete &__value {
        @include theme('light') {
            color: $colorGreyDark2;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._editmode._isEdit._checkComplete &__value {
        @include theme('light') {
            color: rgba($colorMain, .5);
        }

        @include theme('dark') {
            color: rgba($colorMainTheme, .5);
        }
    }

    &._checkComplete._complete &__value {
        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }

        @include media(0, $mediaM) {
            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: $colorMainTheme;
            }
        }
    }

    &._order &__value {
        padding: 0 12px;
        font-size: 15px;

        @include media(0, $mediaM) {
            font-size: 17px;
            padding: 0 16px;
        }

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &._mediumPadding &__value {
        padding: 8px 10px;

        @include media(0, $mediaM) {
            padding: 3px;
        }
    }

    &._medium2Padding &__value {
        padding: 8px 13px;
    }

    &._mediumSize &__value {
        font-size: 14px;

        @include media(0, $mediaM) {
            font-size: 16px;
        }
    }

    &._mediumPadding._area &__value {
        padding-top: 8px;
    }

    &._promo &__value:not(.field__support) {
        font-weight: 600;

        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: $colorMainTheme;
        }
    }

    &._manualMain &__value {
        font-size: 13px;
        font-weight: 500;
        padding: 0px 6px;
    }

    &._manualMain._bigSize &__value {
        font-size: 15px;
    }

    &._fontMedium &__value {
        font-weight: 600;
    }

    &._manualField &__value {
        font-size: 14px;
        font-weight: 500;
        padding: 2px 8px;
    }

    &._manualDoc &__value {
        font-size: 11px;
        font-weight: 500;
        padding: 0 3px;
    }

    &._manualDoc._inSelect &__value {
        padding: 0;
    }

    &._manualServices &__value {
        padding: 0;
        font-size: 14px;
        font-weight: 500;
    }

    &._manualDoc._editmode._isEdit &__value {
        font-weight: 600;
    }

    &._orderInfo &__value {
        font-size: 16px;
        padding: 12px 18px;
    }

    &._manualCard &__value {
        font-size: 15px;
        padding: 0;
    }

    &._manualCard._isEdit &__value {
        padding-left: 6px;
        padding-right: 6px;
    }

    &._crewExecutor &__value {
        font-size: 12.5px;
        line-height: 1.5;
        padding: 0;
    }

    &._crewExecutor._isEdit &__value {
        padding-left: 4px;
        padding-right: 4px;
    }

    &._colorDark2 &__value {
        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &._notPadding &__value {
        padding: 0;
    }

    &._googleVision &__value {
        padding: 6px 12px;
        font-size: 15px;
    }

    &._googleVision._mediumSize &__value {
        padding: 4px 12px;
        font-size: 15px;

        @include forType('crm') {
            font-size: 15px;
        }
    }

    &._bankCard &__value {
        padding-left: 10px;
        padding-right: 10px;
    }

    &._joinTemplates &__value {
        padding: 0;
        font-size: 15.5px;
    }

    &__support {
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        user-select: none;
        width: 100%;
        white-space: nowrap;

        @include theme('light') {
            color: #a1a5b0;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }

        @include forType('app') {
            color: #B5BDC5;
        }
    }

    &._order &__support {
        @include theme('light') {
            color: #7d7d7d;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._notEmpty &__support {
        pointer-events: none;
    }

    &._notEmpty:not(._showSupport) &__support {
        opacity: 0;
    }

    &._showSupport &__support {
        // transform-origin: left top;
    }

    &._showSupport._notEmpty &__support {
        transform: translate(0, -50%) scale(.9);
        margin-top: -1px;
    }

    @include mediaMin($mediaM + 1px) {
        &:not(._notEmpty):not(._readOnly):hover &__support {
            opacity: .5;
        }
    }

    &._grey &__support {
        @include theme('light') {
            color: #a1a5b0;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._orderField:not(._editmode._isEdit) &__support {
        @include theme('light') {
            color: #7d7d7d;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._orderField._light:not(._editmode._isEdit) &__support {
        @include theme('light') {
            color: #bcbcbc;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._editmode._isEdit &__support {
        @include theme('light') {
            color: rgba($colorMain, .5);
        }

        @include theme('dark') {
            color: rgba($colorMainTheme, .5);
        }
    }

    &._manualMain._editmode._isEdit &__support {
        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: rgba($colorMainTheme, .5);
        }
    }

    &._manualDoc:not(._editmode._isEdit) &__support {
        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._manualDoc._editmode._isEdit &__value {
        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._fileOfDoc._white &__support {
        @include theme('light') {
            color: rgba(#fff, .64);
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &._manualField &__icon {
        width: 16px;
        height: 16px;
        right: 8px;
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 12px;
        width: 20px;
        height: 20px;
        z-index: 10;

        @include theme('light') {
            @include icon(#ddd);
        }

        @include theme('dark') {
            @include icon($colorDarkThemeText);
        }

        @include forType('crm', 'light') {
            @include icon($colorBlue);
        }

        &._active {
            @include theme('light') {
                @include icon($colorMain);
            }

            @include theme('dark') {
                @include icon($colorMainTheme);
            }
        }

        &._password {
            @include mediaMin($mediaM + 1px) {
                &:hover {
                    @include theme('light') {
                        @include icon($colorMainDark);
                    }

                    @include theme('dark') {
                        @include icon($colorMainTheme);
                    }
                }
            }
        }
    }

    &._error &__icon {
        @include theme('light') {
            @include icon(rgba($colorAlert, .2));
        }

        @include theme('dark') {
            @include icon(rgba($colorAlert, .2));
        }

        &._active {
            @include theme('light') {
                @include icon($colorAlert);
            }

            @include theme('dark') {
                @include icon($colorAlert);
            }
        }
    }

    &._cabinet &__icon {
        width: 16px;
        height: 16px;
        right: 6px;

        &._editIcon {
            width: 12px;
            height: 12px;
            right: 8px;
            cursor: default;
        }
    }

    &__list {
        @extend %shadow;
        @extend %noscroll;
        position: absolute;
        top: 100%;
        left: -1px;
        width: calc(100% + 2px);
        max-height: 100px;
        overflow-y: auto;
        z-index: 2;
        border-radius: 12px;
        transition: $trans;
        // border: 1px solid transparent;
        margin-top: 6px;

        &:not(._show) {
            opacity: 0;
            transform: translate(0, 6px);
            pointer-events: none;
        }

        @include theme('dark') {
            background: $colorDarkTheme3;
        }

        @include theme('light') {
            background: #fff;
        }

        &Item {
            width: 100%;
            cursor: pointer;
            padding-top: 10px;
            padding-bottom: 10px;

            &+& {
                border-top: 1px solid transparent;

                @include theme('light') {
                    border-color: #eee;
                }

                @include theme('dark') {
                    border-color: $colorDarkTheme2;
                }
            }
        }
    }

    &:not(._editmode._isEdit)._successColor &__value {
        font-weight: 600;
        color: $colorSuccess !important;
    }

    &:not(._editmode._isEdit)._mediumColor &__value {
        font-weight: 600;
        color: $colorMedium !important;
    }

    &:not(._editmode._isEdit)._errorColor &__value {
        font-weight: 600;
        color: $colorError !important;
    }
}