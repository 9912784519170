.corporationsPopup {
    @extend %page;

    @include theme('light') {
        background: #fff;
    }

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__back {
        margin-bottom: 14px;
        margin-right: auto;
    }

    &__icon {
        width: 56px;
        height: 54px;
        object-fit: contain;
        margin-bottom: 7px;
    }

    &__head {
        align-items: center;
        width: 100%;
    }

    &__title {
        @extend %title;
        text-align: center;
        margin-bottom: 8px;
    }

    &__description {
        @extend %text;
        text-align: center;
    }

    &__content {
        width: 100%;
        position: relative;
        z-index: 1;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 22px;
            left: 0;
            z-index: 2;
            pointer-events: none;
        }

        &::before {
            top: 0;

            @include theme('light') {
                background: linear-gradient(to top, rgba(#fff, 0), #fff);
            }

            @include theme('dark') {
                background: linear-gradient(to top, rgba(#1C1F21, 0), #1C1F21);
            }
        }

        &::after {
            bottom: 0;

            @include theme('light') {
                background: linear-gradient(to bottom, rgba(#fff, 0), #fff);
            }

            @include theme('dark') {
                background: linear-gradient(to bottom, rgba(#1C1F21, 0), #1C1F21);
            }
        }
    }

    &__cards {
        @extend %noscroll;
        padding: 22px 0;
        width: 100%;
        overflow-y: auto;
    }

    &__card {
        width: 100%;
        padding: 17px 60px 17px 20px;
        border-radius: 16px;
        position: relative;
        border: 1px solid transparent;
        transition: $trans;

        @include theme('light') {
            background: $colorBlueBack;
        }

        @include theme('dark') {
            background: #25292C;
            border-color: #2B2F33;
        }

        &._current {
            @include theme('light') {
                border-color: $colorSuccess;
            }

            @include theme('dark') {
                border-color: $colorSuccess;
            }
        }

        &+& {
            margin-top: 8px;
        }

        &Name {
            max-width: 100%;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 2px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Balance {
            max-width: 100%;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Point {
            position: absolute;
            top: 18px;
            right: 18px;
            z-index: 2;
            border-radius: 50%;
            transition: $trans;
            width: 24px;
            height: 24px;

            @include theme('light') {
                background: #DFE5EB;
            }

            @include theme('dark') {
                background: #1C1F21;
            }

            &Icon {
                width: 18px;
                height: 18px;
                @include icon(#fff);
                opacity: 0;
                transition: $trans;
                display: block;
                margin: auto;
            }
        }

        &._current &Point {
            background: $colorSuccess;

            &Icon {
                opacity: 1;
            }
        }
    }

    &__foot {
        width: 100%;
        margin-top: auto;
    }

    &__button {
        width: 100%;
        height: $fieldHeight;
    }
}