.supportCursor {
    width: 100%;
    height: 100%;
    padding: 5px 8px 5px 8px;
    color: $colorLight;
    font-size: 12px;
    line-height: 1.25;
    font-weight: 600;
    text-transform: uppercase;
    background: rgba(#4a525b, .9);
    border-radius: 8px;
    user-select: none;
    pointer-events: none;

    &._info {
        border-radius: 12px;
        background: rgba(#4a525b, .85);
    }

    &._normalCase {
        text-transform: none;
        font-size: 12.5px;
    }

    &._center {
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    &._wide {
        padding: 12px 12px;
        line-height: 1.25;
    }

    &._wide &__item {
        line-height: 1.3;
    }

    & b {
        font-weight: 700;

        &._title {
            font-size: 13px;
            margin-bottom: 4px;
        }
    }

    &__item {
        color: $colorLight;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;

        &+& {
            margin-top: 2px;
        }

        &._bold {
            font-weight: 900;
        }

        &._medium {
            font-weight: 600;
        }

        &._light {
            font-weight: 300;
        }

        &._center {
            text-align: center;
        }

        &._normalCase {
            text-transform: none;
        }

        &._bigSize {
            font-size: 16px;
        }
    }
}