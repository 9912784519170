.v2cursorActions {
    position: absolute;
    z-index: 10000;

    &__item {
        position: absolute;
        z-index: 1;
        overflow: hidden;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%);
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._left {
                transform: translate(-3px, 0);
            }

            &._right {
                transform: translate(3px, 0);
            }
        }

        &Action {
            position: relative;
            color: $colorDark2;
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;
            transition: $trans;

            &:not(:last-child) {
                border-bottom: 1px solid rgba($colorBlue, 0.64);
            }

            &._delete,
            &._remove {
                color: $colorAlert;
            }

            &Inner {
                @include button(0.96);

                width: 100%;
                padding: 12px 16px 12px 36px;
                background: #fff;
            }

            &Loader {
                background: inherit;
                border-radius: inherit;

                @include loader($colorMain);

                & ._LOADERITEM {
                    width: 20px;
                    height: 20px;
                }
            }

            @include hover {
                &:not(._delete, ._remove):hover {
                    background: $colorDark2;
                }

                &:not(._delete, ._remove):hover &Inner {
                    color: #fff;
                    background: $colorDark2;

                    @include icon(#fff);
                }

                &._delete:hover,
                &._remove:hover {
                    background: $colorAlert;
                }

                &._delete:hover &Inner,
                &._remove:hover &Inner {
                    color: #fff;
                    background: $colorAlert;

                    @include icon(#fff);
                }
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 12px;
                width: 18px;
                height: 18px;
                transform: translate(0, -50%);

                @include mob {
                    width: 20px;
                    height: 20px;
                }
            }

            &._delete &Icon {
                left: 11px;
            }
        }
    }
}
