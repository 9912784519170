.appChat {
    width: 100%;
    height: 100%;
    position: relative;

    &__empty {
        @extend ._loader;
        z-index: 9;
    }

    &:not(._init) &__list {
        opacity: 0;
    }

    &__list {
        @extend %noscroll;
        width: 100%;
        height: 100%;
        position: relative;
        overflow-y: auto;
        padding: calc(20px + #{$padding}) $padding 30px $padding;
        transition: $trans;

        &Inner {
            width: 100%;
            position: relative;
        }
    }

    &__card {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    ._parentCalc &__card,
    &__listInner._static &__card {
        position: relative;

        &:not(._show) {
            display: none;
        }

        &:not(._last) {
            margin-bottom: 10px;
        }
    }

    &__card:not(._show) &__preview {
        opacity: 0;
    }

    &__preview {
        width: 100%;
        padding: 14px 48px 14px 14px;
        position: relative;
        border-radius: 14px;
        border: 1px solid transparent;
        transition: $trans;
        align-items: center;

        @include theme('light') {
            background: #FBFCFD;
        }

        @include theme('dark') {
            background: #25292C;
            border-color: #2B2F33;
        }

        &._support {
            border-width: 2px;

            @include theme('light') {
                background: #EAF1FF;
                border-color: #fff;
            }

            @include theme('dark') {
                background: #25292C;
                border-color: #2B2F33;
            }
        }

        &._close:not(._support) {
            opacity: 0.72;
        }

        &._support &Avatar {
            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: #1d222a;
            }
        }

        &Avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @include theme('light') {
                background: $colorBlueBack;
            }

            @include theme('dark') {
                background: #1d222a;
            }

            & .avatar__item {
                font-size: 24px;
                font-weight: 600;
                color: #d0d6de;
            }
        }

        &Content {
            width: calc(100% - 60px);
            padding-left: 12px;
        }

        &Title {
            font-size: 16px;
            line-height: 1.3;
            font-weight: 900;
            margin-bottom: 4px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Message {
            max-width: 100%;
            font-size: 15px;
            line-height: 1.4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 4px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Type {
            font-size: 13px;
            line-height: 1.3;
            font-weight: 500;
            color: $colorGreyDark;
            transition: $trans;
        }

        &._close:not(._support) &Type {
            color: $colorSuccess;
        }

        &Counter {
            position: absolute;
            height: 21px;
            min-width: 21px;
            top: 12px;
            right: 12px;
            z-index: 2;
        }

        &Date {
            position: absolute;
            bottom: 12px;
            right: 14px;
            font-size: 12px;
            line-height: 1.4;
            color: $colorGreyDark;
        }
    }
}