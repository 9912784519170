.index {
    @extend %page;

    position: relative;

    &__loader {
        position: absolute;
        top: 28px;
        left: 50%;
        z-index: 3;
        width: $sizeLoaderMob;
        height: $sizeLoaderMob;
        transform: translate(-50%, 0);
        opacity: 0;
        pointer-events: none;

        &Inner {
            width: 100%;
            height: 100%;
            transform: scale(0);
        }
    }

    &._reload &__loader {
        opacity: 1 !important;

        &Inner {
            transform: translate(calc(50vw - calc(#{$sizeLoader} / 2) - 20px - 16px), 8px) scale(0.8) !important;
            transition: $trans !important;
        }
    }

    &:not(._disabled) &__loader {
        transition: $trans;

        &Inner {
            transition: $trans;
        }
    }

    &__inner {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    &:not(._disabled) &__inner {
        transition: $trans;
    }

    &._reload &__head::after {
        opacity: 1;
    }

    &__head {
        position: relative;
        z-index: 1;
        align-items: center;
        width: 100%;
        margin-bottom: 6px;
        padding: 5px;
        border: 1px solid transparent;
        border-radius: 16px;
        transition: $trans;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            width: 100px;
            height: 100%;
            border-radius: 0 16px 16px 0;
            opacity: 0;
            transition: $trans;
            content: "";

            @include theme("light") {
                background: linear-gradient(to left, #fcfdfe, #fcfdfe 54px, rgba(#fcfdfe, 0));
            }

            @include theme("dark") {
                background: linear-gradient(to left, #25292c, #25292c 54px, rgba(#25292c, 0));
            }
        }

        @include theme("light") {
            background: #fcfdfe;
        }

        @include theme("dark") {
            background: #25292c;
            border-color: #2a2f32;
        }

        &Avatar {
            position: relative;
            z-index: 1;
            width: 48px;
            height: 48px;
            overflow: hidden;
            border-radius: 12px;
            transition: $trans;

            @include theme("light") {
                background: $colorBlueBack;
            }

            @include theme("dark") {
                background: #1c1f21;
            }

            & .avatar__item {
                font-weight: 500;
                font-size: 16px;
                transition: $trans;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }
        }

        &Content {
            width: calc(100% - 48px);
            padding-left: 11px;
        }

        &Name {
            display: block;
            max-width: 100%;
            margin-bottom: 1px;
            overflow: hidden;
            font-weight: 900;
            font-size: 16px;
            line-height: 19px;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: $trans;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }
        }

        &Params {
            align-items: center;
            width: 100%;
        }

        &Param {
            position: relative;
            display: block;
            padding-left: 17px;
            overflow: hidden;
            color: $colorDark2;
            font-size: 12px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: $trans;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }

            &._name {
                // max-width: 53%;
            }

            & + & {
                margin-left: 12px;
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 12px;
                height: 12px;
                transform: translate(0, -50%);

                @include icon(#b5bdc5);
            }
        }
    }

    &__info {
        position: relative;
        z-index: 1;
        width: calc(100% + #{$padding} * 2);
        margin-bottom: 14px;
        margin-left: -20px;
        padding: 8px 17px 14px;
        overflow: hidden;

        &Cards {
            // padding: 0 17px;
            position: relative;

            // height: 184px;
            display: inline-flex;
            align-items: stretch;
            transition: $trans;
        }

        &Cards._static &Card {
            position: relative;
            height: auto;

            &:not(._show) {
                display: none;
            }
        }

        &Card {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: calc(100vw - 2 * #{$padding} + 6px);
            height: 100%;
            margin-right: 12px;
            overflow: hidden;
            border: 2px solid transparent;
            border-radius: 20px;
            box-shadow: 0 0.5px 8px 0 rgb(34 40 46 / 16%);
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }

            @include theme("light") {
                background: #fff;
            }

            @include theme("dark") {
                background: #25292c;
                border-color: #303639;
            }
        }

        &Pagination {
            justify-content: center;
            width: 100%;
            padding: 0 17px;
            transition: $trans;

            &:not(._empty) {
                padding-top: 12px;
            }
        }
    }

    &__map {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;

        &Info {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 22px 24px;
            background: rgba(#fff, 0.32);
            transition: $trans;

            @include blur(10px);

            &:not(._show) {
                opacity: 0;
            }

            @include theme("light") {
                background: rgba(#fff, 0.32);
            }

            @include theme("dark") {
                background: rgba(#25292c, 0.32);
            }

            &Title {
                margin-bottom: 7px;
                font-weight: 900;
                font-size: 20px;
                text-align: center;
                transition: $trans;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }

            &Description {
                @extend %text;

                margin-bottom: 14px;
                text-align: center;
            }

            &Button {
                width: 100%;
                height: 48px;
            }
        }

        &Box {
            position: relative;
            width: 100%;
            height: 100%;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &Inner {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        &Geo {
            position: absolute;
            right: 12px;
            bottom: 12px;
            z-index: 2;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-radius: 12px;
            box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%);
            will-change: transform;

            @include theme("light") {
                background: #fff;
            }

            @include theme("dark") {
                background: #2f3439;
            }

            &Icon {
                display: block;
                width: 22px;
                height: 22px;
                margin: 1px 0 0 -1px;

                @include theme("light") {
                    @include icon($colorMain);
                }

                @include theme("dark") {
                    @include icon(#b5bdc5);
                }
            }
        }
    }

    &__nav {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-bottom: -12px;

        &Block {
            align-items: center;
            justify-content: center;
            width: calc(50% - 6px);
            height: calc(50vw - 6px - 20px);
            margin-bottom: 12px;
            border: 1px solid transparent;
            border-radius: 16px;
            transition: $trans;

            @include theme("light") {
                background: #fcfdfe;
            }

            @include theme("dark") {
                background: #25292c;
                border-color: #2a2f32;
            }

            &Icon {
                position: relative;
                display: block;
                width: 32px;
                height: 32px;
                margin-bottom: 6px;
            }

            &ChatCounter {
                position: absolute;
                bottom: 100%;
                left: 100%;
                z-index: 2;
                min-width: 18px;
                height: 18px;
                margin: 0 0 -10px -10px;

                & .notificationCounter {
                    min-width: 18px !important;
                    border-radius: 50% !important;

                    &__inner {
                        font-size: 12px !important;
                    }
                }
            }

            &Title {
                max-width: 100%;
                margin-bottom: 3px;
                font-weight: 600;
                font-size: 20px;
                line-height: 1.2;
                text-align: center;
                transition: $trans;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }

            &Description {
                max-width: 100%;
                font-size: 14px;
                line-height: 1.3;
                text-align: center;
                transition: $trans;

                @include theme("light") {
                    color: #7e8892;
                }

                @include theme("dark") {
                    color: #b5bdc5;
                }
            }

            &._balance &Description {
                color: $colorSuccess;
                font-weight: 600;
            }
        }
    }

    &__contract {
        width: 100%;
        height: 100%;
        padding: 16px 20px;

        &Head {
            align-items: center;
            width: 100%;
            margin-bottom: 12px;

            &Logo {
                position: relative;
                z-index: 1;
                width: 40px;
                height: 40px;
                overflow: hidden;
                border-radius: 50%;

                @include theme("light") {
                    background: $colorBlueBack;
                }

                @include theme("dark") {
                    background: rgba($colorMain, 0.2);
                }

                & .avatar__item._contain .avatar__icon._parent {
                    transform: scale(0.7);
                }
            }

            &Content {
                width: calc(100% - 40px);
                padding-left: 10px;
            }

            &Title {
                max-width: 100%;
                margin-bottom: 2px;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.3;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }

            &Name {
                max-width: 100%;
                font-weight: 600;
                font-size: 15px;

                @include theme("light") {
                    color: #5f666d;
                }

                @include theme("dark") {
                    color: rgba(#fff, 0.72);
                }
            }
        }

        &Info {
            width: 100%;
            margin-bottom: 8px;
        }

        &Button {
            width: 100%;
            height: 48px;
        }
    }

    &__pay {
        width: 100%;
        height: 100%;
        padding: 18px 20px;

        &Title {
            max-width: 100%;
            margin-bottom: 4px;
            font-weight: 700;
            font-size: 16px;
            line-height: 1.3;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }
        }

        &Info {
            width: 100%;
            font-size: 14px;
            line-height: 1.3;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }

            & span {
                color: #949da5;
            }

            & + & {
                margin-top: 3px;
            }

            &._last {
                margin-bottom: 12px;
            }
        }

        &Button {
            width: 100%;
            height: 48px;
            margin-top: auto;
        }
    }

    &__tax {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 16px 20px;

        &Info {
            position: absolute;
            top: 14px;
            right: 14px;
            z-index: 2;
            width: 24px;
            height: 24px;

            @include theme("light") {
                @include icon(rgba($colorMain, 0.2));
            }

            @include theme("dark") {
                @include icon(#aec2ff);
            }
        }

        &Head {
            align-items: center;
            width: 100%;
            margin-bottom: 12px;

            &Icon {
                width: 50px;
                height: 50px;
                border-radius: 50%;

                @include theme("light") {
                    background: #ecf1ff;
                }

                @include theme("dark") {
                    background: #1c1f21;
                }

                &Item {
                    width: 26px;
                    height: 24px;
                    margin: auto;

                    @include icon($colorMain);
                }
            }

            &Title {
                max-width: calc(100% - 50px);
                padding-left: 11px;
                font-weight: 600;
                font-size: 18px;
                line-height: 1.3;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }
        }

        &Button {
            width: 100%;

            &._add {
                height: 42px;
            }

            & + & {
                margin-top: 10px;
            }
        }
    }

    &__fns {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 16px 20px;

        &Head {
            align-items: center;
            width: 100%;
            margin-bottom: 8px;

            &Icon {
                width: 50px;
                height: 50px;
                border-radius: 50%;

                @include theme("light") {
                    background: #ecf1ff;
                }

                @include theme("dark") {
                    background: #1c1f21;
                }
            }

            &Title {
                max-width: calc(100% - 50px);
                padding-left: 11px;
                font-weight: 600;
                font-size: 18px;
                line-height: 1.3;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }
        }

        &Description {
            max-width: 100%;
            margin-bottom: 14px;
            font-size: 14px;
            line-height: 1.4;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }
        }

        &Button {
            width: 100%;
            height: 42px;
        }
    }

    &__empty {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &Title {
            color: $colorGreyDark;
            font-weight: 600;
            font-size: 18px;
        }

        &Description {
            margin-top: 6px;
            color: $colorGreyDark;
            font-size: 16px;
            line-height: 1.4;
        }
    }

    &__notification {
        width: 100%;
        height: 100%;
        padding: 16px 20px;

        &Title {
            color: $colorDark2;
            font-weight: 600;
            font-size: 18px;
        }

        &Description {
            margin-top: 6px;
            color: $colorDark2;
            font-weight: 300;
            font-size: 16px;
            line-height: 1.4;

            & b {
                padding: 0 3px;
                font-weight: 500;
                border-radius: 5px;
                background: $colorBlueBack;
            }
        }
    }
}
