$widthInner:960px;
$margin:8px;

.galery {
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    @include popupBack();

    @include media(0, $mediaM) {
        height: 100%;
        padding: 32px 0;
    }

    &__inner {
        width: $widthInner;
        transition: $trans;
        align-items: center;

        @include media(0, $mediaM) {
            width: calc(100% - #{$paddingMobile} * 2);
            height: 100%;
        }
    }

    &__head {
        align-items: center;
        width: 100%;
        margin-bottom: 24px;
        position: relative;
        z-index: 2;
    }

    &__name {
        align-items: center;
        font-size: 18px;
        font-weight: 900;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }

        @include media(0, $mediaM) {
            font-size: 22px;
            max-width: calc(100% - 100px);
        }

        &Counter {
            align-items: center;
            font-weight: 500;
            font-size: 18px;
            margin-left: 4px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            @include media(0, $mediaM) {
                font-size: 22px;
            }
        }
    }

    &__actions {
        align-items: center;
        margin-left: auto;

        &Buttons {
            align-items: center;
            margin-right: 8px;
        }

        &Button {
            &+& {
                margin-left: 8px;
            }
        }
    }

    &__close {
        width: 20px;
        height: 20px;

        @include theme('light') {
            @include icon($colorDark);
        }

        @include theme('dark') {
            @include icon(#fff);
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: calc(100vh - 250px);
        margin-bottom: $margin;
        border-radius: 16px;
        z-index: 1;

        @include media(0, $mediaM) {
            height: calc(100vh - 100px);
            margin-top: auto;
            margin-bottom: 0;
        }

        &Actions {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            align-items: center;
            padding: 14px;
        }

        &Action {
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 10px;
            transition: $trans;
            @include button();

            &._download {
                margin: 0 0 auto auto;
                position: relative;
                z-index: 3;
            }

            &._arrow {
                @extend %shadow;

                @include theme('light') {
                    background: #fff;
                }

                @include theme('dark') {
                    background: $colorDarkTheme2;
                }
            }

            &._download {
                box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);

                @include theme('light') {
                    background: $colorMain;
                }

                @include theme('dark') {
                    background: $colorMain;
                }
            }

            &+& {
                margin-left: 8px;
            }

            &Icon {
                width: 16px;
                height: 16px;

                @include theme('light') {
                    @include icon($colorDark);
                }

                @include theme('dark') {
                    @include icon(#fff);
                }
            }

            &._download &Icon {
                width: 16px;
                height: 16px;

                @include theme('light') {
                    @include icon(#fff);
                }

                @include theme('dark') {
                    @include icon(#fff);
                }
            }
        }

        &Inner {
            margin: auto;
            transition: $trans;
            padding: 8px;
            width: 100%;
            height: 100%;
            position: relative;

            @include media(0, $mediaM) {
                margin: auto auto 0 auto;
            }
        }

        &Image {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
            transition: $trans;
            will-change: opacity;
            z-index: 1;
            justify-content: center;
            align-items: center;

            & .imageLazy__itemInner {
                width: auto;
                height: auto;
                max-width: $widthInner;
                max-height: calc(100vh - 250px);
            }

            &._doc {
                width: 320px;
                height: 320px;
                @extend %shadow;
                border-radius: 32px;
            }

            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: $colorDarkTheme2;
            }

            &:not(._current) {
                opacity: 0;
                pointer-events: none;
            }

            &Actions {
                top: 20px;
                right: 20px;
                z-index: 3;
                height: 32px;
                position: absolute;
            }

            &Action {
                width: 32px;
                height: 32px;
                @include icon($colorDark2);

                &+& {
                    margin-left: 8px;
                }
            }

            &Icon {
                width: 52px;
                height: 52px;
                display: block;
                @include icon($colorGreyDark);
                margin-bottom: 6px;
            }

            &Support {
                max-width: 100%;
                text-align: center;
                font-size: 18px;
                line-height: 1.3;
                color: $colorGreyDark;
            }

            &Item {
                &._parent {
                    @extend %shadow;
                    border-radius: 16px;
                    position: relative;
                    overflow: hidden;
                    transition: $trans;
                    will-change: transform;
                }
            }
        }
    }

    &__items {
        width: 100%;
        width: 100vw;
        overflow-x: auto;
        // border-radius: 16px;
        @extend %noscroll;
        position: relative;
        z-index: 2;

        &Inner {
            display: inline-flex;
            width: auto;
            padding: 0 calc(calc(100vw - #{$widthInner}) / 2);
        }
    }

    &__item {
        width: calc(calc(#{$widthInner} - #{$margin * 6}) / 7);
        height: calc(calc(#{$widthInner} - #{$margin * 6}) / 7);
        width: 130px;
        height: 130px;
        overflow: hidden;
        border: 1px solid transparent;
        border-radius: 24px;
        opacity: .32;
        transition: $trans;
        will-change: opacity;
        @include button(.97);

        &._doc {
            background: #fff;
        }

        @include theme('light') {
            border-color: $colorBlue;
        }

        @include theme('dark') {
            border-color: $colorBlue;
        }

        &._current {
            opacity: 1;

            @include theme('light') {
                border-color: $colorMain;
            }

            @include theme('dark') {
                border-color: $colorMainTheme;
            }
        }

        &+& {
            margin-left: $margin;
        }

        &Image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &Icon {
            width: 32px;
            height: 32px;
            display: block;
            margin: auto;
            @include icon($colorGreyDark);
        }
    }
}