.chatVoiceBar {
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 14px;
    border-radius: 10px;

    @include forType("app") {
        border-radius: 12px;
    }

    @include theme("light") {
        background: #f1f4ff;
    }

    @include theme("dark") {
        background: #2f3b58;
    }

    &::before {
        width: 6px;
        height: 6px;
        margin-right: 6px;
        background: $colorAlert;
        border-radius: 50%;
        animation: voicePin 1s infinite ease-in-out;
        content: "";

        @keyframes voicePin {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }

    &__timer {
        font-size: 15px;

        @include theme("light") {
            color: $colorDark2;
        }

        @include theme("dark") {
            color: #fff;
        }
    }
}
