.v2input {
    position: relative;
    width: 100%;
    height: 100%;

    &._static {
        display: flex;
    }

    &._grey {
        background: $colorBlueBack;

        &._error {
            background: $colorAlertBack;
        }
    }

    &._chat {
        background: none;
        transition: $trans;
    }

    &__field {
        width: 100%;
        height: 100%;
        color: $colorDark2;
        line-height: 1.4;
        cursor: text;
        resize: none;
    }

    &._static &__field {
        position: relative;
        height: auto;
        margin: auto 0;
        overflow: hidden;
        line-height: 1.5;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__support {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $colorGreyDark;
        line-height: 1.4;
    }

    &._area &__support {
        align-items: flex-start;
    }

    &._focus &__support,
    &:not(._empty) &__support {
        opacity: 0;
        pointer-events: none;
    }

    &__field,
    &__support {
        padding: 12px 16px;
        font-size: 15px;
        cursor: text;
        transition: $trans;
    }

    &._emptySize &__field,
    &._emptySize &__support {
        padding: 0;
    }

    &._comments &__field,
    &._comments &__support {
        padding: 12px 44px 12px 12px;
        font-size: 14px;
    }

    &._chat &__field,
    &._chat &__support {
        padding: 7px 10px !important;
        font-size: 14.5px;
        line-height: 1.4;
    }

    &._widgetField &__field,
    &._widgetField &__support {
        padding: 10px;
        font-size: 14px;
    }

    &._area._widgetField &__field,
    &._area._widgetField &__support {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    &._center &__field,
    &._center &__support {
        justify-content: center;
        text-align: center;
    }

    &._textGrey &__field {
        font-weight: 600;
    }

    &._textGrey &__support {
        color: $colorGreyDark;
        font-weight: 600;
    }

    &._error &__field,
    &._error &__support {
        color: $colorAlert;
        font-weight: 600;
    }
}
