.imageTheme {
    position: relative;
    width: 100%;
    height: 100%;
    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: $trans;
        will-change: opacity;
    }
    &._fitNone &__image {
        object-fit: unset;
    }
}