.appChatMessage {
    width: 100%;
    transition: $trans;

    &._me {
        align-items: flex-end;
    }

    &__inner {
        max-width: 292px;
        height: 100%;
        padding: 8px 16px;
        transition: $trans;
        position: relative;
        z-index: 1;
    }

    &._file &__inner {
        padding: 0;
        overflow: hidden;
    }

    &._info &__inner {
        padding: 0;
        width: 100%;
        max-width: 100%;
        background: none !important;
        border-radius: 0 !important;
    }

    &._me &__inner {
        border-radius: 20px 20px 0 20px;
        background: linear-gradient(to bottom, #668DFF, #3E6AED);
    }

    &._me._end &__inner {
        // border-radius: 20px 0 20px 20px;
    }

    &._me._start &__inner {
        // border-radius: 20px 20px 0 20px;
    }

    &:not(._me) &__inner {
        border-radius: 20px 20px 20px 0;
        background: #FFFFFF;
    }

    &:not(._me)._end &__inner {
        // border-radius: 0 20px 20px 20px;
    }

    &:not(._me)._start &__inner {
        // border-radius: 20px 20px 20px 0;
    }

    &__text {
        font-size: 16px;
        line-height: 1.4;
        color: $colorDark2;

        & b {
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 4px;
            color: $colorDark2;
        }
    }

    &._me &__text {
        color: #fff;
        font-weight: 600;
        padding-right: 16px;
    }

    &._image &__read {
        z-index: 4;
        width: 22px;
        height: 22px;
        border-radius: 7px;
        background: rgba($colorMain, .8);
        justify-content: center;
        align-items: center;
    }

    &__read {
        position: absolute;
        bottom: 13px;
        right: 10px;

        &Icon {
            width: 10px;
            height: 10px;
            @include icon(#fff);
            transition: $trans;

            &+& {
                margin-left: -5px;
            }

            &._hide {
                opacity: 0.24;
            }
        }
    }

    &__images {
        width: 100%;
    }

    &__image {
        width: 100%;
        height: 250px;
    }
}