.v2popupGalery {
    width: 100%;
    height: 100%;

    &__inner {
        position: relative;
        z-index: 1;
        width: 100%;
        height: calc(100vh - 62px - 141px);
        overflow: hidden;
        background: #fbfcfd;
    }

    ._POPUPWRAPPER._pin &__inner {
        height: calc(500px - 62px - 77px);
    }

    &__image {
        width: 100%;
        height: 100%;

        &Item {
            position: relative;
            width: 100%;
            height: 100%;
            transition: $trans;
            user-select: none;

            &._disabled {
                transition: none;
            }

            & .v2lazyImage {
                display: flex;
            }

            & .v2lazyImage__image {
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;
                margin: auto;
            }

            & .v2lazyImage._ready .v2lazyImage__image {
                // transition: none;
            }
        }
    }
}
