.join {
    width: 100%;
    height: 100%;
    background: #fff;

    @mixin inAppPopup() {
        .appPopup & {
            @content;
        }
    }

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__head {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        padding: 16px 0;
        background: linear-gradient(to bottom, #fff, rgba(#fff, 0));
        pointer-events: none;

        &Inner {
            align-items: flex-end;
            width: 100%;
            padding: 0 20px;
        }

        &Title {
            max-width: calc(100% - 80px);
            margin-left: auto;
            padding: 7px 12px;
            color: $colorDark2;
            font-size: 11.5px;
            line-height: 1.3;
            background: #f2f4f7;
            border-radius: 10px 10px 10px 0;
            transition: $trans;

            & b {
                font-weight: 600;
            }
        }
    }

    &:not(._init) &__headTitle {
        opacity: 0;
    }

    &__logo {
        &Name {
            max-width: 100%;
            margin-bottom: 5px;
            color: $colorDark2;
            font-weight: 900;
            font-size: 11px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        &Icon {
            width: 72px;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &Inner {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            transition: $trans;

            // &::after {
            //     position: absolute;
            //     top: 56px;
            //     left: 0;
            //     z-index: 11;
            //     width: 100%;
            //     height: 30px;
            //     background: linear-gradient(to bottom, #fff, #fff 10%, rgba(#fff, 0) 100%);
            //     transition: $trans;
            //     content: "";
            // }
        }

        &._tabsHide &Inner::after {
            opacity: 0;
        }
    }

    &:not(._init) &__contentInner {
        opacity: 0;
        pointer-events: none;
    }

    &__error {
        @extend ._loader;

        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 20px;

        &Icon {
            width: 64px;
            height: 64px;
            margin-bottom: 12px;
            object-fit: contain;
        }

        &Text {
            color: $colorDark2;
            font-size: 18px;
            line-height: 1.4;
            text-align: center;
        }
    }

    &__content._tabsHide &__tabs {
        visibility: hidden;
        opacity: 0;
    }

    &__tabs {
        z-index: 1;
        display: inline-flex;
        width: 100%;
        height: 56px;
        padding: 20px 0 0;
        overflow: hidden;
        transition: $trans;

        &._hide {
            opacity: 0;
        }

        &Inner {
            position: relative;
            z-index: 1;
            transition: $trans;

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: calc(100% - 20px);
                height: 1px;
                background: rgba(#b5bdc5, 0.7);
                transform: translate(-50%, -50%);
                content: "";
            }
        }
    }

    &__tab {
        white-space: nowrap;
        border: 4px solid #fff;
        border-radius: 360px;
        transition: $trans;
        will-change: transform;

        &:not(._current) {
            transform: scale(0.85);
        }

        &Inner {
            position: relative;
            align-items: center;
            height: 28px;
            padding: 5px 12px 6px 28px;
            color: #b5bdc5;
            font-size: 13px;
            background: #f2f4f7;
            border-radius: 360px;
            transition: $trans;
        }

        &._start &Inner {
            padding-left: 12px;
        }

        &._complete &Inner {
            color: $colorSuccess;
            background: #ecfde8;
        }

        &._current &Inner {
            color: #fff;
            background: $colorMain;
            border-color: $colorMainDark;
        }

        &Counter {
            position: absolute;
            top: 50%;
            left: 5px;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            color: #b5bdc5;
            font-weight: 600;
            font-size: 12px;
            background: #fff;
            border-radius: 50%;
            transform: translate(0, -50%);
            transition: $trans;
        }

        &._current &Counter {
            color: $colorMain;
            background: #fff;
        }

        &._complete &Counter {
            color: $colorSuccess;
            background: #fff;
        }

        &Wrapper {
            position: relative;

            & + & {
                margin-left: 12px;
            }
        }
    }

    &__blocks {
        position: relative;
        width: 100%;
        height: 100%;

        &Inner {
            width: 100%;
            transition: $trans;
        }
    }

    &__block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 140px 20px 30px;
        overflow: hidden;
        overflow-y: auto;
        transition: $trans;
        will-change: transform;

        &._final {
            // z-index: 10;
        }

        &._start,
        &._check {
            justify-content: center;
            height: 100%;
            padding-bottom: 120px;
        }

        &._complete {
            padding-bottom: 0;
        }

        &._final {
            // padding-bottom: 0;
        }

        &:not(._show) {
            opacity: 0;

            &._next {
                transform: translate(100px, 0);
            }

            &._prev {
                transform: translate(-100px, 0);
            }
        }

        &Inner {
            align-items: center;
            width: 100%;
        }

        &Inners {
            width: 100%;
        }

        &Loader {
            width: 36px;
            height: 36px;
            margin-bottom: 12px;
        }

        &Done {
            width: 40px;
            height: 40px;
            margin-bottom: 12px;
        }

        &Icon {
            display: block;
            width: 60px;
            height: 60px;
            margin-bottom: 12px;

            &._alert {
                width: 48px;
                height: 48px;
                margin-bottom: 8px;

                @include icon($colorAlert);
            }
        }

        &Title {
            max-width: 100%;
            color: $colorDark2;
            font-weight: 900;
            font-size: 28px;
            line-height: 1.25;
            text-align: center;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }
        }

        &Title + &Text {
            margin-top: 10px;
        }

        &Text {
            max-width: 320px;
            color: $colorDark2;
            font-size: 16px;
            line-height: 1.45;
            text-align: center;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }

            &._marginBottom {
                margin-bottom: 20px;
            }

            & a {
                font-weight: 600;
                border-bottom: 1px solid transparent;

                @include theme("light") {
                    color: $colorMain;
                    border-color: rgba($colorMain, 0.5);
                }

                @include theme("dark") {
                    color: #aec2ff;
                    border-color: rgba(#aec2ff, 0.5);
                }
            }

            & + & {
                margin-top: 10px;
            }

            & b {
                font-weight: 600;
            }
        }

        &Title + &Fields,
        &Text + &Fields {
            margin-top: 20px;
        }

        &Button {
            width: 100%;
            height: 56px;
            margin-top: 18px;

            &._notTop {
                margin-top: 0;
            }
        }

        &Fields {
            width: 100%;
            margin-bottom: 16px;
        }

        &Field {
            width: 100%;

            & + & {
                margin-top: 12px;
            }

            &Support {
                margin-bottom: 6px;
                font-size: 16px;
                line-height: 1.35;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }

            &Box {
                width: 100%;
                height: 56px;
                transition: $trans;

                & .v2widgetField__box {
                    border-radius: 12px;
                }

                & .v2widgetField,
                & .v2widgetField__box,
                & .v2widgetField__field {
                    height: 100%;
                }

                & .v2widgetField__field::after {
                    right: 20px;
                }

                & .v2input__field {
                    padding: 0 10px !important;
                }

                & .v2input__field,
                & .v2input__support {
                    justify-content: center;
                    color: $colorDark2;
                    font-size: 16px !important;
                    text-align: center;
                }

                & .v2input__support {
                    color: $colorGreyDark;
                }
            }

            &._file &Box {
                height: auto;
                background: none;
            }

            &Description {
                max-width: 100%;
                margin-top: 8px;
                color: $colorDark2;
                font-size: 13px;
                line-height: 1.5;
                text-align: center;
            }
        }

        &Code {
            width: 100%;
            max-width: 500px;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        &Back {
            position: relative;
            color: $colorMain;
            font-weight: 500;
            font-size: 16px;

            &._top {
                margin-top: 16px;
            }

            & + & {
                margin-top: 16px;
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 20px;
                height: 20px;
                transform: translate(0, -50%);

                @include icon($colorMain);
            }

            &Loader {
                left: 100%;
                z-index: 10;
                width: auto;
                margin-left: 4px;

                &Item {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &Doc {
            width: 100%;
            padding: 20px 24px 24px;
            background: #f2f7ff;
            border: 1px solid #e2e9ff;
            border-radius: 22px;

            &Head {
                position: relative;
                width: 100%;
                margin-bottom: 14px;
                padding-right: 30px;
                color: $colorDark2;
                font-weight: 600;
                font-size: 17px;
                line-height: 1.3;
            }

            &Counter {
                position: absolute;
                top: 0;
                right: 0;
                color: $colorDark2;
                font-weight: 300;
                font-size: 17px;
                line-height: 1.3;
                white-space: nowrap;
            }

            &Content {
                justify-content: space-between;
                width: 100%;
            }

            &File {
                position: relative;
                z-index: 1;
                width: calc(50% - 6px);
                height: calc(calc(100vw - 88px) / 2 - 6px);
                max-height: 160px;
                background: rgba(#fff, 0.4);
                border-radius: 14px;

                &._full {
                    width: 100%;
                }

                &Input {
                    display: none;
                }

                &Back {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                }

                &._error &Back .borderDashed__rect {
                    stroke: $colorAlert !important;
                }

                &Preview {
                    align-items: center;
                    margin: auto;
                    transition: $trans;

                    &Icon {
                        display: block;
                        width: 24px;
                        height: 24px;
                        margin-bottom: 6px;

                        @include icon($colorMain);
                    }

                    &Description {
                        max-width: 100%;
                        color: $colorMain;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 1.4;
                        text-align: center;
                        transition: $trans;
                    }
                }

                &._error &Preview {
                    &Icon {
                        @include icon($colorAlert);
                    }

                    &Description {
                        color: $colorAlert;
                    }
                }

                &._loading &Preview,
                &._loading &Info {
                    opacity: 0;
                }

                &._isUpload &Preview {
                    opacity: 0;
                }

                &Info {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    padding: 20px;
                    transition: $trans;

                    &:not(._show) {
                        opacity: 0;
                    }

                    &Icon {
                        width: 24px;
                        height: 24px;
                        margin-bottom: 8px;

                        @include icon($colorMain);
                    }

                    &Name {
                        max-width: 100%;
                        overflow: hidden;
                        color: $colorMain;
                        font-size: 16px;
                        line-height: 1.3;
                        white-space: nowrap;
                        text-align: center;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        &Error {
            width: 100%;
            color: $colorAlert;
            font-weight: 600;
            font-size: 15px;
            line-height: 1.3;
            text-align: center;
            transition: $trans;

            &._parent:not(._empty) {
                padding-bottom: 12px;
            }
        }

        &Bank {
            position: relative;
            width: 100%;
            margin-bottom: 16px;
            padding: 20px 24px 20px 74px;
            background: #fff6e9;
            border-radius: 14px;

            &::before {
                position: absolute;
                top: 24px;
                left: 24px;
                width: 33px;
                height: 33px;
                content: "";

                @include bg("/img/crm/joins/bank-info.svg", contain, left top);
            }

            &Inner {
                width: 100%;
                max-width: 210px;
            }

            &Title {
                max-width: 100%;
                margin-bottom: 3px;
                color: $colorDark2;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.3;
            }

            &Description {
                max-width: 100%;
                color: $colorDark2;
                font-weight: 300;
                font-size: 14px;
                line-height: 1.3;
            }
        }

        &Wallet {
            &Tabs {
                width: calc(100% + 40px);
                margin: 10px 0 12px;
                padding: 0 40px;
                border-bottom: 1px solid #d8d8d8;
            }

            &Tab {
                display: block;
                flex: 1 auto;

                &Input {
                    display: none;
                }

                &View {
                    position: relative;
                    align-items: center;
                    padding: 12px 0;
                    color: $colorDark2;
                    font-size: 16px;
                    transition: $trans;

                    &::before {
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        z-index: 2;
                        width: 100%;
                        height: 2px;
                        background: $colorMain;
                        border-radius: 4px 4px 0 0;
                        opacity: 0;
                        transition: $trans;
                        content: "";
                    }
                }

                &Input:checked ~ &View {
                    color: $colorMain;
                    font-weight: 600;

                    &::before {
                        opacity: 1;
                    }
                }
            }

            &Blocks {
                position: relative;
                width: 100%;
                margin-top: 20px;
                margin-bottom: 16px;
                transition: $trans;
            }

            &Block {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;

                    &._prev {
                        transform: translate(-20px, 0);
                    }

                    &._next {
                        transform: translate(20px, 0);
                    }
                }
            }

            &Blocks._static &Block {
                position: relative;

                &:not(._show) {
                    display: none;
                }
            }
        }
    }

    &__blocksInner._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__policy {
        max-width: 100%;
        margin-top: 20px;
        padding-bottom: 4px;
        color: $colorDark2;
        font-size: 11px;
        line-height: 1.65;
        text-align: center;

        & span {
            color: $colorMain;
            font-weight: 600;
            border-bottom: 1px solid rgba($colorMain, 0.5);
        }
    }

    &__telegram {
        align-items: center;
        width: 100%;
        margin-top: 12px;

        &Button {
            width: 100%;
            height: 56px;
            margin-bottom: 16px;
        }

        &Description {
            position: relative;
            margin-left: 10px;
            padding-right: 24px;
            color: $colorDark;
            font-size: 16px;

            & b {
                font-weight: 600;
            }

            &Icon {
                position: absolute;
                right: 0;
                bottom: 5px;
                width: 20px;
                height: 20px;
                object-fit: contain;
            }
        }
    }

    &__statuses {
        width: 100%;
        margin: 16px 0;
    }

    &__status {
        display: block;
        width: 100%;

        & + & {
            margin-top: 12px;
        }

        &Input {
            display: none;
        }

        &Content {
            position: relative;
            width: 100%;
            padding: 20px 26px;
            background: #f2f4f7;
            border: 1px solid transparent;
            border-radius: 14px;
            transition: $trans;
        }

        &._error &Content {
            background: $colorAlertBack !important;
            border-color: $colorAlert !important;
        }

        &._link &Content {
            padding: 21px 27px;
            background: #ecf1ff;
            border: 0;

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                width: 2px;
                height: calc(100% - 40px);
                background: $colorMain;
                border-radius: 0 360px 360px 0;
                transform: translate(0, -50%);
                content: "";
            }
        }

        &Input:checked ~ &Content {
            border-color: $colorMain;
        }

        &Title {
            max-width: 100%;
            margin-bottom: 3px;
            padding-right: 30px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.3;
            transition: $trans;
        }

        &Description {
            max-width: 100%;
            color: $colorDark2;
            font-weight: 300;
            font-size: 14px;
            line-height: 1.3;
            transition: $trans;
        }

        &._error &Title,
        &._error &Description {
            color: $colorAlert;
        }

        &._error &Description {
            font-weight: 500;
        }

        &Point {
            position: absolute;
            top: 12px;
            right: 12px;
            z-index: 2;
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 50%;

            &::after {
                display: block;
                width: 10px;
                height: 10px;
                margin: auto;
                background: $colorMain;
                border-radius: 50%;
                opacity: 0;
                transition: $trans;
                content: "";
            }
        }

        &Input:checked ~ &Content &Point::after {
            opacity: 1;
        }

        &Arrow {
            position: absolute;
            top: 24px;
            right: 22px;
            z-index: 2;
            width: 20px;
            height: 12px;

            @include icon($colorMain);
        }
    }

    &__statusPages {
        position: relative;
        width: 100%;
        transition: $trans;
    }

    &__statusPage {
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        width: 100%;
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._next {
                transform: translate(100px, 0);
            }

            &._prev {
                transform: translate(-100px, 0);
            }
        }
    }

    &__statusPages._static &__statusPage {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__instruction {
        align-items: center;
        width: calc(100% + 32px);
        margin-top: 56px;
        padding: 20px;
        background: #edf3fc;
        border-radius: 20px 20px 0 0;

        &Logo {
            width: 72px;
            height: 72px;
            margin: -56px 0 12px;
            background: #fff;
            border-radius: 18px;
            box-shadow: 0 3px 24px 0 rgb(51 91 210 / 16%);

            &Icon {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &Title {
            max-width: 300px;
            margin-bottom: 32px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 24px;
            line-height: 1.4;
            text-align: center;
        }

        & .appInstruction__block,
        & .appInstruction__result {
            @include theme("light") {
                background: #fff;
            }
        }

        & .appInstruction__blockListItemIconInner {
            @include theme("light") {
                background: #edf3fc;
            }
        }
    }

    &__fnsLogo {
        width: 80px;
        height: 80px;
        margin: 0 auto 12px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0 0 12px 0 rgb(0 0 0 / 12%);

        &Icon {
            width: 54px;
            height: 56px;
            margin: auto;
            object-fit: contain;
        }
    }

    &__smzInstruction {
        width: 100%;
        margin: 20px 0;

        &Block {
            width: 100%;

            & + & {
                margin-top: 14px;
            }

            &Content {
                width: 100%;
                margin-bottom: 1px;
                padding: 20px 26px;
                border-radius: 14px 14px 1px 1px;

                @include theme("light") {
                    background: #f2f4f7;
                }

                @include theme("dark") {
                    background: #25292c;
                }
            }

            &Title {
                max-width: 100%;
                margin-bottom: 5px;
                font-weight: 600;
                font-size: 16px;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }

            &Description {
                max-width: 100%;
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 1.3;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }
            }

            &Info {
                align-items: center;
                font-size: 14px;
                line-height: 1.3;

                @include theme("light") {
                    color: $colorDark2;
                }

                @include theme("dark") {
                    color: #fff;
                }

                &Icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 6px;
                }
            }

            &Button {
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 56px;
                font-weight: 600;
                font-size: 16px;
                border-radius: 1px 1px 14px 14px;

                @include theme("light") {
                    color: $colorMain;
                    background: #e7edff;
                }

                @include theme("dark") {
                    color: #aec2ff;
                    background: #1c1f21;
                }
            }
        }
    }

    &__fnsPartner {
        align-items: center;
        width: 100%;
        margin-top: 20px;
        padding: 14px;
        background: #edf1ff;
        border-radius: 14px;

        &Logo {
            width: 64px;
            height: 64px;
            background: #fff;
            border-radius: 11px;

            &Icon {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &Content {
            width: calc(100% - 64px);
            padding-left: 14px;
            color: $colorDark2;
            font-weight: 300;
            font-size: 16px;
            line-height: 1.4;

            & b {
                font-weight: 600;
            }
        }
    }

    &__smzForm {
        width: 100%;
        margin: 20px 0;

        &Tabs {
            position: relative;
            z-index: 1;
            align-items: center;
            justify-content: center;
            width: 100%;

            &::before {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: calc(100% + 40px);
                height: 1px;
                background: #d8d8d8;
                transform: translate(-50%, 0);
                content: "";
            }
        }

        &Tab {
            display: block;

            & + & {
                margin-left: 28px;
            }

            &Input {
                display: none;
            }

            &View {
                position: relative;
                padding-bottom: 14px;
                color: $colorDark2;
                font-size: 18px;
                line-height: 1.3;
                transition: $trans;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: $colorMain;
                    border-radius: 360px 360px 0 0;
                    opacity: 0;
                    transition: $trans;
                    content: "";
                }
            }

            &Input:checked ~ &View {
                color: $colorMain;
                font-weight: 600;

                &::after {
                    opacity: 1;
                }
            }
        }

        &Fields {
            position: relative;
            width: 100%;
            height: 56px;
            margin-top: 26px;
        }

        &Field {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: $trans;

            &:not(._show) {
                opacity: 0;

                &._next {
                    transform: translate(50px, 0);
                }

                &._prev {
                    transform: translate(-50px, 0);
                }
            }
        }

        &Fields._static &Field {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }
    }

    &__action {
        width: 100%;
        padding: 26px;
        background: #eef2ff;
        border-radius: 24px;

        & + & {
            margin-top: 16px;
        }

        &Support {
            margin-bottom: 8px;
            color: $colorDark2;
            font-size: 16px;
            line-height: 1.3;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        &Title {
            max-width: 100%;
            margin-bottom: 5px;
            color: $colorDark2;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.3;

            & b {
                font-weight: 600;
            }
        }

        & .join__blockText {
            text-align: left;
        }

        & ._link {
            border-bottom: 1px solid rgba($colorMain, 0.5);
        }
    }
}
