.appOrder {
    width: 100%;
    position: relative;

    &__blocks {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__block {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-64px, 0);
            }

            &._next {
                transform: translate(64px, 0);
            }
        }
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__move {
        width: 100%;

        &Head {
            width: 100%;
            position: relative;
            transition: $trans;
            z-index: 1;
            overflow: hidden;

            &Block {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: $trans;
                padding: $padding;
                will-change: transform;

                &:not(._show) {
                    opacity: 0;

                    &._prev {
                        transform: translate(-64px, 0);
                    }

                    &._next {
                        transform: translate(64px, 0);
                    }
                }
            }

            &._static &Block {
                position: relative;

                &:not(._show) {
                    display: none;
                }
            }
        }

        &Info {
            width: 100%;
            position: relative;
            padding-left: 48px;

            &._move {
                padding-right: 80px;
            }

            &Number {
                position: absolute;
                top: 2px;
                left: 0;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                border: 1px solid transparent;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 600;
                transition: $trans;

                @include theme('light') {
                    border-color: $colorMain;
                    color: $colorMain;
                }

                @include theme('dark') {
                    border-color: #AEC2FF;
                    color: #AEC2FF;
                }

                &._active {
                    background: #FFCA23;

                    @include theme('light') {
                        border-color: #FFCA23;
                        color: #fff;
                    }

                    @include theme('dark') {
                        border-color: #FFCA23;
                        color: $colorDark2;
                    }
                }
            }

            &Title {
                width: 100%;
                height: 20px;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 2px;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }

                &._bold {
                    font-weight: 900;
                }
            }

            &Description {
                max-width: 100%;
                font-size: 12px;
                line-height: 1.4;
                margin-bottom: 2px;
                align-items: center;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }

                &Item {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 1.4;
                    white-space: nowrap;
                    color: $colorSuccess;

                    &._parent {
                        margin-left: 3px;
                    }
                }
            }

            &Sub {
                font-size: 12px;
                padding: 3px 5px;
                border-radius: 5px;
                margin-left: -5px;

                @include theme('light') {
                    background: rgba(#FFCA23, .14);
                    color: $colorDark2;
                }

                @include theme('dark') {
                    background: rgba(#FFCA23, .25);
                    color: #fff;
                }
            }

            &Times {
                position: absolute;
                top: 0;
                right: 0;
                align-items: flex-end;
                z-index: 2;

                &All {
                    font-size: 18px;
                    font-weight: 600;
                    color: $colorDark2;
                    line-height: 20px;
                    margin-bottom: 4px;

                    @include theme('light') {
                        color: $colorDark2;
                    }

                    @include theme('dark') {
                        color: #fff;
                    }
                }

                &Current {
                    font-size: 12px;
                    font-weight: 600;
                    color: #B5BDC5;
                    white-space: nowrap;
                }
            }
        }

        &Road {
            width: 100%;
            height: 16px;
            margin-top: 12px;
            position: relative;
            align-items: center;
            padding-right: 16px;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #5CC962;
                z-index: 2;
            }

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
                width: 16px;
                height: 16px;
                z-index: 2;
                @include bg('../img/app/order/finish.svg', contain, center);
            }

            &Inner {
                width: 100%;
                position: relative;
            }

            &Line {
                width: 100%;
                height: 1px;
                background: rgba(#B5BDC5, .56);

                &Progress {
                    width: 0%;
                    height: 100%;
                    background: #5CC962;
                    transition: $trans;
                }
            }

            &Cursor {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                width: 16px;
                height: 18px;
                z-index: 3;
                margin-left: -2px;
                transition: $trans;

                @include theme('light') {
                    @include icon($colorDark2);
                }

                @include theme('dark') {
                    @include icon(#B5BDC5);
                }
            }
        }

        &Content {
            width: 100%;
            padding: 12px $padding 78px $padding;

            @include theme('light') {
                background: #f4f4f4;
            }

            @include theme('dark') {
                background: #1C1F21;
            }
        }

        &Actions {
            width: 100%;
            margin-bottom: 12px;
        }

        &Foot {
            width: 100%;
            position: relative;
            transition: $trans;

            &Block {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                transition: $trans;
                will-change: transform;
                align-items: center;

                &:not(._show) {
                    opacity: 0;

                    &._prev {
                        transform: translate(-64px, 0);
                    }

                    &._next {
                        transform: translate(64px, 0);
                    }
                }
            }

            &._static &Block {
                position: relative;

                &:not(._show) {
                    display: none;
                }
            }

            &Action {
                margin: 19px auto 0 auto;
            }
        }

        &Button {
            width: 100%;
            height: 56px;
        }
    }

    &__actions {
        width: 100%;
        height: 56px;
        justify-content: space-between;
    }

    &__actions._final &__action {
        width: 100%;

        &Inner {
            width: auto;
            margin-left: -14px;
        }
    }

    &__action {
        width: calc(50% - 4px);
        height: 100%;
        border-radius: 12px;
        padding: 9px 12px 12px 18px;
        transition: $trans;
        position: relative;
        z-index: 1;
        justify-content: center;

        @include theme('light') {
            background: #F8FAFD;
        }

        @include theme('dark') {
            background: #25292C;
        }

        &._white {
            @include theme('light') {
                background: #fff;
            }
        }

        &._disabled {
            opacity: 0.64;
            pointer-events: none;
        }

        &Loader {
            border-radius: 12px;
            background: inherit;
            z-index: 9;
        }

        &Counter {
            position: absolute;
            height: 18px;
            min-width: 18px;
            left: 100%;
            bottom: 100%;
            margin: 0 0 -14px -14px;
            z-index: 10;

            & .notificationCounter {
                border-radius: 50% !important;
                min-width: 18px !important;

                &__inner {
                    font-size: 12px !important;
                }
            }
        }

        &Inner {
            max-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;
            padding-left: 32px;
        }

        &._chat &Inner {
            padding-left: 35px;
        }

        &Icon {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: 2px;
            transform: translate(0, -50%);
            width: 22px;
            height: 22px;
        }

        &._chat &Icon {
            width: 25px;
            height: 28px;
        }

        &Title {
            max-width: 100%;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Description {
            max-width: 100%;
            font-size: 12px;
            line-height: 1.2;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #B5BDC5;
            }
        }
    }

    &__docs {
        width: 100%;
        padding: $padding $padding 78px $padding;

        @include theme('dark') {
            background: #1C1F21;
        }

        &Head {
            width: 100%;
            height: 20px;
            align-items: center;
            margin-bottom: 14px;

            &Info {
                &._parent {
                    @extend %row;
                    align-items: center;
                }

                &Support {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.3;
                    white-space: nowrap;
                    transition: $trans;

                    @include theme('light') {
                        color: $colorMain;
                    }

                    @include theme('dark') {
                        color: #AEC2FF;
                    }
                }
            }

            &Pagination {
                margin-left: auto;
            }
        }

        &Blocks {
            width: 100%;
            position: relative;
            transition: $trans;
        }

        &Block {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: $trans;
            align-items: center;
            will-change: transform;

            &:not(._show) {
                opacity: 0;

                &._prev {
                    transform: translate(-64px, 0);
                }

                &._next {
                    transform: translate(64px, 0);
                }
            }
        }

        &Blocks._static &Block {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }

        &Title {
            @extend %title;
            text-align: center;
            margin-bottom: 10px;
        }

        &Actions {
            width: 100%;
            padding-top: 16px;
        }
    }

    &__final {
        width: 100%;
        padding: $padding $padding 78px $padding;
        align-items: center;

        @include theme('dark') {
            background: #1C1F21;
        }

        &Support {
            max-width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            line-height: 23px;
            margin-bottom: 6px;

            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: #AEC2FF;
            }
        }

        &Title {
            @extend %title;
            text-align: center;
            margin-bottom: 10px;
        }

        &Description {
            @extend %text;
            text-align: center;
        }

        &Button {
            width: 100%;
            height: 56px;
            margin-top: 16px;
        }

        &Actions {
            width: 100%;
            margin-top: 16px;
        }
    }

    &__map {
        width: 100%;
        height: 100%;
    }
}