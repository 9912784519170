.appInstruction {
    width: 100%;

    &__block {
        width: 100%;
        border-radius: 24px;
        padding: 24px 28px;

        @include theme('light') {
            background: #F2F4F7;
        }

        @include theme('dark') {
            background: #1C1F21;
        }

        @include forType('app', 'light') {
            background: #EEF2FF;
        }

        &+& {
            margin-top: 14px;
        }

        &Title {
            max-width: 100%;
            font-size: 18px;
            font-weight: 600;
            position: relative;
            margin-bottom: 12px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            &Icon {
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translate(0, -50%);
                width: 16px;
                height: 16px;
                object-fit: contain;
                margin-left: 4px;

                @include theme('light') {
                    @include icon($colorDark2);
                }

                @include theme('dark') {
                    @include icon(#fff);
                }
            }
        }

        &List {
            width: 100%;
            counter-reset: listCounter;

            &Item {
                width: 100%;
                padding-left: 30px;
                font-size: 16px;
                line-height: 1.45;
                counter-increment: listCounter;
                position: relative;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }

                & a {
                    border-bottom: 1px solid transparent;

                    @include theme('light') {
                        color: $colorMain;
                        border-color: rgba($colorMain, .64);
                    }

                    @include theme('dark') {
                        color: #AEC2FF;
                        border-color: rgba(#AEC2FF, .64);
                    }
                }

                &::before {
                    display: flex;
                    flex-direction: column;
                    content: counter(listCounter);
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 18px;
                    height: 18px;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background: $colorMain;
                    border-radius: 50%;
                    font-size: 11px;
                    color: #fff;
                    font-weight: 900;
                }

                &+& {
                    margin-top: 10px;
                }

                &Icon {
                    width: 23px;
                    height: 16px;
                    display: inline-block;
                    margin-left: 5px;
                    position: relative;

                    &Inner {
                        width: 23px;
                        height: 23px;
                        border-radius: 5px;
                        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.04);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        margin-top: 3px;

                        @include theme('light') {
                            background: #fff;
                        }

                        @include theme('dark') {
                            background: #2B2F33;
                        }
                    }

                    &Item {
                        width: 13px;
                        height: 13px;
                        margin: auto;

                        @include theme('light') {
                            @include icon($colorDark2);
                        }

                        @include theme('dark') {
                            @include icon(#fff);
                        }

                        &._menu {
                            width: 11px;
                            height: 11px;
                        }
                    }
                }
            }
        }
    }

    &__result {
        width: 100%;
        margin-top: 14px;
        position: relative;
        padding: 20px 28px;
        border-radius: 24px;
        font-size: 18px;
        line-height: 1.6;
        z-index: 1;

        @include theme('light') {
            background: #EBF0FF;
            color: $colorDark2;
        }

        @include theme('dark') {
            background: rgba($colorMain, .2);
            color: #fff;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: 3px;
            height: calc(100% - 48px);
            border-radius: 0 360px 360px 0;
            background: $colorMain;
            z-index: 2;
        }

        & b {
            font-weight: 600;
        }
    }
}