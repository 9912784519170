.v2popupChats {
    width: 100%;
    height: 100%;

    &._move {
        user-select: none;
        pointer-events: none;
    }

    &__blocks {
        width: 100%;
        height: 100%;

        &Item {
            width: 100%;
            height: 100%;
        }
    }

    &__block {
        width: 100%;
        height: 100%;
    }

    & .chatWindowHead__info {
        user-select: none;
        pointer-events: none;
    }

    & .chatWindowHead__infoPreview {
        pointer-events: visible;
    }
}
