.popup {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include mediaMin($mediaM + 1px) {
        @include popupBack;

        // background: #f8f8f8;
        // opacity: 1;
    }

    @include media(0, $mediaM) {
        justify-content: flex-start;
        transition: $transLong;
    }

    &__inner {
        position: relative;
        z-index: 1;
        width: 380px;
        transform: translate(0, 16px);
        transition: $transLong;

        @include media(0, $mediaM) {
            width: 100%;
            max-width: 440px;
            margin-top: auto;
            overflow: visible;
        }

        &._wide {
            width: 440px;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._large {
            width: 640px;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &Box {
            position: relative;
            width: 100%;
            padding: 32px;
            overflow: hidden;
            border-radius: 26px;

            @include theme("dark") {
                background: $colorDarkTheme2;
                box-shadow: 0 0 5px 0 rgb(0 0 0 / 40%);

                @include media(0, $mediaM) {
                    box-shadow: none;
                }
            }

            @include theme("light") {
                background: #fff;
                box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);

                @include media(0, $mediaM) {
                    box-shadow: none;
                }
            }

            &._scroll {
                @extend %noscroll;

                overflow-y: auto;
            }

            @include media(0, $mediaM) {
                padding: 20px;
            }
        }

        &Content {
            position: relative;
            width: 100%;
        }
    }

    // &._cargoImages &__inner{
    //     border-radius: 26px;
    //     overflow: hidden;
    // }
    &._pay &__inner {
        @include media(0, $mediaM) {
            box-shadow: none;
        }
    }

    &._pay &__innerBox {
        margin-top: 3px;
        border-radius: 4px 4px 26px 26px;
        transition: $trans;

        @include media(0, $mediaM) {
            border-radius: 4px 4px 0 0;
        }
    }

    &._center &__innerBox {
        align-items: center;
        text-align: center;
    }

    &._cargoImages &__innerBox {
        padding-bottom: 0;
    }

    .body__page._show &,
    .body__popup._show & {
        @include media(0, $mediaM) {
            @include popupBack;
        }
    }

    .body__page._show &__inner,
    .body__popup._show &__inner {
        transform: translate(0, 0);

        @include media(0, $mediaM) {
            transform: translate(0, 0);
        }
    }

    &._auth &__innerBox {
        align-items: center;
    }

    &__preview {
        position: absolute;
        z-index: 10;
        width: 146px;
        height: 192px;
        overflow: hidden;
        border-radius: 14px;
        opacity: 0;
        transition: $trans;

        @include theme("light") {
            background: #fff;
        }

        @include theme("dark") {
            background: $colorDarkTheme2;
        }

        &._show {
            border-radius: 26px;
            opacity: 1;
        }

        &Image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 2;
        display: block;
        width: 20px;
        height: 20px;
        transition: $trans;

        @include close-circle;

        @include media(0, $mediaM) {
            top: 20px;
            right: 20px;
            width: 24px;
            height: 24px;
        }
    }

    &__icon {
        width: 64px;
        height: 64px;
        margin-bottom: 8px;
        object-fit: contain;
    }

    &__support {
        max-width: 100%;
        margin-bottom: 7px;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.5px;
        text-transform: uppercase;

        @include media(0, $mediaM) {
            font-size: 15px;
        }

        @include theme("light") {
            color: $colorMain;
        }

        @include theme("dark") {
            color: $colorMainTheme;
        }
    }

    &__title {
        max-width: 100%;
        margin-bottom: 8px;
        font-weight: 900;
        font-size: 19px;
        line-height: 1.35;

        @include media(0, $mediaM) {
            font-size: 24px;
        }

        @include theme("dark") {
            color: #fff;
        }

        @include theme("light") {
            color: #202020;
        }

        &._bigSize {
            font-size: 22px;

            @include media(0, $mediaM) {
                font-size: 24px;
                line-height: 1.2;
            }
        }
    }

    &._center &__title {
        @include media(0, $mediaM) {
            max-width: 320px;
        }
    }

    &._mobileProfile &__title {
        @include media(0, $mediaM) {
            max-width: 300px;
        }
    }

    &__description {
        max-width: 100%;
        font-weight: 400;
        font-size: 16.5px;
        line-height: 1.4;

        @include theme("dark") {
            color: #fff;
        }

        @include theme("light") {
            color: #202020;
        }

        @include media(0, $mediaM) {
            font-size: 18px;
        }

        &._bold {
            font-weight: 600;
        }

        &Item {
            color: $colorMain;
            font-weight: 600;
        }

        & + & {
            margin-top: 4px;
        }

        & a {
            font-weight: 600;

            @include theme("light") {
                color: $colorMain;
            }

            @include theme("dark") {
                color: $colorMainTheme;
            }
        }
    }

    &._center &__description {
        @include media(0, $mediaM) {
            max-width: 320px;
        }
    }

    &._auth &__description {
        text-align: center;
    }

    &__actions {
        @extend %noscroll;

        width: calc(100% + 52px);
        height: 192px;
        margin-top: -9px;
        margin-left: -26px;
        overflow-x: auto;

        @include media(0, $mediaM) {
            height: 200px;
        }

        &Inner {
            position: relative;
            display: inline-flex;
            height: 100%;
            padding: 23px 26px;

            &._isDelete {
                overflow: hidden;
            }
        }
    }

    &._uploadTemplate &__actions {
        width: 100%;
        height: 146px;
        margin-top: 0;
        margin-top: 16px;
        margin-left: 0;

        &Inner {
            width: 100%;
            padding: 0;

            // height: 169px;
        }
    }

    &__buttons {
        width: 100%;
    }

    &__button {
        position: relative;
        z-index: 2;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        margin-top: 12px;
        font-weight: 500;
        font-size: 16.5px;
        border-radius: 14px;
        transition: $trans;

        @include button;

        @include theme("light") {
            color: $colorMain;
            background: #f3f6ff;
        }

        @include theme("dark") {
            color: $colorMainTheme;
            background: $colorDarkTheme3;
        }

        @include media(0, $mediaM) {
            height: $heightFieldMobile;
            font-weight: 600;
            font-size: 18px;
        }

        &._empty {
            height: auto;

            @include theme("light") {
                color: $colorMain;
                background: none;
            }

            @include theme("dark") {
                color: $colorMainTheme;
                background: none;
            }
        }

        &Icon {
            width: 20px;
            height: 20px;
            margin-left: 5px;

            @include theme("light") {
                @include icon($colorMain);
            }

            @include theme("dark") {
                @include icon($colorMainTheme);
            }
        }
    }

    &__upload {
        position: relative;
        z-index: 1;
        display: block;
        min-width: 174px;
        height: 100%;
        transition: $trans;

        &Input {
            display: none;
        }

        &Back {
            @extend %backBorderDashed;
        }

        &View {
            position: relative;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 26px 22px;
            transition: $trans;
            will-change: transform;

            &:not(._show) {
                transform: translate(0, -6px);
                opacity: 0;
            }
        }

        &:active &View &Icon {
            transform: translate(0, 2px);
        }

        &Drag {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            transition: $trans;

            &:not(._show) {
                transform: translate(0, 6px);
                opacity: 0;
            }
        }

        &Icon {
            position: relative;
            width: 24px;
            height: 24px;
            margin-bottom: 6px;
            transition: $trans;

            @include media(0, $mediaM) {
                width: 28px;
                height: 28px;
            }

            &Item {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;

                @include theme("light") {
                    @include icon($colorMain);
                }

                @include theme("dark") {
                    @include icon($colorMainTheme);
                }

                &._isLoad {
                    z-index: 2;
                }
            }
        }

        &Description {
            font-weight: 500;
            font-size: 16.5px;
            line-height: 1.3;
            text-align: center;

            @include media(0, $mediaM) {
                font-size: 18px;
            }

            @include theme("light") {
                color: $colorMain;
            }

            @include theme("dark") {
                color: $colorMainTheme;
            }

            & span {
                font-weight: 600;
            }
        }
    }

    &._uploadTemplate &__upload {
        width: 100%;
    }

    &__images {
        position: relative;
        height: 100%;
        transition: $transLong;

        &Item {
            position: absolute;
            top: 0;
            left: 0;
            width: 146px;
            height: 100%;
            transition: $transLong;

            &._delete {
                z-index: 3;
            }
        }
    }

    &__preview &__imageInner {
        // opacity: 1;
        transform: scale(1);
    }

    &__preview &__imageItem {
        object-fit: contain;
    }

    &__imagesItem._show &__imageInner,
    &__preview._show &__imageInner {
        transform: scale(1);
        opacity: 1;
    }

    &__imagesItem._delete &__imageInner,
    &__preview._delete &__imageInner {
        transform: scale(1.2);
        transform-origin: left center;
        opacity: 0;
    }

    &__image {
        width: 100%;
        height: 100%;

        &Inner {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 14px;
            transform: scale(1.1);
            opacity: 0;
            transition: $transLong;
        }

        &Action {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;
            width: 26px;
            height: 26px;

            @include media(0, $mediaM) {
                width: 32px;
                height: 32px;
            }
        }

        &Item {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &Close {
            position: absolute;
            top: 16px;
            right: 16px;
            z-index: 2;
            width: 20px;
            height: 20px;

            @include icon(rgba($colorDark, 0.8));
            @include close-circle;
        }
    }

    &__policy {
        width: 100%;
        margin-top: 12px;
        text-align: left;

        &Inner {
            width: 100%;

            @include media(0, $mediaM) {
                max-width: 330px;
            }
        }
    }

    &__point {
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 16px 0;

        &Field {
            width: 100%;

            & + & {
                margin-top: 10px;
                padding-top: 10px;
                border-top: 1px solid transparent;

                @include theme("light") {
                    border-color: #eee;
                }

                @include theme("dark") {
                    border-color: $colorDarkTheme;
                }
            }

            &Inner {
                position: relative;
                width: 100%;
                padding-left: 36px;
            }

            &Icon {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 32px;
                height: 32px;

                @include theme("light") {
                    @include icon($colorGreyDark2);
                }

                @include theme("dark") {
                    @include icon($colorDarkThemeText);
                }
            }

            &._time &Icon {
                transform: scale(1.1);
            }

            &._complete &Icon {
                @include theme("light") {
                    @include icon($colorMain);
                }

                @include theme("dark") {
                    @include icon($colorMainTheme);
                }
            }

            &Box {
                width: 100%;
            }

            &._input &Box {
                height: 32px;
            }

            &._area &Box {
                height: 120px;
                padding-top: 4px;
            }
        }
    }

    &__cargo {
        position: relative;
        z-index: 3;
        width: 100%;
    }
}
