.orderPreview {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid transparent;

    @include theme('light') {
        background: #FCFDFE;
    }

    @include theme('dark') {
        background: #25292C;
        border-color: #2B2F33;
    }

    &__inner {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__content {
        width: 100%;
        position: relative;
    }

    &__number {
        max-width: 100%;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 6px;
        position: relative;
        padding-left: 24px;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }

        &Icon {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            width: 16px;
            height: 16px;
        }
    }

    &__addresses {
        max-width: 240px;
    }

    &__address {
        max-width: 100%;
        font-size: 16px;
        line-height: 1.3;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }

        &+& {
            margin-top: 2px;
        }
    }

    &__date {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 16px;
        line-height: 1.3;
        color: #B5BDC5;
    }

    &__icon {
        position: absolute;
        bottom: -2px;
        right: -2px;
        width: 24px;
        height: 24px;

        @include theme('light') {
            @include icon(#D4DBE1);
        }

        @include theme('dark') {
            @include icon(#3E454C);
        }
    }

    &__corporation {
        width: calc(100% + 32px);
        margin: 12px 0 0 -16px;
        padding: 12px 16px 0 16px;
        border-top: 1px solid transparent;

        @include theme('light') {
            border-color: #e4e9eb;
        }

        @include theme('dark') {
            border-color: #1C1F21;
        }

        &Logo {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @include theme('light') {
                background: rgba($colorBlue, .5);
            }

            @include theme('dark') {
                background: #1C1F21;
            }
        }

        &Name {
            max-width: calc(100% - 24px);
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;
            padding-left: 8px;
            padding-top: 2px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            & span {
                font-weight: 500;
            }
        }
    }
}