.body {
    @extend %noscroll;

    position: relative;
    width: 100%;
    text-size-adjust: none;

    // cursor: none;
    html._hidden {
        overflow: hidden;
    }

    &__content {
        position: relative;
        width: 100%;
        min-height: 100vh;
        transition: $transLong;

        &._dark {
            background: $colorDarkTheme;
        }

        &._light {
            background: $colorLight;
        }

        &._seo {
            visibility: hidden;
            opacity: 0;
        }
    }

    &__page {
        @extend %noscroll;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        min-height: 100%;
        transition: $trans;

        &._land:not(._popup) {
            padding-top: $heightMenu + $heightTopBar;

            @include media(0, $mediaM) {
                padding-top: $heightMenuMobile;
            }

            @include theme("dark") {
                background: $colorDarkTheme;

                @include media(0, $mediaM) {
                    background: #111418;
                }
            }

            @include theme("light") {
                background: $colorLight;
            }
        }

        &._popup {
            position: fixed;
            z-index: 1001;
        }

        &:not(._show) {
            opacity: 0;
            pointer-events: none;

            &._popup {
                transform: scale(1.02);
            }
        }
    }

    &__section {
        width: 100%;

        & + & {
            @include media(0, $mediaM) {
                margin-top: 20px;
            }
        }

        &._footer {
            margin-top: auto;
        }
    }

    &__theme {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: $transLong;

        &._show {
            opacity: 1;
        }
    }

    &__alerts {
        position: fixed;
        top: 80px;
        right: 22px;
        z-index: 100000;
        width: 240px;
        pointer-events: none;

        @include forType("crm") {
            top: 24px;
            right: 32px;
            width: 260px;

            @include media(0, $mediaM) {
                top: 20px;
                right: 20px;
                width: calc(100% - 40px);
            }
        }

        @include forType("app") {
            top: 24px;
            right: 32px;
            width: 260px;

            @include media(0, $mediaM) {
                top: 20px;
                right: 20px;
                width: calc(100% - 40px);
            }
        }

        @include media(0, $mediaM) {
            top: $heightMenuMobile + 20px;
            right: 20px;
            width: calc(100% - 40px);
        }
    }

    &__popup {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10002;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: $trans;

        @include media(0, $mediaM) {
            &:not(._galery) {
                opacity: 1;
            }
        }

        &._show {
            opacity: 1;
        }

        &._info,
        &._cargoImages {
            z-index: 1001;
        }

        &._createCorporation,
        &._executorInvite {
            z-index: 103;
            background: rgba(#000, 0.32);
        }

        &._createCorporation {
            @include blur(20px);
        }
    }

    &__supportCursor {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100001;
        pointer-events: none;
        will-change: transform;

        &Inner {
            width: 100%;
            height: 100%;
            visibility: hidden;
            opacity: 0;
            transition: $trans;
            will-change: opacity;
        }

        &._show &Inner {
            visibility: visible;
            opacity: 1;
        }
    }

    &__chatFix {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 101;
        width: 100%;
        height: 100%;
        transition: $trans;

        &Wrap {
            z-index: 101;
            opacity: 0;
            transition: $trans;

            @include media(0, $mediaM) {
                z-index: 105;
            }

            &._show {
                opacity: 1;
            }
        }

        &._separate {
            position: relative;
            z-index: auto;
        }

        &:not(._separate)::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: $trans;
            content: "";

            @include popupBack;
        }

        &._isShow::before {
            opacity: 1;
        }

        &:not(._separate, ._isShow) {
            pointer-events: none;
        }
    }

    &__window {
        position: absolute;
        z-index: 2000;

        &._actions {
            margin-top: -8px;
            transform: translate(-50%, -100%);

            &._right {
                transform: translate(-100%, -100%);
            }

            &._left {
                transform: translate(0, -100%);
            }
        }

        &._list {
            width: 256px;
            margin-top: 24px;
            transform: translate(-50%, 0);

            &._chatUserSettings {
                width: 280px;
            }

            &._reverseY {
                margin-top: -56px;
                transform: translate(-50%, -100%);
            }

            &._right {
                margin-top: 0;
                margin-left: -28px;
                transform: translate(-100%, -50%);
            }
        }

        &._likeExecutor {
            width: 320px;
            margin-top: 24px;
            transform: translate(-50%, 0);

            &._reverseY {
                margin-top: -40px;
                transform: translate(-50%, -100%);
            }
        }

        &._filter {
            width: $widthFilter;
            margin-top: 24px;
            transform: translate(-50%, 0);

            &._right {
                margin-left: 23px;
                transform: translate(-100%, 0);

                &._reportsRegistry {
                    margin-left: 38px;
                }
            }
        }

        &._prompt {
            width: 232px;
            margin-top: 20px;
            transform: translate(-50%, 0);

            &._userAdd {
                margin-top: 16px;
            }

            &._reverseY {
                margin-top: -40px;
                transform: translate(-50%, -100%);
            }

            &._left {
                margin-top: 0;
                margin-left: 20px;
                transform: translate(0, -50%);
            }

            &._right {
                margin-left: 30px;
                transform: translate(-100%, 0);

                &._reverseY {
                    margin-top: -28px;
                    transform: translate(-100%, -100%);
                }

                &._bottom {
                    margin-top: -38px;
                    transform: translate(-100%, -100%);
                }
            }

            &._tax {
                margin-top: 0;
                margin-left: -20px;
                transform: translate(-100%, -50%);
            }

            &._cancelEdo {
                width: 200px;
                margin-top: -18px;
                transform: translate(-50%, -100%);
            }

            &._contract {
                width: 210px;
                margin-top: 0;
                margin-left: -10px;
                transform: translate(-100%, -50%);
            }

            &._contractAlready {
                width: 210px;
                margin-top: -40px;
                transform: translate(-50%, -100%);
            }

            &._roleUsersLoseProxies {
                width: 250px;
                margin-top: -20px;
                margin-left: 0;
                transform: translate(-100%, -100%);
            }

            &._roleUserLoseProxy {
                width: 250px;
                margin-top: -20px;
                margin-left: 0;
                transform: translate(0, -100%);
            }
        }

        &._statusesOrder {
            margin-left: 20px;
            transform: translate(0, -50%);
        }

        &._feedbackOrder {
            margin-left: -20px;
            transform: translate(-100%, -50%);
        }

        &._listBodyworks {
            width: 190px;
            margin-top: 24px;
            transform: translate(-50%, 0);
        }

        &._executorTariff {
            width: 320px;
            margin: 20px 0 0 32px;
            transform: translate(-100%, 0);

            &._reverseY {
                margin: -48px 0 0 32px;
                transform: translate(-100%, -100%);
            }
        }

        &._editPanel {
            margin-top: -20px;
            transform: translate(-50%, -100%);
        }

        &._emodzi {
            width: 280px;
            margin-top: 20px;
            transform: translate(-50%, 0);

            &._reverseY {
                margin-top: -36px;
                transform: translate(-50%, -100%);
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: $trans;
        }

        &._prompt &Inner,
        &._list &Inner,
        &._filter &Inner,
        &._executorTariff &Inner,
        &._editPanel &Inner,
        &._likeExecutor &Inner,
        &._emodzi &Inner {
            transform: translate(0, 6px);
        }

        &._prompt._left &Inner {
            transform: translate(6px, 0);
        }

        &._list._right &Inner {
            transform: translate(-6px, 0);
        }

        &._statusesOrder &Inner {
            transform: translate(6px, 0);
        }

        &._feedbackOrder &Inner {
            transform: translate(-6px, 0);
        }

        &._listBodyworks &Inner {
            transform: translate(0, 6px);
        }

        &._prompt._tax &Inner {
            transform: translate(6px, 0);
        }

        &._prompt._contract &Inner {
            transform: translate(6px, 0);
        }

        &._prompt._contractAlready &Inner {
            transform: translate(0, -6px);
        }

        &._prompt._roleUsersLoseProxies &Inner {
            transform: translate(0, -6px);
        }

        &._show &Inner {
            opacity: 1;
        }

        &._show &Inner {
            transform: translate(0, 0) !important;
        }
    }

    &__cookie {
        position: fixed;
        bottom: 40px;
        left: 50%;
        z-index: 1000;
        width: calc(100% - 40px);
        max-width: 750px;
        transform: translate(-50%, 0);
        transition: $trans;

        &:not(._show) {
            transform: translate(-50%, 0) scale(0.98);
            opacity: 0;
        }
    }

    &__hidden {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        pointer-events: none;

        &Text {
            font-family: PTRoot;

            &._1 {
                font-weight: 300;
            }

            &._2 {
                font-weight: 500;
            }

            &._3 {
                font-weight: 600;
            }

            &._4 {
                font-weight: 900;
            }
        }
    }

    &__infoPopup {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000000;
        width: 100%;
        height: 100%;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            transform: scale(1.03);
            opacity: 0;
        }
    }

    &__v2popup {
        width: 100%;
        height: 100%;

        &._newYear {
            background: linear-gradient(to bottom, #16234b, #0f1a3b);
        }

        &Inner {
            width: 100%;
            height: 100%;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }
    }

    &__newYearStatus {
        z-index: 99;
    }
}
