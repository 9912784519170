.v2search {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 12px 0 32px;
    background: $colorBlueBack;
    border-radius: 10px;
    cursor: text;

    &._white {
        background: #fff;
    }

    &._public {
        padding: 0 1.2rem 0 4.8rem;
        border-radius: 1.3rem;

        @include mob {
            padding-left: 7rem;
            border-radius: 2rem;
        }
    }

    &._knowledge {
        padding: 0 14px 0 33px;
        background: #fbfcfd;
        border-radius: 12px;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 7px;
        width: 22px;
        height: 22px;
        transform: translate(0, -50%);

        @include icon($colorGreyDark);
    }

    &._knowledge &__icon {
        left: 8px;
        width: 22px;
        height: 22px;
    }

    &._public &__icon {
        left: 1.2rem;
        width: 3rem;
        height: 3rem;

        @include icon(#b4bec8);

        @include mob {
            left: 2rem;
            width: 4rem;
            height: 4rem;
        }
    }

    &._public .v2input__field,
    &._public .v2input__support {
        padding: 1rem 0;
        font-size: 1.8rem;

        @include mob {
            font-size: 2.3rem;
        }
    }

    &._knowledge .v2input__field,
    &._knowledge .v2input__support {
        font-size: 15px;
    }

    &__field {
        width: 100%;
        height: 100%;
        padding-bottom: 1px;

        & .v2input__field,
        & .v2input__support {
            padding: 9px 0;
            font-size: 14px;
        }
    }
}
