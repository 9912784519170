.imageLazy {
    width: 100%;
    height: 100%;
    position: relative;

    &__empty {
        position: absolute;
        width: 60%;
        height: 60%;
        max-width: $sizeLoader;
        max-height: $sizeLoader;
        min-width: 16px;
        min-height: 16px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        transition: $trans;

        @include theme('light') {
            @include icon(#eee);
        }

        @include theme('dark') {
            @include icon($colorDarkTheme);
        }

        @include forType('crm', 'light') {
            @include icon($colorBlue);
        }

        @include forType('crm', 'dark') {
            @include icon($colorDarkTheme);
        }

        @keyframes animateLazy {
            0% {
                opacity: 1;
            }

            50% {
                opacity: .5;
            }

            100% {
                opacity: 1;
            }
        }

        // animation: animateLazy 1s infinite ease-in-out;
    }

    &._load &__empty {
        opacity: 0;
    }

    &__item {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: $trans;
        z-index: 1;

        &Inner {
            object-fit: contain;
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    &:not(._load) &__item {
        opacity: 0;
    }

    &._cover &__itemInner {
        object-fit: cover;
    }
}