.edit {
    @extend %shadow;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    transition: $trans;
    @include button(.95);

    @include theme('light') {
        background: #fff;
    }

    @include theme('dark') {
        background: $colorDarkTheme3;
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
        z-index: 2;
        opacity: 0;
        transition: $trans;
        pointer-events: none;
    }

    &._isLoader &__loader {
        opacity: 1;
        pointer-events: visible;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        transition: $trans;
        will-change: transform;

        &._edit {
            @include theme('light') {
                @include icon(#9da8b4);
            }

            @include theme('dark') {
                @include icon($colorDarkThemeText);
            }
        }

        &._done {
            width: 18px;
            height: 18px;
            opacity: 0;
            transform: translate(-50%, calc(-50% - 4px));

            @include theme('light') {
                @include icon($colorMain);
            }

            @include theme('dark') {
                @include icon($colorDarkThemeText);
            }
        }
    }

    &._isShow &__icon {
        &._edit {
            opacity: 0;
            transform: translate(-50%, calc(-50% + 4px));
        }

        &._done {
            opacity: 1;
            transform: translate(-50%, -50%);
        }
    }

    &._isLoader &__icon {

        &._edit,
        &._done {
            opacity: 0;
            transform: translate(-50%, -50%);
        }
    }
}