.payPreview {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 16px;
    border: 1px solid transparent;

    @include theme('light') {
        background: #FCFDFE;
    }

    @include theme('dark') {
        background: #25292C;
        border-color: #2B2F33;
    }

    &__head {
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid transparent;

        @include theme('light') {
            border-color: rgba(#C5CCD3, .72)
        }

        @include theme('dark') {
            border-color: #1C1F21;
        }

        &Title {
            position: relative;
            padding-left: 24px;
            font-size: 20px;
            font-weight: 600;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Icon {
            position: absolute;
            top: 50%;
            left: 0;
            width: 16px;
            height: 16px;
            transform: translate(0, -50%);
        }

        &Periods {
            font-size: 16px;
            line-height: 1.3;
            color: #B5BDC5;
            margin-left: auto;
        }
    }

    &__content {
        width: 100%;
        padding: 20px;
    }

    &__name {
        max-width: 100%;
        font-size: 16px;
        line-height: 1.45;
        font-weight: 600;
        margin-bottom: 5px;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &__details {
        width: 100%;

        &Item {
            width: 100%;

            &:not(._empty)+&:not(._empty) {
                margin-top: 12px;
                border-top: 1px solid transparent;

                @include theme('light') {
                    border-color: rgba(#C5CCD3, .72);
                }

                @include theme('dark') {
                    border-color: #1C1F21;
                }
            }

            &Inner {
                width: 100%;
                justify-content: space-between;
                padding-top: 12px;
            }

            &Value {
                font-size: 15px;
                line-height: 1.3;
                text-align: right;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }

                &._support {
                    font-weight: 600;
                    text-align: left;
                    padding-right: 20px;

                    @include theme('light') {
                        color: #949BA2;
                    }

                    @include theme('dark') {
                        color: #949BA2;
                    }
                }

                &._status {
                    font-weight: 600;
                    white-space: nowrap;

                    &._wait {
                        color: $colorGreyDark;
                    }

                    &._error {
                        color: $colorAlert;
                    }

                    &._completed {
                        color: $colorSuccess;
                    }

                    &._process {
                        color: $colorMedium;
                    }
                }
            }
        }
    }

    &__buttons {
        @extend %static;
        width: 100%;
        height: 0;
        transition: $trans;

        &:not(._empty) {
            height: 50px;
            margin-top: 20px;
        }
    }

    &__button {
        @extend %staticItem;
        width: 100%;
        height: 50px;
        display: block;

        &:not(._show) {
            &._next {
                transform: translate(0, -10px);
            }

            &._prev {
                transform: translate(0, 10px);
            }
        }
    }
}