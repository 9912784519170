$colorDark: #111;
$colorDark2:#363c42;
$colorDark3:#4a525b;
$colorLight:#fff;
$colorGrey: #fafafa;
$colorGreyDark:#9da8b4;
$colorGreyDark2:#a1a5b0;
$colorGreyLight: #f8f8f8;
$colorGreyLight2:#e8e8e8;
$colorGreyDark3:rgb(207, 204, 204);
$colorMain:#3e6aed;
$colorMainDark:#2553db;
$colorDarkTheme:#1d222a;
$colorDarkTheme2:#2f333c;
$colorDarkTheme3:#383d46;
$colorDarkTheme4:#2a2d31;
$colorDarkThemeBorder:#363b45;
$colorMainTheme:#b0c4ff;
$colorBorderHover:#d2ddff;
$colorDarkThemeText:#adb5c8;
$colorBlue:#e1e7ee;
$colorBlueBack:#f5f7fa;
$colorBlueBack2:#f7f8fb;
$colorBlueActive:#f3f7ff;
$colorAlert:#f25130;
$colorAlertLight:#fb927d;
$colorAlertBack:#fff0ee;
$colorSuccess:#5cc962;
$colorMedium:#ffb400;
$colorError:#f25130;
$colorTTK:#00b6ff;
$colorSK:#ffb400;
$orderStatuses:(wait-pay:#c2c2c2, new:#f29138, start-work:#f2ba38, choice-crew:#98d400, in-proccess:#66ca49, wait-close:#5cc23f, complete:#25c352, cancel:#c2c2c2);
$colorsCarServices:(_tail:#25C352, _strapping:#F25130, _cart:#F29438, _thermal:#F2BA38, _bracing:#3E93ED, _koniki:#583EED, _ttk:#393C43, _bodywork:#3E6AED, _sk:#98D400, _brand:#c2c2c2);
$widthInner:1064px;
$heightTopBar:48px;
$heightMenu:70px;
$heightMenuMobile:72px;
$heightField:50px;
$heightFieldMobile:56px;
$heightFieldMedium:44px;
$heightFieldProfile:34px;
$heightCabinetHead:58px;
$trans:.3s ease-in-out;
$transLong:.5s ease-in-out;
$radiusDefault:14px;
$widthCalendar:320px;
$widthFilter:320px;
$paddingMobile:20px;
$sizeLoader:24px;
$sizeLoaderMob:28px;
$sizeLoaderMedium:22px;
$widthPublicInner:135rem;
$widthPublicWideInner: 152rem;

// * {  
//     cursor: pointer !important;
// }