.newYearBorder {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 14px;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 1.5px solid #ff4c5d;
        border-radius: inherit;
        content: "";
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 3px solid #fff;
        border-radius: inherit;
        content: "";
    }

    &__inner {
        position: relative;
        z-index: 2;
        display: block;
        width: 100%;
        height: 100%;
    }

    &__rect {
        width: calc(100% - 1.5px);
        height: calc(100% - 1.5px);
        animation: newYearBorder 3s infinite ease-in-out;
        stroke: #52ac39;
        stroke-width: 1.5;
        stroke-dasharray: 20 20;

        @keyframes newYearBorder {
            0% {
                stroke-dashoffset: 0%;
            }

            50% {
                stroke-dashoffset: 100%;
            }

            100% {
                stroke-dashoffset: 0%;
            }
        }
    }
}
