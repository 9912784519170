.appOrderDocs {
    width: 100%;
    align-items: center;

    &__title {
        @extend %title;
        max-width: 328px;
        text-align: center;
        margin-bottom: 8px;
    }

    &__blocks {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__block {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: $trans;
        align-items: center;
        will-change: transform;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-64px, 0);
            }

            &._next {
                transform: translate(64px, 0);
            }
        }
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__description {
        @extend %text;
        max-width: 328px;
        text-align: center;
        margin-bottom: 14px;
    }

    &__files {
        @extend %noscroll;
        width: calc(100% + 40px);
        height: 154px;
        overflow: hidden;
        overflow-x: scroll;
        margin-bottom: 16px;
        position: relative;
        z-index: 1;

        &Inner {
            position: relative;
            padding: 0 20px;
            height: 100%;

            &._static {
                display: inline-flex;
            }
        }
    }

    &__filesInner._static &__file {
        position: relative;

        &:not(._show) {
            display: none;
        }

        &:not(._last) {
            margin-right: 12px;
        }
    }

    &__file {
        width: 154px;
        height: 154px;
        position: absolute;
        top: 0;
        left: 0;

        &Inner {
            width: 100%;
            height: 100%;
            transition: $trans;
            position: relative;
        }

        &:not(._show) &Inner {
            opacity: 0;
        }

        &Number {
            position: absolute;
            top: 12px;
            left: 12px;
            width: 24px;
            height: 24px;
            z-index: 2;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            background: $colorMain;
            border-radius: 50%;
            color: #fff;
            font-weight: 600;
        }

        &Upload {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            justify-content: center;
            align-items: center;

            &Border {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;

                & .borderDashed__rect {
                    stroke-dasharray: 8 6 !important;
                    rx: 20;
                    ry: 20;
                }
            }

            &Content {
                align-items: center;
                transition: $trans;
            }

            &._loading &Content {
                opacity: 0;
            }

            &Icon {
                width: 22px;
                height: 22px;
                display: block;
                margin-bottom: 4px;

                @include theme('light') {
                    @include icon($colorMain);
                }

                @include theme('dark') {
                    @include icon(#AEC2FF);
                }
            }

            &Description {
                max-width: 100%;
                text-align: center;
                font-size: 16px;
                line-height: 23px;
                font-weight: 600;

                @include theme('light') {
                    color: $colorMain;
                }

                @include theme('dark') {
                    color: #AEC2FF;
                }
            }
        }

        &Preview {
            width: 100%;
            height: 100%;
            border-radius: 20px;
            border: 1px solid transparent;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @include theme('light') {
                background: #f4f4f4;
                border-color: $colorMain;
            }

            @include theme('dark') {
                background: #25292C;
                border-color: #AEC2FF;
            }

            &Image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &Done {
                position: absolute;
                top: 12px;
                right: 12px;
                width: 24px;
                height: 24px;
                z-index: 2;
            }
        }
    }

    &__field {
        width: 100%;
        height: 56px;
        margin-bottom: 8px;

        & .field {
            border: 1px solid rgba(#B5BDC5, .32);
        }
    }

    &__error {
        width: 100%;
        position: relative;
        transition: $trans;

        &:not(._empty) {
            margin-bottom: 6px;
        }

        &Item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 14px;
            line-height: 1.3;
            font-weight: 600;
            color: $colorAlert;
            padding: 2px 0 6px 0;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
                transform: translate(0, -3px);

                &._hide {
                    transform: translate(0, 3px);
                }
            }
        }

        &._static &Item {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }
    }

    &__button {
        width: 100%;
        height: 56px;
    }
}