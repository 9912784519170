.v2popupUpload {
    width: 100%;

    &__fields {
        justify-content: space-between;
        width: 100%;
        height: 34px;
        margin-bottom: 20px;
    }

    &__field {
        width: calc(50% - 5px);
        height: 100%;
    }

    &__template {
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 4px 12px 5px;
        overflow: hidden;
        color: $colorMain;
        font-size: 15px;
        background: #eff4ff;
        border-radius: 9px;
        transition: $trans;

        @include button(0.97);

        &Input {
            display: none;
        }

        &Icon {
            width: 15px;
            height: 15px;
            margin-left: 6px;

            @include icon($colorMain);

            &._info {
                @include icon(#c4d3ff);
            }
        }
    }

    &__file {
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 160px;
        transition: $trans;

        @include button(0.99);

        &Back {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
        }

        &Input {
            display: none;
        }

        &Icon {
            position: relative;
            width: 22px;
            height: 22px;
            margin-bottom: 8px;

            @include icon($colorMain);

            &Item {
                width: 100%;
                height: 100%;
                transition: $trans;

                &._loading {
                    opacity: 0;
                }
            }
        }

        &Title {
            max-width: 100%;
            margin-bottom: 6px;
            color: $colorMain;
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
        }

        &Description {
            max-width: 100%;
            color: $colorMain;
            font-weight: 300;
            font-size: 15px;
            text-align: center;
        }
    }
}
