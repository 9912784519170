.v2popupGaleryFoot {
    width: 100%;
    padding: 16px;
    overflow: hidden;
    overflow-x: auto;
    border-top: 1px solid $colorBlue;

    &__files {
        display: inline-flex;
    }

    ._POPUPWRAPPER._pin &__file {
        width: 44px;
        height: 44px;
        border-radius: 8px;
    }

    &__file {
        position: relative;
        z-index: 1;
        width: 108px;
        height: 108px;
        overflow: hidden;
        background: $colorBlueBack;
        border: 1px solid transparent;
        border-radius: 16px;
        transition: $trans;

        @include button(0.97);

        &._current {
            border-color: $colorMain;
        }

        &:not(._current) {
            opacity: 0.5;
        }

        & + & {
            margin-left: 8px;
        }
    }
}
