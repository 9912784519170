.filterButton {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 10px;

    @include button(0.95);

    &._grey {
        background: $colorBlueBack;
    }

    &__icon {
        width: 16px;
        height: 16px;
        margin: auto;
    }

    &__counterWrapper {
        position: absolute;
        top: -6px;
        right: -6px;
        z-index: 2;
        min-width: 18px;
        height: 18px;
    }

    &__counter {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 2px 4px;
        color: #fff;
        font-weight: 700;
        font-size: 10px;
        text-align: center;
        background: $colorMain;
        border: 2px solid #fff;
        border-radius: 360px;
    }
}
