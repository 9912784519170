.windowActions {
    @extend %shadow;
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    z-index: 1;

    &__item {
        align-items: center;
        padding: 10px 14px 10px 10px;
        transition: $trans;
        position: relative;

        &:not(._alert):hover {
            background: $colorDark2;
        }

        &:not(._alert):hover &Icon {
            @include icon(#fff);
        }

        &:not(._alert):hover &Name {
            color: #fff;
        }

        &._alert:hover {
            background: $colorAlert;
        }

        &._alert:hover &Icon {
            @include icon(#fff);
        }

        &._alert:hover &Name {
            color: #fff;
        }

        &+& {
            border-top: 1px solid #eee;
        }

        &Inner {
            width: 100%;
            align-items: center;
            transition: $trans;
            @include button(.98);
        }

        &Icon {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            // @include icon($colorGreyDark);
        }

        &Name {
            color: $colorDark2;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            transition: $trans;
        }

        &._alert &Name {
            color: $colorAlert;
        }

        &Loader {
            background: #fff;
            
            &Item {
                width: $sizeLoaderMedium;
                height: $sizeLoaderMedium;
            }
        }
    }
}