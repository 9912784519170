.newYearStatus {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 99;
    display: flex;
    width: 56px;
    height: 56px;
    overflow: hidden;
    background: linear-gradient(to bottom, #24489b, #091947);
    border: 2px solid #d8ff00;
    border-radius: 50%;

    &._completed {
        background: linear-gradient(to bottom, #00c7ff, #3a0047);
    }

    &._shake {
        animation: shake 1.2s infinite linear;
    }

    @include button(0.96);

    &._top {
        top: 76px;
        right: 4px;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        content: "";

        @include bg("/img/crm/newYear/status-back.svg", cover, center);
    }

    &__text {
        margin: auto;
        padding-top: 1px;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        font-family: Gilroy;
        font-style: italic;
        line-height: 1.1;
        text-align: center;

        &._min {
            font-size: 12px;
        }

        & span {
            font-weight: 600;
            font-size: 9.5px;
            font-family: Gilroy;
            font-style: italic;
        }
    }

    &__timer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        font-family: Gilroy;
        font-style: italic;
        background: #000;
    }
}
