.v2infoBlock {
    width: 100%;
    height: 100%;
    padding: 32px;

    @include mob {
        padding: 20px 0;
    }

    &._public {
        padding: 4rem;
    }

    &._white {
        background: rgba(#fff, 0.8);
        border-radius: 32px;

        @include mob {
            padding: 20px;
        }
    }

    &__inner {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__icon {
        width: 36px;
        height: 36px;
        margin-bottom: 12px;
        object-fit: contain;
    }

    &._public &__icon {
        width: 4rem;
        height: 4rem;
        margin-bottom: 1.5rem;
    }

    &__title {
        margin-bottom: 6px;
        color: #b5bec9;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.3;
        text-align: center;

        @include mob {
            font-size: 18px;
        }
    }

    &._public &__title {
        margin-bottom: 0.8rem;
        font-size: 2.4rem;

        @include mob {
            font-size: 2.7rem;
        }
    }

    &__description {
        color: #b5bec9;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        text-align: center;

        @include mob {
            font-size: 16px;
        }
    }

    &._public &__description {
        font-size: 1.8rem;

        @include mob {
            font-size: 2.1rem;
        }
    }

    &__button {
        margin-top: 12px;
    }

    &._public &__button {
        margin-top: 1.5rem;
    }
}
