.v2button {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 15px 16px 16px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 12px;

    &._public {
        height: 5.6rem;
        padding: 1.8rem 3rem;
        font-size: 1.7rem;
        border-radius: 1.4rem;

        @include mob {
            height: 8rem;
            font-size: 2.3rem;
            border-radius: 2rem;
        }
    }

    &._mediumSize {
        padding: 8px 13px 9px;
        font-size: 14px;
        border-radius: 10px;
    }

    &._minSize {
        padding: 7px 12px 8px;
        font-size: 13px;
        border-radius: 8px;
    }

    &._bigSize {
        padding: 15px 16px 16px;
        font-size: 16px;
        border-radius: 12px;
    }

    @include button(0.97);

    &._disabled {
        color: $colorGreyDark;
        font-weight: 600;
        background: $colorBlueBack;

        @include icon($colorGreyDark);

        &._whiteDisabled {
            background: #fff;
        }

        &._opacityDisabled {
            opacity: 0.5;
        }
    }

    &:not(._disabled)._main {
        color: #fff;
        background: $colorMain;

        &._shadow {
            box-shadow: 0 2px 4px rgba($colorMain, 0.64);

            &._public {
                box-shadow: 0 0.4rem 0.6rem rgba($colorMain, 0.64);
            }
        }

        @include icon(#fff);
    }

    &:not(._disabled)._white {
        color: $colorDark2;
        font-weight: 500;
        background: #fff;
    }

    &:not(._disabled)._whiteOpacity {
        color: #fff;
        background: rgba(#fff, 0.16);

        @include icon(#fff);
    }

    &._opacityDisabled._main {
        color: #fff;
        background: $colorMain;

        &._shadow {
            box-shadow: 0 2px 4px rgba($colorMain, 0.64);
        }
    }

    &:not(._disabled)._alertEmpty {
        color: $colorAlert;
        border: 1px solid $colorAlert;

        @include icon($colorAlert);
    }

    &._blue:not(._disabled) {
        color: $colorMain;
        background: #f3f6ff;

        @include icon($colorMain);
        @include loader($colorMain);
    }

    &._grey:not(._disabled) {
        color: $colorDark2;
        background: $colorBlueBack;

        @include icon($colorMain);
        @include loader($colorMain);
    }

    &._edit {
        padding: 6px 10px 8px;
        font-weight: 500;
        font-size: 14.5px;
        border-radius: 9px;

        &._blue {
            color: $colorMain;
            background: #f3f6ff;

            @include icon($colorMain);
            @include loader($colorMain);
        }

        &._alert {
            color: $colorAlert;
            background: $colorAlertBack;

            @include icon($colorAlert);
            @include loader($colorAlert);
        }
    }

    &__loader {
        background: inherit;
        border-radius: inherit;
    }

    &._edit ._LOADERITEM {
        width: 16px;
        height: 16px;
    }

    &._minSize ._LOADERITEM {
        width: 16px;
        height: 16px;
    }

    & i {
        width: 16px;
        height: 16px;

        &._editPrev {
            width: 14px;
            height: 14px;
            margin-right: 4px;
        }

        &._limitPrev {
            width: 14px;
            height: 14px;
            margin-right: 6px;
        }

        &._arrowPrev {
            width: 16px;
            height: 16px;
            margin-right: 7px;
            transform: translate(0, 1px);
        }

        &._arrowNext {
            width: 16px;
            height: 16px;
            margin-left: 7px;
            transform: translate(0, 1px);
        }
    }

    &._public i._arrowNext {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0.1rem 0 0 0.5rem;
        transform: translate(0, 0.1rem);

        @include mob {
            width: 3.5rem;
            height: 3.5rem;
            margin: 0.2rem 0 0 1.2rem;
        }
    }

    &._public i._publicPresentation {
        width: 1.9rem;
        height: 1.9rem;
        margin: 0.1rem 0 0 1rem;
        transform: translate(0, 0.1rem);

        @include mob {
            width: 2.7rem;
            height: 2.7rem;
            margin: 0.1rem 0 0 1.3rem;
            transform: translate(0, 0);
        }
    }

    &._edit i._arrowNext {
        width: 18px;
        height: 18px;
        margin-left: 3px;
        transform: translate(0, 2px);
    }
}
