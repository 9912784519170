.link {
    font-size: 16px;
    line-height: 21px;
    position: relative;
    transition: $trans;

    @include theme('light') {
        color: $colorMain;
    }

    @include theme('dark') {
        color: #AEC2FF;
    }

    &._alert {
        @include theme('light') {
            color: $colorAlert;
        }

        @include theme('dark') {
            color: $colorAlert;
        }
    }

    &._bold {
        font-weight: 600;
    }

    &__loader {
        height: auto;
        top: 50%;
        transform: translate(0, -50%);

        &Item {
            width: 20px;
            height: 20px;
        }
    }

    &._right &__loader {
        width: 20px;
        left: 100%;
        margin-left: 6px;
    }

    &__inner {
        transition: $trans;
        line-height: 21px;
    }

    &._isLoading &__inner {
        opacity: .4;
    }
}