.v2checkbox {
    display: block;

    @include button(0.95);

    &__input {
        display: none;
    }

    &__inner {
        position: relative;
        min-height: 16px;
        padding-left: 16px;
    }

    &__box {
        position: absolute;
        top: 1px;
        left: 0;
        width: 16px;
        height: 16px;
        border: 1px solid $colorGreyDark;
        border-radius: 5px;
        transition: $trans;

        &Icon {
            width: 13px;
            height: 13px;
            margin: auto;
            opacity: 0;
            transition: $trans;

            @include icon(#fff);
        }
    }

    &__input:checked ~ &__inner &__box {
        background: $colorMain;
        border-color: $colorMain;

        &Icon {
            opacity: 1;
        }
    }

    &._green &__input:checked ~ &__inner &__box {
        background: $colorSuccess;
        border-color: $colorSuccess;

        &Icon {
            opacity: 1;
        }
    }

    &__text {
        padding-left: 7px;
        color: $colorDark2;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.3;

        & b {
            font-weight: 600;
        }
    }

    &._lightWeight &__text {
        font-weight: 300;
    }

    &__info {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-left: 4px;
        transform: translate(0, 3px);

        @include icon(rgba($colorDark2, 0.2));
    }
}
