.cookie {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 22px;
    border-radius: 18px;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.16);

    &__icon {
        width: 34px;
        height: 34px;
    }

    &__content {
        font-size: 11px;
        line-height: 1.5;
        color: $colorDark2;
        width: calc(100% - 34px - 100px);
        padding: 0 12px 0 12px;

        & a {
            border-bottom: 1px solid rgba($colorMain, .5);
            color: $colorMain;
        }
    }

    &__button {
        width: 100px;
    }
}