.arrowBtn {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid transparent;
    border-radius: 12px;
    transition: $trans;
    @include button(.95);
    box-shadow: 0 0 2px 0 rgba(216, 220, 228, 0.72);

    @include forType('crm') {
        border-radius: 8px;
        box-shadow: none;
    }

    @include theme('light') {
        background: #fff;
        border-color: #fff;
    }

    @include theme('dark') {
        border-color: $colorDarkTheme3;
        background: $colorDarkTheme3;
    }

    @include forType('crm', 'light') {
        background: $colorBlueBack;
        border-color: $colorBlueBack;
    }

    @include mediaMin($mediaM + 1px) {
        &:hover {
            @include theme('light') {
                @include icon($colorMain);
            }

            @include theme('dark') {
                @include icon($colorBorderHover);
            }
        }
    }

    @include theme('light') {
        @include icon($colorDark);
    }

    @include theme('dark') {
        @include icon($colorLight);
    }

    @include forType('crm', 'light') {
        @include icon($colorGreyDark);
    }

    &[data-disabled] {
        opacity: 0.5;
        pointer-events: none;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        transform: translate(-50%, -50%);
        transition: $trans;
        will-change: opacity;
    }

    &._short &__icon {
        width: 12px;
        height: 12px;
    }

    &._short._prev &__icon {
        margin-left: -1px;
    }

    &._short._next &__icon {
        margin-left: 1px;
    }
}