._ACTION_CHANGE_WRAPPER {
    position: absolute;
    width: 28px;
    height: 28px;
}

.v2actionChange {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 3px 0 rgb(173 183 197 / 56%);

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        background: $colorMain;
        transform: translate(-50%, -50%);
        transition: $trans;
        content: "";
    }

    &::before {
        width: 10px;
        height: 1px;
    }

    &::after {
        width: 1px;
        height: 10px;
    }

    &._active::before {
        background: $colorAlert;
    }

    &._active::after {
        opacity: 0;
    }
}
