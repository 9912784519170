.startPopup {
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 60px 20px;

    @include theme("light") {
        background: #fff;
    }

    @include theme("dark") {
        background: #1c1f21;
    }

    &__content {
        align-items: center;
        width: 100%;
        margin: auto 0;
    }

    &__logo {
        position: relative;
        z-index: 1;
        width: 64px;
        height: 64px;
        margin-bottom: 24px;
        padding: 8px;
        object-fit: contain;
        overflow: hidden;
        border-radius: 16px;
        box-shadow: 0 0 4px rgba(#000, 0.12);
    }

    &__title {
        max-width: 100%;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 32px;
        text-align: center;
        text-transform: uppercase;

        @include theme("light") {
            color: $colorDark2;
        }

        @include theme("dark") {
            color: #fff;
        }
    }

    &__description {
        max-width: 100%;
        font-size: 14px;
        line-height: 1.5;
        text-align: center;

        @include theme("light") {
            color: $colorDark2;
        }

        @include theme("dark") {
            color: #fff;
        }
    }

    &__foot {
        color: #b5bdc5;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.3;
    }
}
