.filePreview {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &._png &__image,
    &._svg &__image {
        width: 80%;
        height: 80%;
        object-fit: contain;
    }

    &__icon {
        width: 40%;
        height: 40%;
        @include icon($colorBorderHover);
    }
}