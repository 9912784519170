@font-face {
    font-weight: 1000;
    font-family: 'PTRoot';
    font-style: normal;
    src: local('PTRoot'), url('../../fonts/PT-Root-UI/Ptrootuiblack.otf');
}

html {
    overflow: hidden;
    overscroll-behavior: none;
    &[data-theme="light"] {
        background: #fff;
    }
    &[data-theme="dark"] {
        background: #2B2F33;
    }
    &[data-page="index"] {
        &[data-theme="light"] {
            background: #f5f7fa;
        }
        &[data-theme="dark"] {
            background: #1C1F21;
        }
    }
    &[data-page="corporations"],
    &[data-page="start"],
    &[data-page="auth"] {
        &[data-theme="dark"] {
            background: #1C1F21;
        }
    }
}

%title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    transition: $trans;
    @include theme('light') {
        color: $colorDark2;
    }
    @include theme('dark') {
        color: #fff;
    }
    &._mediumSize {
        font-size: 28px;
    }
}

%text {
    font-size: 16px;
    line-height: 23px;
    transition: $trans;
    @include theme('light') {
        color: $colorDark2;
    }
    @include theme('dark') {
        color: #B5BDC5;
    }
    & b {
        font-weight: 600;
    }
    & span {
        font-weight: 600;
        color: #B5BDC5;
    }
}

%page {
    @extend %noscroll;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: $padding $padding 30px $padding;
    transition: $trans;
    @include theme('light') {
        background: $colorBlueBack;
    }
    @include theme('dark') {
        background: #1C1F21;
    }
}