@media print {
    @page {
        padding: 0;
        margin: 0;
    }

    .body__content {
        min-height: auto !important;
    }

    .body__page {
        position: relative !important;
        min-height: auto !important;
    }
}

@media print {

    html,
    body {
        margin: 0 !important;
        padding: 0 !important;
        -webkit-text-size-adjust: auto;

        & * {
            -webkit-font-smoothing: auto;
        }
    }
}

.pdf {
    width: 210mm;
    position: relative;
    display: block;
    z-index: 1;
    background: rgba(red, .05);
    padding: 0 20mm;

    @media print {
        background: none;
    }

    &._landscape {
        width: 297mm;
    }

    &._ordersReport {
        padding: 0 4.5mm;
        // height: 420mm;
        // background: rgba(red, .2);
    }

    &._fnsReceipt {
        width: 80mm;
        padding: 6mm;
    }

    &__inner {
        width: 100%;
        position: relative;

        &Box {
            width: 100%;
        }
    }

    &__page {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        page-break-inside: avoid;
        page-break-after: avoid;
        page-break-before: avoid;

        &:not(._last) {
            // page-break-after: always;
        }
    }

    &__elem {
        width: 100%;

        &._lastText {
            padding-bottom: 12px;
        }
    }

    &__corpAct {
        &Title {
            width: 100%;
            text-align: center;
            font-size: 17px;
            font-weight: 700;
            line-height: 1.2;
            color: $colorDark2;
            padding-bottom: 2px;
        }

        &Head {
            width: 100%;
            justify-content: space-between;
            padding-bottom: 20px;

            &Item {
                font-weight: 700;
                color: $colorDark2;
                font-size: 15px;
                line-height: 1.2;
            }
        }

        &Inner {
            width: 100%;
            padding-left: 40px;
            position: relative;

            &._top {
                padding-top: 12px;
            }

            &._last {
                padding-bottom: 12px;
            }

            &Box {
                width: 100%;
                position: relative;
            }

            &Counter {
                position: absolute;
                top: 0;
                right: 100%;
                margin-right: 4px;
                font-size: 14px;
                line-height: 1.4;
                font-weight: 500;
                color: $colorDark2;
            }
        }

        &Description {
            max-width: 100%;
            font-size: 14px;
            line-height: 1.4;
            font-weight: 500;
            color: $colorDark2;
            padding-bottom: 12px;
        }
    }

    &__elem+&__elem &__tableRow {
        margin-top: -1px;
    }

    &__table {
        &Row {
            width: 100%;
            border: 1px solid rgba($colorDark2, .2);
            align-items: stretch;
        }

        &Col {
            padding: 6px;
            font-size: 13px;
            line-height: 1.3;
            color: $colorDark2;

            &+& {
                border-left: 1px solid rgba($colorDark2, .2);
            }
        }

        &Row._head &Col {
            font-weight: 700;
            text-align: center;

            &._left {
                text-align: left;
            }
        }

        &._nonResidentPayAct &Col {
            &._number {
                width: 5%;
            }

            &._name {
                width: 40%;
            }

            &._hours {
                width: 20%;
            }

            &._amount {
                width: 35%;
            }

            &._cond {
                width: 65%;
            }

            &._koef {
                width: 35%;
            }
        }

        &._nonResidentPayAct._3 &Col {
            &._number {
                width: 5%;
            }

            &._name {
                width: 75%;
            }

            &._amount {
                width: 20%;
            }
        }
    }

    &._corporationReport &__tableRow._info &__tableCol {
        font-weight: 700;

        &._info {
            width: 75%;
        }

        &._sum {
            width: 25%;
            text-align: center;
        }
    }

    &._corporationReport &__tableRow._details &__tableCol {
        &._name {
            width: 45%;
        }

        &._sum {
            width: 18%;
            text-align: center;
        }

        &._date {
            width: 15%;
            text-align: center;
        }

        &._deltaSum {
            width: 22%;
            text-align: center;
        }
    }

    &._joinsReport &__tableRow &__tableCol {
        &._number {
            width: 6%;
            text-align: center;
        }

        &._name {
            width: 24.5%;
            width: 47.5%;
        }

        &._phone {
            width: 16.5%;
        }

        &._ip {
            width: 16%;
        }

        // &._agent {
        //     width: 23%;
        // }
        &._date {
            width: 14%;
        }
    }

    &._joinsReport &__tableRow:not(._head) &__tableCol._agent {
        font-size: 10px;
        font-weight: 300;
    }

    &__elem._endPage &__ordersReportTableRow {
        border-radius: 0 0 10px 10px;
        border-bottom: 1px solid rgba(#e7ecf1, .64);
    }

    &__page &__elem:last-child &__ordersReportTableRow {
        border-radius: 0 0 10px 10px;
        border-bottom: 1px solid rgba(#e7ecf1, .64);
    }

    &__ordersReport {
        &Head {
            width: 100%;
            align-items: center;
            padding-bottom: 8px;

            &Title {
                font-size: 13.5px;
                font-weight: 700;
                color: $colorDark2;
            }

            &Counter {
                padding-top: 20px;
                margin-left: auto;
                font-size: 9px;
                font-weight: 600;
                text-transform: uppercase;
                color: #75808b;
            }

            &Description {
                max-width: 100%;
                font-size: 9px;
                line-height: 1.3;
                color: $colorDark2;

                & b {
                    font-weight: 700;
                }
            }
        }

        &Table {
            width: 100%;

            &Wrapper {
                width: 100%;

                &._top {
                    padding-top: 10px;
                }
            }

            &Row {
                width: 100%;
                align-items: stretch;

                &._head {
                    background: #eaeff6;
                    border-radius: 10px 10px 0 0;
                }

                &:not(._head) {
                    background: #fbfcfd;
                    border-left: 1px solid rgba(#e7ecf1, .64);
                    border-right: 1px solid rgba(#e7ecf1, .64);

                    // height: 120px;
                    &._odd {
                        background: #f5f7fa;
                    }

                    &._last {
                        border-radius: 0 0 10px 10px;
                        border-bottom: 1px solid rgba(#e7ecf1, .64);
                    }
                }
            }

            &Col {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-size: 6.2px;
                line-height: 1.3;
                padding: 6px 6px;
                color: $colorDark2;
                justify-content: center;

                &._address {
                    justify-content: flex-start;
                    padding: 0;
                }

                &._double {
                    padding: 0;
                }

                &+& {
                    border-left: 1px solid rgba(#e7ecf1, .64);
                }

                &Inner {
                    font-size: 6.2px;
                    line-height: 1.3;
                    color: $colorDark2;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &._double>&Inner {
                    min-height: 100%;
                }

                &._clientAndExecutor &Block {
                    min-height: auto;
                }

                &Block {
                    width: 100%;
                    min-height: 50%;
                    padding: 6px;
                    font-size: 6.2px;
                    line-height: 1.3;
                    color: $colorDark2;
                    justify-content: center;

                    &+& {
                        border-top: 1px solid rgba(#e7ecf1, .64);
                    }
                }

                &Address {
                    width: 100%;
                    padding: 6px 6px 6px 18px;
                    font-size: 6.2px;
                    line-height: 1.3;
                    color: $colorDark2;
                    position: relative;

                    &Number {
                        position: absolute;
                        top: 6px;
                        left: 6px;
                        width: 8px;
                        height: 8px;
                        padding-top: 0.5px;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        font-size: 5px;
                        font-weight: 900;
                        color: $colorDark2;
                        background: #eaeff6;
                    }

                    &+& {
                        border-top: 1px solid rgba(#e7ecf1, .64);
                    }
                }
            }

            &Row._head &Col {
                font-weight: 600;
                padding-top: 3px;
                padding-bottom: 3px;
                white-space: nowrap;
            }

            &Col {
                &._number {
                    width: 2%;
                    text-align: center;
                    align-items: center;
                }

                &._tariff {
                    width: 5%;
                }

                &._orderNumber {
                    width: 2.5%;
                }

                &._startDate {
                    width: 4.5%;
                }

                &._startTime {
                    width: 3%;
                }

                &._endDate {
                    width: 4.5%;
                }

                &._endTime {
                    width: 3%;
                }

                &._duration {
                    width: 3.5%;
                }

                &._minPrice {
                    width: 4%;
                }

                &._extraPrice {
                    width: 3.5%;
                }

                &._servicesPrice {
                    width: 4%;
                }

                &._mkad {
                    width: 3.5%;
                }

                &._mkadPrice {
                    width: 3%;
                }

                &._changedPrice {
                    width: 4%;
                }

                &._totalPrice {
                    width: 4%;
                }

                &._totalPrices {
                    width: 4%;
                }

                &._address-1,
                &._address-2,
                &._address-3 {
                    width: calc(30% / 3);
                }
            }

            &._all &Col {
                &._clientAndExecutor {
                    width: 12%;
                }
            }

            &._clients &Col,
            &._executors &Col,
            &._services &Col {
                &._fullName {
                    width: 6.5%;
                }

                &._car {
                    width: 5%;
                }

                &._carNumber {
                    width: 4.5%;
                }
            }

            &._services &Col {

                // 25.5%
                &._serviceName {
                    width: 6%;
                }

                &._commission {
                    width: 7%;
                }

                &._serviceAmount {
                    width: 6%;
                }

                &._savedAmount {
                    width: 7%;
                }
            }
        }

        &Foot {
            width: 100%;
            background: #fbfcfd;
            border: 1px solid rgba(#e7ecf1, .64);
            border-radius: 12px;
            padding: 14px;
            justify-content: space-between;

            &Wrapper {
                width: 100%;
                padding-top: 16px;
            }

            &Block {
                &._info {
                    width: 520px;
                }
            }

            &Amount {
                font-size: 9.5px;
                font-weight: 900;
                color: $colorDark2;
                margin-bottom: 2px;

                &Text {
                    font-size: 7.5px;
                    line-height: 1.3;
                    color: $colorDark2;
                    margin-bottom: 7px;
                }
            }

            &Info {
                font-size: 7px;
                line-height: 1.4;
                font-weight: 300;
                color: $colorDark2;
            }

            &Corporation {
                font-size: 8px;
                font-weight: 600;
                color: $colorDark2;
                margin-bottom: 12px;

                & b {
                    font-weight: 900;
                }
            }

            &Sign {
                font-size: 8px;
                color: $colorDark2;
            }
        }
    }

    &__joinsReport {
        &Title {
            width: 100%;
            text-align: center;
            font-size: 17px;
            font-weight: 700;
            line-height: 1.2;
            color: $colorDark2;
            padding-bottom: 20px;
        }

        &Date {
            max-width: 100%;
            font-size: 15px;
            font-weight: 600;
            color: $colorDark2;
            padding-bottom: 24px;
        }

        &SubTitle {
            max-width: 100%;
            text-align: center;
            font-size: 15px;
            font-weight: 700;
            color: $colorDark2;
            line-height: 1.2;
            text-transform: uppercase;
            position: relative;
            margin: 0 auto;
            padding-bottom: 18px;

            & ._counter {
                position: absolute;
                top: 0;
                right: 100%;
                margin-right: 28px;
                font-size: 16px;
                font-weight: 700;
                color: $colorDark2;
                line-height: 1.2;
            }
        }

        &Text {
            max-width: 100%;
            font-size: 14px;
            line-height: 1.4;
            font-weight: 500;
            color: $colorDark2;
            padding-left: 32px;
            padding-bottom: 12px;
            position: relative;
            text-align: justify;

            &._default {
                text-align: left;
                padding-left: 0;

                &::before {
                    display: none;
                }
            }

            &._notPadding {
                padding-bottom: 0;
            }

            &._bold {
                font-weight: 600;
            }

            &._2 {
                padding-left: 78px;
            }

            & ._counter {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 14px;
                line-height: 1.4;
                font-weight: 500;
                color: $colorDark2;
            }

            &._2 ._counter {
                left: 32px;
            }

            &._bold ._counter {
                border-width: 600;
            }

            & b {
                font-weight: 600;
            }
        }
    }

    & ._gap {
        padding: 0 !important;
        margin: 0 !important;
    }

    &__pagenation {
        position: absolute;
        right: 0;
        width: 100%;
        z-index: 2;
        pointer-events: none;

        &Box {
            width: 100%;
            margin-top: auto;
        }

        &._top &Box {
            margin-top: 0;
        }

        &Inner {
            width: 100%;
            height: 50px;
            justify-content: flex-end;
            align-items: center;
        }

        &Content {
            max-width: calc(100% - 50px);
            padding-right: 12px;
            align-items: flex-end;
        }

        &Description {
            color: #0F7DD6;
            font-size: 10px;
            font-weight: 300;
            line-height: 1.2;
            text-align: right;

            &+& {
                margin-top: 3px;
            }

            &._bold {
                font-weight: 600;
            }
        }

        &Logo {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            // position: absolute;
            // top: 50%;
            // right: 0;
            // transform: translate(0, -50%);
            border: 1px solid #0F7DD6;

            &Icon {
                width: 30px;
                height: 30px;
                margin: auto;
            }
        }
    }

    &__foot {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        page-break-after: avoid;
        page-break-inside: avoid;
        pointer-events: none;

        &Inner {
            width: 100%;
            margin-top: auto;
        }
    }

    &__sign {
        width: 100%;
        border: 1px solid #0F7DD6;
        border-radius: 10px;
        position: relative;

        &Number {
            position: absolute;
            top: 0;
            right: 24px;
            transform: translate(0, -50%);
            background: #fff;
            padding: 4px 6px;
            z-index: 2;
            font-size: 9.5px;
            font-weight: 500;
            color: #0F7DD6;
        }

        &Head {
            width: 100%;
            padding: 14px 28px;
            align-items: center;
            font-size: 15px;
            font-weight: 600;
            color: #0F7DD6;
            border-bottom: 1px solid #0F7DD6;

            &Logo {
                width: 28px;
                height: 28px;
                object-fit: contain;
                object-position: left center;
                margin-right: 10px;
            }
        }

        &Content {
            width: 100%;
            padding: 22px 28px;
            align-items: stretch;
        }

        &Block {
            max-width: 40%;
            border-left: 1px solid #0F7DD6;
            padding-left: 8px;

            &+& {
                margin-left: 32px;
            }

            &._rules {
                max-width: none;
                margin-left: auto;
            }

            &Content {
                font-size: 9.5px;
                line-height: 1.4;
                color: #0F7DD6;

                & b {
                    font-weight: 700;
                }
            }
        }
    }

    &__joinsTemplate {
        width: 100%;
        font-size: 16px;

        & ._break {
            line-height: 0;
            display: block;
        }

        &Logo {
            width: 240px;
            height: 60px;
            object-fit: contain;
            object-position: left top;
            margin-bottom: 16px;
        }

        &Inner {
            @extend %editorContent;
            width: 100%;
            line-height: 1.3;
            font-size: 16px;

            & * {
                line-height: 1.3;
                font-size: 16px;
            }

            & h2,
            & h2 * {
                line-height: 1.3;
                font-size: 21px;
                font-weight: 600;
            }

            & h3,
            & h3 * {
                line-height: 1.3;
                font-size: 18px;
                font-weight: 600;
            }

            & b * {
                font-weight: 600;
            }
        }
    }
}