.orders {
    width: 100%;
    height: 100%;
    position: relative;

    &__pages {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-64px, 0);
            }

            &._next {
                transform: translate(64px, 0);
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
            position: relative;
        }

        &Box {
            @extend %noscroll;
            width: 100%;
            height: 100%;
            position: relative;
            overflow-y: auto;
            padding: calc(20px + #{$padding}) $padding 30px $padding;
            transition: $trans;
        }

        &Inner:not(._init) &Box {
            opacity: 0;
        }

        &Empty {
            @extend ._loader;
            z-index: 9;
        }

        &Previews {
            width: 100%;
            position: relative;
        }

        &Preview {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: $trans;

            &Inner {
                width: 100%;
                transition: $trans;
            }

            &:not(._show) &Inner {
                opacity: 0;
            }
        }

        ._parentCalc &Preview,
        &Previews._static &Preview {
            position: relative;

            &:not(._show) {
                display: none;
            }

            &:not(._last) {
                margin-bottom: 10px;
            }
        }
    }

    &__pages._static &__page {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }
}