.v2counter {
    z-index: 3;
    width: auto;
    min-width: 16px;
    height: 16px;
    border-radius: 6px;
    transition: $trans;

    &._bigSize {
        min-width: 18px;
        height: 18px;
    }

    &:not(._empty) {
        background: $colorAlert;
    }

    &__item {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 3px 1px;
        color: #fff;
        font-weight: 700;
        font-size: 11px;
        text-align: center;
    }

    &._bigSize &__item {
        font-size: 12px;
    }
}
