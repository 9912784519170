.date {
    @extend %shadow;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    position: relative;
    z-index: 1;

    @include theme('dark') {
        background: $colorDarkThemeBorder;
    }

    @include theme('light') {
        background: #fff;
    }

    @include media(0, $mediaM) {
        border-radius: 0;
        overflow: hidden;

        @include theme('dark') {
            background: none;
            box-shadow: none;
        }

        @include theme('light') {
            background: none;
            box-shadow: none;
        }
    }

    &::before {
        content: "";
        position: absolute;
        width: 36px;
        height: 36px;
        z-index: -1;

        @include media(0, $mediaM) {
            display: none;
        }

        @include theme('dark') {
            background: $colorDarkThemeBorder;
        }

        @include theme('light') {
            background: #fff;
        }
    }

    &::after {
        content: "";
        @extend %shadow;
        position: absolute;
        width: 16px;
        height: 16px;
        z-index: -2;

        @include media(0, $mediaM) {
            display: none;
        }

        @include theme('dark') {
            background: $colorDarkThemeBorder;
        }

        @include theme('light') {
            background: #fff;
        }
    }

    &._notCursor::after,
    &._notCursor::before {
        display: none;
    }

    &._centerRight::after,
    &._centerRight::before {
        right: 0;
        top: 50%;
    }

    &._centerRight::after {
        transform: translate(50%, -50%) rotate(45deg);
    }

    &._centerRight::before {
        transform: translate(0, -50%);
    }

    &._bottomLeft::after,
    &._bottomLeft::before {
        left: 0;
        bottom: 32px;
    }

    &._bottomRight::after,
    &._bottomRight::before {
        right: 0;
        bottom: 32px;
    }

    &._bottomLeft::after {
        transform: translate(-50%, 0) rotate(45deg);
    }

    &._bottomLeft::before {
        bottom: 24px;
    }

    &._bottomRight::after {
        transform: translate(50%, 0) rotate(45deg);
    }

    &._bottomRight::before {
        bottom: 24px;
    }

    &._topCenter::after,
    &._topCenter::before {
        left: 50%;
        top: 0;
    }

    &._topCenter::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &._topCenter::before {
        transform: translate(-50%, 0);
    }

    &__blocks {
        width: 100%;
        position: relative;
        overflow: hidden;
        padding: 24px 25px 0 25px;
        border-radius: 14px;
        margin-bottom: 10px;
        transition: $transLong;

        @include media(0, $mediaM) {
            padding: 20px;
            overflow: visible;
        }
    }

    &__block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 24px 25px 0 25px;
        transition: $transLong;

        @include media(0, $mediaM) {
            padding: 24px 28px 16px 28px;
            opacity: 0;
        }

        &._calendar {
            transform: translate(-100%, 0);
        }

        &._time {
            transform: translate(100%, 0);
        }

        &._show {
            transform: translate(0, 0);

            @include media(0, $mediaM) {
                opacity: 1;
            }
        }
    }

    &__calendar {
        width: 100%;
    }

    &__time {
        width: 100%;
        padding-bottom: 8px;

        &Head {
            align-items: flex-end;
            width: 100%;
            height: 20px;
            margin-bottom: 22px;
        }

        &Back {
            align-items: center;
            font-weight: 600;
            font-size: 19px;

            @include media(0, $mediaM) {
                font-size: 20px;
            }

            @include theme('dark') {
                color: #fff;
            }

            @include theme('light') {
                color: $colorDark;
            }

            &Icon {
                width: 20px;
                height: 20px;
                margin-right: 6px;

                @include media(0, $mediaM) {
                    width: 22px;
                    height: 22px;
                    margin-right: 8px;
                }

                @include theme('dark') {
                    @include icon(#fff);
                }

                @include theme('light') {
                    @include icon($colorDark);
                }
            }
        }

        &Date {
            font-size: 19px;
            font-weight: 600;
            margin-left: auto;

            @include media(0, $mediaM) {
                font-size: 20px;
            }

            @include theme('dark') {
                color: #fff;
            }

            @include theme('light') {
                color: $colorDark;
            }
        }

        &Content {
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }

        &Inputs {
            align-items: center;
        }

        &Input {
            width: 52px;
            height: 54px;
            border-radius: 8px;
            text-align: center;
            font-size: 26px;
            font-family: 'PTRoot';
            font-weight: 500;
            transition: $trans;

            @include media(0, $mediaM) {
                width: 64px;
                height: 64px;
                border-radius: 12px;
                font-size: 30px;
            }

            @include theme('light') {
                background: #f8f8f8;
                color: $colorDark2;
            }

            @include theme('dark') {
                background: $colorDarkTheme2;
                color: #fff;
            }

            @include forType('crm', 'light') {
                background: $colorBlueBack;
            }
        }

        &Delimeter {
            font-size: 24px;
            font-weight: 600;
            margin: -4px 4px 0 4px;

            @include theme('dark') {
                color: #fff;
            }

            @include theme('light') {
                color: $colorDark2;
            }
        }

        &Line {
            width: 14px;
            height: 2px;

            @include theme('dark') {
                background: #fff;
            }

            @include theme('light') {
                background: $colorDark2;
            }
        }
    }

    &__foot {
        width: 100%;
        padding: 0 25px 24px 25px;

        @include media(0, $mediaM) {
            padding: 0 16px 16px 16px;
        }
    }

    &__button {
        width: 100%;
        height: 58px;
        position: relative;
        border-radius: 12px;
        background: $colorMain;
        box-shadow: 0 1px 6px 2px rgba($colorMain, .4);
        transition: $trans;
        overflow: hidden;
        @include button();

        @include media(0, $mediaM) {
            height: 66px;
            border-radius: 16px;
        }

        &:hover {
            background: $colorMainDark;
        }

        &Content {
            position: absolute;
            top: 0;
            left: 0;
            transition: $transLong;
            width: 100%;
            height: 100%;
            padding-bottom: 2px;
            justify-content: center;
            align-items: center;
            will-change: transform;

            &._calendar {
                transform: translate(0, -100%);
            }

            &._time {
                transform: translate(0, 100%);
            }

            &._show {
                transform: translate(0, 0)
            }
        }

        &Title {
            max-width: 100%;
            font-size: 15px;
            font-weight: 600;
            text-align: center;
            color: #fff;
            margin-bottom: 5px;

            @include media(0, $mediaM) {
                font-size: 18px;
                margin-bottom: 7px;
            }
        }

        &Text {
            max-width: 100%;
            text-align: center;
            font-weight: 300;
            color: #fff;
            font-size: 13px;
            white-space: nowrap;

            @include media(0, $mediaM) {
                font-size: 16px;
            }
        }

        &Points {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 24px;
        }

        &Point {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #fff;
            opacity: 0.5;
            transition: $trans;

            @include media(0, $mediaM) {
                width: 8px;
                height: 8px;
            }

            &._current {
                opacity: 1;
            }

            &+& {
                margin-left: 4px;

                @include media(0, $mediaM) {
                    margin-left: 6px;
                }
            }
        }
    }
}