.orderDetails {
    width: 100%;
    transition: $trans;

    &__inner {
        width: 100%;
        transition: $trans;
    }

    &:not(._init) &__inner {
        opacity: 0;
    }

    &__head {
        width: 100%;
        position: relative;
        margin-bottom: 14px;
    }

    &__title {
        max-width: 100%;
        font-size: 28px;
        line-height: 1.3;
        font-weight: 900;
        margin: -2px 0 -1px 0;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &__date {
        max-width: 100%;
        font-size: 18px;
        line-height: 1.3;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #B5BDC5;
        }
    }

    &__status {
        position: absolute;
        top: 4px;
        right: 0;
    }

    &._cancel &__routePointInfo span {
        color: $colorAlert;
    }

    &._market &__routePointInfo span {
        color: #5BDCE5;
    }

    &._market &__routePointIcon._complete {
        background: #5BDCE5 !important;
    }

    &__corporation {
        max-width: 100%;
        padding: 8px 14px 18px 14px;
        position: relative;
        z-index: 0;
        border-radius: 14px 14px 0 0;
        margin-bottom: -10px;

        @include theme('light') {
            background: $colorBlueBack;
        }

        @include theme('dark') {
            background: #2B2F33;
        }

        &Logo {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: #1C1F21;
            }
        }

        &Name {
            max-width: calc(100% - 24px);
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;
            padding-left: 8px;
            padding-top: 1px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            & span {
                font-weight: 500;
            }
        }
    }

    &._market &__route {
        // margin-top: 64px;
    }

    &__route {
        width: 100%;
        position: relative;
        margin-bottom: 12px;
        z-index: 1;

        &Inner {
            width: 100%;
            border-radius: 16px;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @include theme('light') {
                background: #fff;
                box-shadow: 0 0 2px 0 rgba(216, 220, 228, 1);
            }

            @include theme('dark') {
                background: #2B2F33;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.56)
            }
        }

        &Map {
            width: 100%;
            height: 156px;
            position: relative;

            @include theme('light') {
                background: #f4f4f4;
            }

            @include theme('dark') {
                background: #1C1F21;
            }

            &Info {
                position: absolute;
                left: 8px;
                bottom: -1px;
                font-size: 12px;
                line-height: 1.3;
                font-weight: 600;
                border-radius: 12px 12px 0 0;
                padding: 6px 11px 1px 11px;
                z-index: 2;
                text-transform: uppercase;
                letter-spacing: 0.4px;
                will-change: transform;

                @include theme('light') {
                    background: #fff;
                    color: $colorDark2;
                }

                @include theme('dark') {
                    background: #2B2F33;
                    color: #fff;
                }
            }

            &Inner {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;
            }
        }

        &Points {
            width: 100%;
            padding: 0 16px 2px 16px;

            &Inner {
                width: 100%;
            }

            &Drop {
                width: 100%;
                position: relative;
                transition: $trans;
                z-index: 1;
            }
        }

        &PointsDrop &Point {
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 1px solid rgba(#646F7A, .24);

            &:not(._show) {
                opacity: 0;
            }
        }

        &PointsDrop._static &Point {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }

        &Point {
            width: 100%;
            padding: 12px 0 12px 30px;
            position: relative;
            z-index: 2;
            transition: $trans;

            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: #2B2F33;
            }

            &._firstPoint._static {
                border-bottom: 1px solid rgba(#646F7A, .24);
                padding-bottom: 13px;
            }

            &:not(._firstPoint) &Icon {
                flex-direction: column-reverse;

                &Home {
                    margin-bottom: 0;
                    margin-top: 3px;
                }
            }

            &:not(._firstPoint) &IconDot+&IconDot {
                margin-top: 0;
                margin-bottom: 2px;
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                width: 18px;
                height: 36px;
                border-radius: 360px;
                align-items: center;
                padding: 6px 2px;

                @include theme('light') {
                    background: #B5BDC5;
                }

                @include theme('dark') {
                    background: #3E454C;
                }

                &._complete {
                    @include theme('light') {
                        background: $colorSuccess;
                    }

                    @include theme('dark') {
                        background: $colorSuccess;
                    }
                }

                &Home {
                    width: 9px;
                    height: 9px;
                    margin-bottom: 3px;
                    transform: scale(1.1);

                    @include theme('light') {
                        @include icon(#fff);
                    }

                    @include theme('dark') {
                        @include icon(#B5BDC5);
                    }
                }

                &._complete &Home {
                    @include theme('light') {
                        @include icon(#fff);
                    }

                    @include theme('dark') {
                        @include icon(#fff);
                    }
                }

                &Dot {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;

                    @include theme('light') {
                        background: #fff;
                    }

                    @include theme('dark') {
                        background: #B5BDC5;
                    }

                    &:nth-last-of-type(3) {
                        width: 2px;
                        height: 2px;
                        opacity: 0.36;
                    }

                    &:nth-last-of-type(2) {
                        width: 2px;
                        height: 2px;
                        opacity: 0.72;
                    }

                    &+& {
                        margin-top: 2px;
                    }
                }

                &._complete &Dot {
                    @include theme('light') {
                        background: #fff;
                    }

                    @include theme('dark') {
                        background: #fff;
                    }
                }
            }

            &Address {
                width: 100%;
                font-size: 18px;
                line-height: 1.1;
                font-weight: 600;
                margin-bottom: 2px;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }

            &Info {
                max-width: 100%;
                height: 15px;
                font-size: 12px;
                line-height: 1.3;
                align-items: center;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #B5BDC5;
                }

                & span {
                    color: $colorSuccess;
                    font-weight: 900;
                }

                &Other {
                    font-size: 12px;
                    line-height: 1.3;
                    transition: $trans;
                    white-space: nowrap;

                    &._parent {
                        display: inline-block;
                        margin-left: 2px;
                    }
                }
            }
        }

        &Btn {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            font-size: 12px;
            line-height: 1.3;
            padding: 4px 10px;
            border-radius: 12px 0 0 0;
            font-weight: 600;
            align-items: center;
            transition: $trans;

            @include theme('light') {
                background: $colorBlueBack;
                color: $colorDark2;
            }

            @include theme('dark') {
                background: #1C1F21;
                color: #fff;
            }

            &::after {
                content: "";
                border: 3px solid transparent;
                transform: translate(0, -1px) rotate(45deg);
                margin-left: 4px;
                transition: $trans;

                @include theme('light') {
                    border-right-color: $colorDark2;
                    border-bottom-color: $colorDark2;
                }

                @include theme('dark') {
                    border-right-color: #fff;
                    border-bottom-color: #fff;
                }
            }
        }

        &._showDrop &Btn {
            background: #F6F8FF;

            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                background: #1C1F21;
                color: #AEC2FF;
            }

            &::after {
                transform: translate(0, 2px) rotate(-135deg);

                @include theme('light') {
                    border-right-color: $colorMain;
                    border-bottom-color: $colorMain;
                }

                @include theme('dark') {
                    border-right-color: #AEC2FF;
                    border-bottom-color: #AEC2FF;
                }
            }
        }
    }

    &__info {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 6px;

        &._withoutAmount &Item:not(._services) {
            width: calc(calc(100% / 2) - calc(8px / 2));
        }

        &Item {
            margin-bottom: 8px;
            padding: 12px 14px 10px 14px;
            border-radius: 12px;
            width: calc(calc(100% / 3) - calc(16px / 3));

            @include theme('light') {
                background: #F7F9FC;
            }

            @include theme('dark') {
                background: #25292C;
            }

            &._services {
                width: 100%;
                padding-top: 10px;
                padding-bottom: 12px;
            }

            &Value {
                max-width: 100%;
                font-size: 20px;
                line-height: 1.15;
                font-weight: 900;
                margin-bottom: -1px;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }

            &._services &Value {
                margin-bottom: 4px;
            }

            &Support {
                max-width: 100%;
                font-size: 14px;
                line-height: 1.2;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #B5BDC5;
                }
            }
        }
    }

    &._complete &__details {
        &Status {
            color: $colorSuccess;
        }
    }

    &__details {
        width: 100%;
        position: relative;
        z-index: 1;
        margin-bottom: 16px;
        padding: 24px 16px;

        &Back {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;

            @include theme('dark') {
                background: linear-gradient(to bottom, rgba(#2B2F33, 0), rgba(#2B2F33, 0) 6px, rgba(#2B2F33, 1) 6px, rgba(#2B2F33, 1) calc(100% - 6px), rgba(#2B2F33, 0) calc(100% - 6px), rgba(#2B2F33, 0) 100%);
            }

            &Inner {
                width: 100%;
                height: 100%;
                position: relative;

                @include theme('light') {
                    box-shadow: 0 0 2px 0 rgb(201, 205, 211);
                }

                @include theme('dark') {
                    box-shadow: 0 0 2px 0 rgba(0, 0, 0, .72);
                }
            }

            &Line {
                position: absolute;
                width: 100%;
                height: 9px;
                left: 0;
                overflow: hidden;

                &._top {
                    padding-top: 4px;
                    top: -2px;
                }

                &._bottom {
                    bottom: -2px;
                    padding-bottom: 4px;
                }

                &Inner {
                    width: calc(100% + 12px);
                    height: 100%;
                    margin-left: -6px;
                    justify-content: space-between;
                }

                &._bottom &Inner {
                    align-items: flex-end;
                }

                &Item {
                    width: calc(100% / 32);
                    height: calc(calc(100vw - 40px) / 32);
                    transform: rotate(45deg);

                    @include theme('light') {
                        background: #fff;
                    }

                    @include theme('dark') {
                        background: #2B2F33;
                    }

                    @include theme('light') {
                        box-shadow: 0 0 2px 0 rgb(201, 205, 211);
                    }

                    @include theme('dark') {
                        box-shadow: 0 0 2px 0 rgba(0, 0, 0, .72);
                    }
                }
            }

            &Box {
                width: calc(100% + 8px);
                height: 100%;
                position: relative;
                overflow: hidden;
                padding: 0 4px;
                margin-left: -4px;
            }
        }

        &Dot {
            position: absolute;
            width: 9px;
            height: 20px;
            z-index: 2;

            &::after {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                top: 50%;
                transform: translate(0, -50%);
                z-index: 2;

                @include theme('light') {
                    background: #fff;
                }

                @include theme('dark') {
                    background: #1C1F21;
                }
            }

            &._top {
                top: 25px;
            }

            &._bottom {
                bottom: 25px;
            }

            &._left {
                left: 0;

                &::after {
                    right: 4px;
                    border-radius: 0 360px 360px 0;
                }
            }

            &._left &Inner::before {
                right: 4px;
            }

            &._right {
                right: 0;

                &::after {
                    left: 4px;
                    border-radius: 360px 0 0 360px;
                }
            }

            &._right &Inner::before {
                left: 4px;
            }

            &Inner {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 2;
                overflow: hidden;

                &::before {
                    content: "";
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;

                    @include theme('light') {
                        background: #fff;
                        box-shadow: 0 0 3px 0 rgb(201, 205, 211);
                    }

                    @include theme('dark') {
                        background: #1C1F21;
                        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .8);
                    }

                    top: 50%;
                    transform: translate(0, -50%);
                    z-index: 1;
                }
            }
        }

        &Head {
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
        }

        &Total {
            font-size: 20px;
            font-weight: 900;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Status {
            font-size: 14px;
            font-weight: 900;
            color: #B5BDC5;
            text-transform: uppercase;
            letter-spacing: .4px;
        }

        &Items {
            width: 100%;
        }

        &Item {
            width: 100%;
            align-items: flex-end;
            padding-bottom: 3px;
            position: relative;
            z-index: 1;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-size: 8px 1px;

                @include theme('light') {
                    background-image: linear-gradient(90deg, rgba(#3E454C, .5), rgba(#3E454C, .5) 60%, transparent 60%, transparent 100%);
                }

                @include theme('dark') {
                    background-image: linear-gradient(90deg, rgba(#fff, .5), rgba(#fff, .5) 60%, transparent 60%, transparent 100%);
                }
            }

            &+& {
                margin-top: 16px;
            }

            &Col {
                max-width: 60%;
                font-size: 16px;
                line-height: 1.3;
                font-weight: 300;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }

                &._value {
                    max-width: 40%;
                    text-align: right;
                    font-weight: 600;
                    margin-left: auto;
                    padding-left: 20px;
                }
            }
        }
    }

    &__buttons {
        width: 100%;
        height: 56px;
        transition: $trans;
        position: relative;

        &._empty {
            height: 0;
        }
    }

    &__button {
        position: absolute;
        width: 100%;
        height: 56px;
        top: 0;
        left: 0;
        transition: $trans;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: $colorMain;
        box-shadow: 0px 2px 10px 0px rgba(62, 106, 237, 0.64);

        &._start._warn {
            box-shadow: 0px 0px 16px 0px #F25130;
        }

        &._market-wait {
            flex-direction: row;
        }

        &._wait,
        &._market-wait,
        &._disabled {
            box-shadow: none;
            pointer-events: none;

            @include theme('light') {
                background: #B5BDC5;
            }

            @include theme('dark') {
                background: #3E454C;
            }
        }

        &:not(._show) {
            opacity: 0;
            transform: translate(64px, 0);

            &._hide {
                transform: translate(-64px, 0)
            }
        }

        &Loader {
            background: inherit;
            border-radius: 12px;

            &._static {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
        }

        &._start &Content {
            font-weight: 900;
        }

        &Content {
            max-width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 1.3;
            font-weight: 600;
            color: #fff;
            position: relative;
            align-items: center;

            &._withIcon {
                padding-right: 28px;
                margin-left: 6px;
            }

            &Item {
                font-size: 16px;
                line-height: 1.3;
                font-weight: 600;
                white-space: nowrap;

                &._parent {
                    margin-left: 3px;
                }
            }

            &Icon {
                position: absolute;
                right: 0;
                width: 24px;
                height: 24px;
                @include icon(#fff);
                top: 50%;
                transform: translate(0, -50%);
                margin-top: 1px;
            }
        }

        &._start._alert &Description {
            color: #FFBF19;
            font-weight: 600;

            &Item {
                color: #FFBF19;
                font-weight: 600;
            }
        }

        &._start._warn &Description {
            color: #FFAB65;
            font-weight: 600;

            &Item {
                color: #FFAB65;
                font-weight: 600;
            }
        }

        &Description {
            max-width: 100%;
            text-align: center;
            font-size: 12px;
            line-height: 1.4;
            color: #fff;
            white-space: nowrap;
            align-items: center;
            transition: $trans;

            &Item {
                font-size: 12px;

                &._parent {
                    margin-left: 3px;
                }
            }
        }
    }

    &__buttons._static &__button {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__support {
        text-align: center;
        margin: 20px auto 0 auto;
    }
}