.balance {
    width: 100%;

    &__cards {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &:not(._ready) &__cards {
        opacity: 0;
    }

    &__card {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;
        border-radius: 16px;
        border: 1px solid transparent;
        padding: 16px 16px 13px 16px;
        width: 100%;

        @include theme('light') {
            background: #FCFDFE;
        }

        @include theme('dark') {
            background: #25292C;
            border-color: #2B2F33;
        }

        &:not(._show) {
            opacity: 0;
        }

        &Inner {
            width: 100%;
            position: relative;
        }

        &Title {
            max-width: 100%;
            font-size: 20px;
            font-weight: 600;
            color: $colorSuccess;
            margin-bottom: 6px;
        }

        &._minus &Title {
            color: $colorAlert;
        }

        &Description {
            max-width: 210px;
            font-size: 16px;
            line-height: 1.3;
            transition: $trans;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Date {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 16px;
            line-height: 1.2;
            color: #B5BDC5;
        }

        &Icon {
            position: absolute;
            bottom: 2px;
            right: 2px;
            width: 17px;
            height: 24px;
            z-index: 2;

            @include theme('light') {
                @include icon(#D4DBE1);
            }

            @include theme('dark') {
                @include icon(#3E454C);
            }
        }
    }

    ._parentForce &__card,
    &__cards._static &__card {
        position: relative;

        &:not(._show) {
            display: none;
        }

        &:not(._last) {
            margin-bottom: 10px;
        }
    }
}