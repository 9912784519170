.article {
    width: 100%;
    position: relative;

    &:not(._isReady) &__content {
        opacity: 0;
    }

    &__content {
        width: 100%;
        transition: $trans;

        & i {}

        & h1,
        & h2._main {
            max-width: 80%;
            font-size: 35px;
            font-weight: 900;
            line-height: 1.2;

            @include media(0, $mediaM) {
                max-width: 100%;
                font-size: 32px;
            }

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: $colorLight;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        & h2:not(._main) {
            max-width: 80%;
            font-size: 26px;
            font-weight: 600;
            line-height: 1.3;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: $colorLight;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        & a {
            word-wrap: break-word;
            text-decoration: none !important;
            border-bottom: 1px solid transparent;

            @include theme('light') {
                color: $colorMain;
                border-color: $colorMain;
            }

            @include theme('dark') {
                color: $colorMainTheme;
                border-color: $colorMainTheme;
            }
        }

        & b {
            font-weight: 900;
        }

        & span._search {
            color: $colorMain !important;
        }

        & p {
            margin: 0;
            font-size: 17px;
            font-weight: 500;
            line-height: 1.5;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: $colorLight;
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        & div:not([class]) {
            font-size: 17px;
            font-weight: 500;
            line-height: 1.3;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: $colorLight;
            }
        }

        &>p,
        &>div,
        &>ul,
        &>ol {
            &:not(:last-child) {
                margin-bottom: 16px;

                @include forType('crm') {
                    margin-bottom: 40px;
                }
            }
        }

        & ul,
        & ol {
            padding-left: 24px;
        }

        & ul li {
            list-style-type: disc;
        }

        & ol li {
            list-style-type: decimal;
        }

        & li {
            font-size: 17px;
            font-weight: 500;
            line-height: 1.3;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: $colorLight;
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    &._announce &__content br+br {
        content: "";
        display: block;
        margin-top: 12px;
    }

    &._announce &__content div:not([class]),
    &._announce &__content li {
        font-size: 15px;
        line-height: 1.5;

        &::marker {
            font-size: 12px;
        }
    }

    &._announce &__content ul,
    &._announce &__content ol {
        padding-left: 16px;
    }

    &._announce &__content>p,
    &._announce &__content>div,
    &._announce &__content>ul,
    &._announce &__content>ol {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &._public &__content h1 {
        font-size: 6rem;
        line-height: 1.25;
        font-weight: 600;
        color: $colorDark2;

        @include media(0, $mediaM) {
            font-size: 5.2rem;
        }
    }

    &._public &__content h2 {
        font-size: 4rem;
        line-height: 1.25;
        font-weight: 600;
        color: $colorDark2;
    }

    &._public &__content div:not([class]),
    &._public &__content li {
        font-size: 2.1rem;
        line-height: 1.35;
        color: $colorDark2;

        @include media(0, $mediaM) {
            font-size: 2.7rem;
        }

        & b {
            font-weight: 600;
        }

    }

    &._public &__content li {
        &:not(:last-child) {
            margin-bottom: 1.5rem;

            @include media(0, $mediaM) {
                margin-bottom: 2rem;
            }
        }
    }

    &._public &__content ul,
    &._public &__content ol {
        padding-left: 4rem;
    }

    &._public &__content>p,
    &._public &__content>div,
    &._public &__content>ul,
    &._public &__content>ol {
        &:not(:last-child) {
            margin-bottom: 3rem;

            @include media(0, $mediaM) {
                margin-bottom: 4rem;
            }
        }
    }

    &__images {
        justify-content: space-between;
        width: 100%;

        @include media(0, $mediaM) {
            flex-direction: column;
        }

        &:not(:last-child) {
            margin-bottom: 16px;

            @include forType('crm') {
                margin-bottom: 40px;
            }
        }

        &Item {
            width: 49%;

            @include media(0, $mediaM) {
                width: 100%;

                &+& {
                    margin-top: 2rem;
                }
            }
        }
    }

    &__image {
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: 12px;

        @include theme('light') {
            background: #fff;
            box-shadow: 0 0 2px 0 rgba(216, 220, 228, 0.72);
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
            box-shadow: 0 0 2px 0 rgba($colorDark, 0.72);
        }

        &Preview {
            position: relative;
            display: block;
            width: 100%;
            // height: 400px;
            // object-fit: cover;
        }

        &Description {
            width: 100%;
            margin: 0;
            padding: 12px 16px;
            font-size: 17px;
            font-weight: 500;
            line-height: 1.3;
            border-top: 2px solid transparent;

            @include theme('light') {
                color: $colorDark;
                background: #fff;
                border-top-color: #f2f2f2;
            }

            @include theme('dark') {
                color: #fff;
                background: $colorDarkThemeBorder;
                border-top-color: $colorDarkTheme2;
            }
        }
    }

    &._announce &__image {
        background: none !important;
        box-shadow: none;
        border-radius: 0;
        overflow: visible;

        &Preview {
            // box-shadow: 0 0 2px 0 rgba(186, 190, 198, 0.32) !important;
            border: 1px solid $colorBlue;
            border-radius: 14px;
            position: relative;
            z-index: 1;
            overflow: hidden;

            & img {
                object-fit: cover;
            }
        }

        &Description {
            font-size: 13.5px;
            color: $colorGreyDark;
            padding: 0;
            border-top: 0;
            margin-top: 6px;
            background: none;
        }
    }

    &._public &__image {
        background: none !important;
        box-shadow: none;

        &Preview {
            position: relative;
            z-index: 1;
            overflow: hidden;
            border-radius: 3rem;

            @include media(0, $mediaM) {
                border-radius: 4rem;
            }
        }

        &Description {
            margin-top: 1rem;
            padding: 0;
            font-size: 2rem;
            border-top: 0;
            color: $colorGreyDark;
            font-weight: 600;

            @include media(0, $mediaM) {
                font-size: 2.7rem;
            }
        }
    }
}