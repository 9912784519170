.newYearPopup {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__logo {
        position: absolute;
        top: 64px;
        left: 64px;
        width: 212px;
        transition: $trans;
    }

    &._result &__logo,
    &._result &__close {
        opacity: 0;
        pointer-events: none;
    }

    &__close {
        position: absolute;
        top: 64px;
        right: 64px;
        z-index: 5;
        width: 52px;
        height: 52px;
        background: rgba(#fff, 0.07);
        border-radius: 18px;
        transition: $trans;

        @include button(0.95);

        &Item {
            position: absolute;
            top: 50%;
            left: 50%;
            display: flex;
            width: 20px;
            height: 20px;

            &:first-child {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:last-child {
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &::before {
                width: 2px;
                height: 100%;
                margin: auto;
                background: rgba(#fff, 0.72);
                border-radius: 360px;
                content: "";
            }
        }
    }

    &__decor {
        transition: $trans;
        pointer-events: none;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__startBack {
        position: absolute;
        top: 0;
        z-index: -1;
        height: 100%;
        pointer-events: none;

        &._left {
            left: 0;
        }

        &._right {
            right: 0;
        }
    }

    &__topDecor {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 2;
        width: 420px;
        transform: translate(-50%, 0);
    }

    &__snow {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 400px;
        object-fit: contain;
        object-position: center bottom;

        &:not(._show) {
            transform: translate(0, 50%);
        }
    }

    &__resultDecor {
        position: absolute;
        z-index: -1;

        &._image {
            position: relative;
            top: 0 !important;
            left: 0 !important;
            width: 100% !important;
            transform: scale(0);
            will-change: transform;

            &._animate {
                animation: newYearFair 2s infinite ease-in-out;
            }
        }

        @keyframes newYearFair {
            0% {
                transform: scale(0);
            }

            80% {
                transform: scale(1);
                opacity: 1;
            }

            100% {
                transform: scale(1);
                opacity: 0;
            }
        }

        &._1 {
            top: -210px;
            left: -180px;
            width: 470px;
        }

        &._2 {
            top: -450px;
            left: -330px;
            width: 840px;
            animation-delay: 0.3s !important;
        }

        &._3 {
            top: 280px;
            left: 120px;
            width: 160px;
            animation-delay: 0.5s !important;
        }

        &._4 {
            top: 70px;
            left: 560px;
            width: 120px;
            animation-delay: 0.2s !important;
        }

        &._5 {
            top: -180px;
            right: 330px;
            width: 400px;
            animation-delay: 0.4s !important;
        }

        &._6 {
            top: -130px;
            right: -500px;
            width: 840px;
            animation-delay: 0.1s !important;
        }
    }

    &__blocks {
        position: relative;
        z-index: 4;
        width: 100%;
        height: 100%;
    }

    &__block {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-bottom: 60px;
        transition: 0.4s ease-in-out;
        will-change: transform;

        &:not(._start, ._result, ._clients) {
            padding-bottom: 0;
        }

        &._current {
            z-index: 5;
        }

        &:not(._current) {
            opacity: 0;
            pointer-events: none;

            &._prev {
                transform: translate(-250px, 20px) rotate(-5deg);
            }

            &._next {
                transform: translate(250px, 20px) rotate(5deg);
            }
        }
    }

    &__info {
        align-items: center;

        &Icon {
            width: 48px;
            margin-bottom: 28px;
        }

        &Support {
            margin-bottom: 20px;
            color: #fff;
            font-weight: 300;
            font-size: 17px;
            font-family: Gilroy;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        &Title {
            margin-bottom: 18px;
            color: #eee;
            font-weight: 600;
            font-size: 44px;
            font-family: Gilroy;
            line-height: 1.25;
            text-align: center;
        }

        &Text {
            color: #eee;
            font-weight: 400;
            font-size: 18px;
            font-family: Gilroy;
            line-height: 1.75;
            text-align: center;
        }

        &Buttons {
            align-items: center;
            margin-top: 32px;
        }

        &Button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 6px;
            padding: 16px 28px;
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            background: rgba(#fff, 0.08);
            border-radius: 12px;

            @include button(0.96);

            &._main {
                background: $colorMain;
            }

            &._sub {
                width: 116px;
            }
        }
    }

    &__stats {
        position: relative;
        z-index: 1;
        align-items: center;
        width: 100%;

        &Icon {
            position: absolute;
            left: 50%;
            z-index: -1;
            transform: translate(-50%, 0);
        }

        &Count {
            position: relative;
            width: 100%;
            margin-bottom: 8px;
            color: #fff;
            font-weight: 600;
            font-size: 128px;
            font-family: Gilroy;
            text-align: center;

            & span {
                display: block;
                font-weight: 600;
                font-family: Gilroy;
                white-space: nowrap;
                text-align: center;
            }
        }

        &Description {
            color: #eee;
            font-weight: 300;
            font-size: 28px;
            line-height: 1.45;
            text-align: center;

            & b {
                font-weight: 600;
            }
        }
    }

    &__block._join &__stats {
        &Count span {
            margin-left: 40px;
            background: linear-gradient(to bottom, #fff -40%, #84ff00);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &Icon {
            top: -40px;
            width: 50px;
            height: 50px;
            margin-left: -90px;
            object-fit: contain;
        }
    }

    &__block._pays &__stats {
        &Count span {
            margin-left: 40px;
            background: linear-gradient(to bottom, #fff -40%, #742dff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &Icon {
            top: -50px;
            width: 50px;
            height: 50px;
            margin-left: -56px;
            object-fit: contain;
        }
    }

    &__block._fns &__stats {
        &Count span {
            margin-left: 40px;
            background: linear-gradient(to bottom, #fff -40%, #00daff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &Icon {
            top: -30px;
            width: 50px;
            height: 50px;
            margin-left: 150px;
            object-fit: contain;
        }
    }

    &__block._transactions &__stats {
        &Count span {
            margin-left: 40px;
            background: linear-gradient(to bottom, #fff -40%, #3e6aed);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &Icon {
            top: -40px;
            width: 50px;
            height: 50px;
            margin-left: -34px;
            object-fit: contain;
        }
    }

    &__block._chats &__stats {
        &Count span {
            margin-left: 40px;
            background: linear-gradient(to bottom, #fff -20%, #3ed8ed);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &Icon {
            top: -40px;
            width: 50px;
            height: 50px;
            margin-left: -40px;
            object-fit: contain;
        }
    }

    &__block._blog &__stats {
        &Count,
        &Count span {
            font-size: 170px;
            background: linear-gradient(to bottom, #fff -40%, #edaf3e);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &Icon {
            top: -54px;
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
    }

    &__block._tax &__stats {
        &Count span {
            margin-left: 40px;
            background: linear-gradient(to bottom, #fff -40%, #fac0cb);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &Icon {
            top: -60px;
            width: 50px;
            height: 50px;
            margin-left: -6px;
            object-fit: contain;
        }
    }

    &__block._mvd &__stats {
        &Count span {
            margin-left: 40px;
            background: linear-gradient(to bottom, #fff -40%, #31ff79);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &Icon {
            top: -40px;
            width: 50px;
            height: 50px;
            margin-left: 106px;
            object-fit: contain;
        }
    }

    &__block._thanks &__stats {
        &Count span {
            margin-left: 40px;
            background: linear-gradient(to bottom, #fff -40%, #ed3e64);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &Icon {
            top: -60px;
            width: 50px;
            height: 50px;
            margin-left: -120px;
            object-fit: contain;
        }
    }

    &__block._clients &__stats {
        &Count,
        &Count span {
            margin-bottom: 0;
            font-size: 180px;
            background: linear-gradient(to bottom, #fff -40%, #76ff78);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &SubCount {
            margin-top: 16px;
            color: #eee;
            font-weight: 400;
            font-size: 120px;
            font-family: Gilroy;
            font-style: italic;
        }
    }

    &__nav {
        position: absolute;
        bottom: 0;
        z-index: 6;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 48px 64px;
        transition: $trans;
        pointer-events: none;

        &:not(._show) {
            opacity: 0;
        }

        &Steps {
            align-items: center;
            pointer-events: visible;
        }

        &Step {
            position: relative;
            display: flex;
            align-items: center;
            width: 24px;
            height: 24px;
            margin: 0 3px;
            transition: $trans;

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                background: rgba(#fff, 0.24);
                border-radius: 360px;
                transform: translate(0, -50%);
                content: "";
            }

            &._current {
                width: 56px;
            }

            &Inner {
                width: 0;
                height: 2px;
                background: #fff;
                border-radius: 360px;
            }
        }

        &Arrow {
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            background: rgba(#00ffe9, 0.16);
            border-radius: 50%;
            pointer-events: visible;

            &Icon {
                width: 32px;
                height: 32px;

                @include icon(#fff);
            }
        }
    }

    &__arrow {
        position: absolute;
        bottom: 0;
    }
}
