.pdfPayReceipt {
    width: 100%;

    &__title {
        width: 100%;
        font-size: 20px;
        line-height: 1.3;
        font-weight: 900;
        color: $colorDark2;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($colorDark2, .2);
        margin-bottom: 20px;
    }

    &__row {
        width: 100%;

        &._foot {
            border-top: 1px solid rgba($colorDark2, .2);
            padding-top: 20px !important;
            margin-top: 20px !important;
        }

        &+& {
            margin-top: 12px;
            padding-top: 12px;
        }
    }

    &__col {
        width: 70%;
        font-size: 14px;
        line-height: 1.45;
        color: $colorDark2;

        &._support {
            width: 30%;
            padding-right: 32px;
            color: $colorGreyDark;
        }

        &._bold {
            font-weight: 600;
        }
    }

    &__status {
        position: relative;
        padding-left: 26px;

        &Icon {
            width: 20px;
            height: 20px;
            @include icon($colorSuccess);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
        }
    }

    &__amount {
        width: 240px;

        &Row {
            width: 100%;
            justify-content: space-between;
            align-items: center;

            &+& {
                margin-top: 8px;
            }
        }

        &Col {
            font-size: 14px;
            line-height: 1.45;
            color: $colorDark2;

            &:first-child {
                color: $colorGreyDark;
            }
        }
    }
}