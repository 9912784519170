._click {
    cursor: default;

    @include mediaMin($mediaM + 1px) {
        cursor: pointer;
    }

    user-select: none;
}

._clickForce {
    cursor: pointer;

    & * {
        cursor: pointer !important;
    }
}

%row,
._row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

%col,
._col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

._SECTION {
    display: flex;
    flex-direction: column;
    align-items: center;
}

a,
label {
    @extend ._click;
}

._parentForEdit {
    &:hover ._editBtn {
        opacity: 1;
    }

    &:not(:hover) ._editBtn:not(._isShow) {
        pointer-events: none;
    }
}

._editBtn {
    position: absolute;
    top: 2px;
    right: 2px;
    transform: translate(50%, -50%);
    z-index: 10;
    width: 26px;
    height: 26px;
    opacity: 0;
    transition: $trans;

    &._isShow {
        opacity: 1;
    }
}

._parentForEdits {
    &._edit ._editBack:not(._current)::before {
        opacity: 1;
        visibility: visible;
        pointer-events: visible;
    }

    &._edit ._editBack:not(._current) {
        opacity: .64;
        filter: blur(8px);
        pointer-events: none;
    }

    &._edit ._editBack._current {
        @include theme('dark') {
            box-shadow: 0 0 24px 0 rgba(#000, .32);
        }

        @include theme('light') {
            box-shadow: 0 0 24px 0 rgba(216, 220, 228, .32);
        }
    }

    &._edit ._parentForEdit:not(._current) ._editBtn {
        opacity: 0;
        pointer-events: none;
    }
}

._editBack {
    position: relative;
    transition: $trans;
    border-radius: 20px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        @include blur(10px);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: $trans;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        display: none;

        @include theme('dark') {
            background: rgba($colorDarkTheme2, .3);
        }

        @include theme('light') {
            background: rgba(#fff, .3);
        }
    }
}

._windowWithCursor {
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        width: 30px;
        height: 24px;
        z-index: -1;

        @include media(0,
            $mediaM) {
            display: none;
        }

        @include theme('dark') {
            background: $colorDarkThemeBorder;
        }

        @include theme('light') {
            background: #fff;
        }
    }

    &::after {
        content: "";
        @extend %shadow;
        position: absolute;
        width: 14px;
        height: 14px;
        z-index: -2;

        @include media(0,
            $mediaM) {
            display: none;
        }

        @include theme('dark') {
            background: $colorDarkThemeBorder;
        }

        @include theme('light') {
            background: #fff;
        }
    }

    &._topCenter::after,
    &._topCenter::before {
        left: 50%;
        top: 0;
    }

    ._reverseY &._topCenter::after,
    ._reverseY &._topCenter::before {
        top: auto;
        bottom: 0;
    }

    &._bottomCenter::after,
    &._bottomCenter::before {
        left: 50%;
        bottom: 0;
    }

    ._reverseY &._bottomCenter::after,
    ._reverseY &._bottomCenter::before {
        bottom: auto;
        top: 0;
    }

    &._topRight::after,
    &._topRight::before {
        right: 16px;
        top: 0;
    }

    ._reverseY &._topRight::after,
    ._reverseY &._topRight::before {
        top: auto;
        bottom: 0;
    }

    &._bottomRight::after,
    &._bottomRight::before {
        right: 16px;
        bottom: 0;
    }

    ._reverseY &._bottomRight::after,
    ._reverseY &._bottomRight::before {
        top: 0;
        bottom: auto;
    }

    &._bottomLeft::after,
    &._bottomLeft::before {
        left: 16px;
        bottom: 0;
    }

    ._reverseY &._bottomLeft::after,
    ._reverseY &._bottomLeft::before {
        top: 0;
        bottom: auto;
    }

    &._centerRight::after,
    &._centerRight::before {
        right: 0;
        top: 50%;
    }

    &._centerLeft::after,
    &._centerLeft::before {
        left: 0;
        top: 50%;
    }

    &._topCenter::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    ._reverseY &._topCenter::after {
        transform: translate(-50%, 50%) rotate(45deg);
    }

    &._bottomCenter::after {
        transform: translate(-50%, 50%) rotate(45deg);
    }

    ._reverseY &._bottomCenter::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &._topRight::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    ._reverseY &._topRight::after {
        transform: translate(-50%, 50%) rotate(45deg);
    }

    &._bottomRight::after {
        transform: translate(-50%, 50%) rotate(45deg);
    }

    ._reverseY &._bottomRight::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &._bottomLeft::after {
        transform: translate(50%, 50%) rotate(45deg);
    }

    ._reverseY &._bottomLeft::after {
        transform: translate(50%, -50%) rotate(45deg);
    }

    &._centerRight::after {
        transform: translate(50%, -50%) rotate(45deg);
    }

    &._centerLeft::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &._topCenter::before {
        transform: translate(-50%, 0);
    }

    &._bottomCenter::before {
        transform: translate(-50%, 0);
    }

    &._topRight::before {
        transform: translate(0, 0);
        width: 40px;
    }

    &._centerRight::before {
        height: 30px;
        transform: translate(0, -50%);
    }

    &._centerLeft::before {
        height: 30px;
        transform: translate(0, -50%);
    }
}

._loader {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    transition: $trans;

    &:not(._show) {
        opacity: 0;
    }
}

._loaderItem {
    width: $sizeLoader;
    height: $sizeLoader;
    margin: auto;

    @include media(0, $mediaM) {
        width: $sizeLoaderMob;
        height: $sizeLoaderMob;
    }

    &._public {
        width: 3.5rem;
        height: 3.5rem;

        @include media(0, $mediaM) {
            width: 4.5rem;
            height: 4.5rem;
        }
    }
}

._loaderScroll {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    z-index: 10;
    pointer-events: none;
    transition: $trans;

    @include theme('light') {
        background: linear-gradient(to bottom, rgba($colorGrey, 0) 0%, rgba($colorGrey, 1) 100%);
    }

    @include theme('dark') {
        background: linear-gradient(to bottom, rgba($colorDarkTheme2, 0) 0%, rgba($colorDarkTheme2, 1) 100%);
    }

    @include forType('app', 'dark') {
        background: linear-gradient(to bottom, rgba(#1C1F21, 0) 0%, rgba(#1C1F21, 1) 100%);
    }

    &._full {
        height: 100%;
    }

    @include forType('crm', 'light') {
        background: linear-gradient(to bottom, rgba($colorBlueBack, 0) 0%, rgba($colorBlueBack, 1) 100%);
    }

    &._white {
        @include forType('crm', 'light') {
            background: linear-gradient(to bottom, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
        }
    }

    @include media(0,
        $mediaM) {
        height: 160px;
    }

    &._right {
        left: auto;
        right: 0;
        width: 120px;
        height: 100%;
        bottom: auto;
        top: 0;
        background: linear-gradient(to right, rgba($colorGrey, 0) 0%, rgba($colorGrey, 1) 100%);

        &._full {
            width: 100%;
        }

        @include forType('crm',
            'light') {
            background: linear-gradient(to right, rgba($colorBlueBack, 0) 0%, rgba($colorBlueBack, 1) 100%);
        }
    }

    &:not(._show) {
        opacity: 0;
        transform: translate(0, 6px);

        &._right {
            transform: translate(6px, 0);
        }
    }
}