@mixin themeLocal($theme) {
    .theme._#{$theme} & {
        @content;
    }
}

.theme {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: $transLong;

    &._dark {
        background: rgba(#fff, 1);
    }

    &._light {
        background: rgba(29, 34, 42, 1);
    }

    &__inner {
        position: relative;
        width: 280px;
        height: 280px;
        background: #fff;
        border-radius: 16px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        transition: $transLong;

        @include media(0, $mediaM) {
            border-radius: 24px;
        }

        @include themeLocal('light') {
            background: #2f333c;
        }

        @include themeLocal('dark') {
            background: #fff;
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 2;
        width: 46px;
        height: 46px;
        border-radius: 10px;
        transform: translate(-50%, -50%);
        transition: $transLong;

        @include media(0, $mediaM) {
            width: 48px;
            height: 48px;
            border-radius: 14px;
        }

        @include themeLocal('light') {
            background: #4a505b;
        }

        @include themeLocal('dark') {
            background: #ffeb00;
        }

        &Item {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            width: 22px;
            height: 22px;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: $transLong;

            @include media(0, $mediaM) {
                width: 24px;
                height: 24px;
            }

            &._light {
                @include themeLocal('light') {
                    opacity: 1;
                }
            }

            &._dark {
                @include themeLocal('dark') {
                    opacity: 1;
                }
            }
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 38px 0 0 0;
        overflow: hidden;
        border-radius: 16px;

        @include media(0, $mediaM) {
            padding-top: 48px;
            border-radius: 24px;
        }
    }

    &__title {
        max-width: 100%;
        margin-bottom: 8px;
        font-weight: 900;
        font-size: 22px;
        text-align: center;
        transition: $transLong;

        @include themeLocal('light') {
            color: #fff;
        }

        @include themeLocal('dark') {
            color: $colorDark;
        }

        @include media(0, $mediaM) {
            font-size: 22px;
        }
    }

    &__description {
        max-width: 100%;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        transition: $transLong;

        @include themeLocal('light') {
            color: #fff;
        }

        @include themeLocal('dark') {
            color: $colorDark;
        }
    }

    &__item {
        position: absolute;
        top: 120px;
        left: 50%;
        z-index: 2;
        width: 60px;
        height: 60px;
        object-fit: contain;
        transform: translate(-50%, 0);
        transition: $transLong;

        &._light {
            transform: translate(-50%, -60px) scale(.8);
            opacity: 0;

            @include themeLocal('light') {
                transform: translate(-50%, 0) scale(.8);
                opacity: 1;
            }
        }

        &._dark {
            transform: translate(-50%, 60px);
            opacity: 0;

            @include themeLocal('dark') {
                transform: translate(-50%, 0);
                opacity: 1;
            }
        }
    }

    &__image {
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 1;
        width: 104%;
        object-fit: cover;
        object-position: center bottom;
        transform: translate(-50%, 0);
        opacity: 0;
        transition: $transLong;

        &._light {
            @include themeLocal('light') {
                opacity: 1;
            }
        }

        &._dark {
            @include themeLocal('dark') {
                opacity: 1;
            }
        }
    }
}