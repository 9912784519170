@mixin borderDashed($color) {
    & .borderDashed__rect {
        stroke: $color;
    }
}

.borderDashed {
    width: 100%;
    height: 100%;

    &__rect {
        transition: $trans;
        stroke-width: 1;
        stroke-dasharray: 5 5;

        @include theme('light') {
            stroke: $colorMain;
        }

        @include theme('dark') {
            stroke: $colorMainTheme;
        }
    }

    &._grey &__rect {
        @include theme('light') {
            stroke: #9da8b4;
        }

        @include theme('dark') {
            stroke: $colorDarkThemeText;
        }
    }

    &._upload &__rect {
        stroke-width: 1;
        stroke-dasharray: 5 5;
    }

    &._upload2 &__rect {
        stroke-width: 1;
        stroke-dasharray: 6 6;
    }

    &._minSize &__rect {
        stroke-dasharray: 4 2;
        stroke-width: 1;
    }

    &._app &__rect {
        stroke-width: 1.2;
        stroke-dasharray: 7 7;
    }
}