.chatList {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 24px;

    &._mode-popup {
        padding-top: 16px;
    }

    &__inner {
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: $trans;
    }

    &._ready &__inner {
        opacity: 1;
    }

    &__head {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 32px;
        padding: 0 24px;

        &::before {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 8px;
            background: linear-gradient(to bottom, $colorBlueBack, rgba($colorBlueBack, 0));
            content: "";
            pointer-events: none;
        }
    }

    &._mode-popup &__head {
        padding: 0 16px;

        &::before {
            background: linear-gradient(to bottom, #fff, rgba(#fff, 0));
        }
    }

    &__search {
        width: calc(100% - 40px);
        height: 100%;

        & .v2search {
            background: #fff !important;
            border-radius: 8px;

            @include icon(#76808a);
        }

        & .v2search__field .v2input__field,
        & .v2search__field .v2input__support {
            color: #76808a;
            font-size: 13px;
        }

        & .v2search__field .v2input__field {
            color: $colorDark2;
        }
    }

    &._mode-popup &__search .v2search {
        background: $colorBlueBack !important;
    }

    &__filter {
        width: 32px;
        height: 100%;
        margin-left: auto;
    }

    &._mode-popup &__filter .filterButton {
        background: $colorBlueBack;
    }

    &__content {
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(100% - 32px);
        padding: 8px 24px 0;
        overflow: hidden;
        overflow-y: auto;
        transition: $trans;
    }

    &._mode-popup &__content {
        padding-right: 16px;
        padding-left: 16px;
    }

    &._loading &__content {
        opacity: 0;
    }

    &__info {
        position: absolute;
        bottom: 24px;
        left: 24px;
        z-index: 0;
        width: calc(100% - 48px);
        background: rgba(#fff, 0.72);
        border-radius: 20px;
        transition: $trans;
        pointer-events: none;

        &:not(._show) {
            opacity: 0;
        }
    }

    &._loading &__info {
        opacity: 0;
    }

    &__items {
        position: relative;
        width: 100%;
        min-height: 100%;
        transition: $trans;
    }

    &__item {
        z-index: 1;
        width: 100%;
        transition: $trans;

        &Inner {
            @include button;

            width: 100%;
            height: 100%;
            transition: $trans;
        }
    }

    &__items._static &__item:not(._last) {
        margin-bottom: 8px;
    }

    &__items._static &__item._last {
        margin-bottom: 24px;
    }
}
