.settings {
    width: 100%;
    min-height: 100%;
    align-items: center;
    position: relative;

    &__loader {
        height: calc(100vh - 30rem);
    }

    &__blocks {
        width: 100%;
        margin-bottom: 24px;
    }

    &__block {
        width: 100%;
        position: relative;
        border-radius: 16px;
        z-index: 1;
        overflow: hidden;
        border: 1px solid transparent;
        transition: $trans;

        &._hide {
            opacity: 0;
        }

        @include theme('light') {
            border-color: #fff;
        }

        @include theme('dark') {
            border-color: #2B2F33;
        }

        &+& {
            margin-top: 16px;
        }

        &Link {
            width: 100%;
            min-height: 50px;
            padding: 10px 16px 11px 16px;
            position: relative;
            transition: $trans;

            @include theme('light') {
                background: #FCFDFE;
                color: $colorDark2;
            }

            @include theme('dark') {
                background: #25292C;
                color: #fff;
            }

            &+& {
                border-top: 3px solid transparent;

                @include theme('light') {
                    border-color: $colorBlueBack;
                }

                @include theme('dark') {
                    border-color: #1C1F21;
                }
            }

            &Icon {
                width: 20px;
                height: 20px;
                @include icon(#B5BDC5);
                margin: 3px 14px 0 0;
            }

            &Content {
                max-width: calc(100% - 34px);
                padding-right: 32px;
                font-size: 20px;
                line-height: 1.3;
            }

            &Arrow {
                position: absolute;
                top: 10px;
                right: 12px;
                width: 28px;
                height: 28px;
                z-index: 2;

                @include theme('light') {
                    @include icon($colorDark2);
                }

                @include theme('dark') {
                    @include icon(#B5BDC5);
                }
            }
        }
    }

    &__avatar {
        width: 100%;
        align-items: center;
        padding: 6px;
        border-radius: 16px;
        margin-bottom: 8px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        transition: $trans;

        @include theme('light') {
            background: #FCFDFE;
        }

        @include theme('dark') {
            background: #25292C;
        }

        &._isLoading &PreviewItem {
            opacity: 0;
        }

        &Preview {
            width: 104px;
            height: 104px;
            position: relative;

            &Inner {
                width: 100%;
                height: 100%;
                z-index: 1;
                overflow: hidden;
                position: relative;
                border-radius: 12px;
                transition: $trans;

                @include theme('light') {
                    background: $colorBlueBack;
                }

                @include theme('dark') {
                    background: #2F3439;
                }
            }

            &Item {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }
            }

            &Image {
                width: 100%;
                height: 100%;

                &Item {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &Icon {
                width: 52px;
                height: 52px;

                @include theme('light') {
                    @include icon(#B5BDC5);
                }

                @include theme('dark') {
                    @include icon(#3E454C);
                }
            }
        }

        &Content {
            width: calc(100% - 104px);
            padding-left: 20px;
        }
    }

    &__info {
        width: 100%;
        padding: 16px 20px;
        border-radius: 16px;
        transition: $trans;

        @include theme('light') {
            background: #FCFDFE;
        }

        @include theme('dark') {
            background: #25292C;
        }

        &+& {
            margin-top: 8px;
        }

        &._inner {
            padding: 0;
            background: none;
        }

        &Title {
            max-width: 100%;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 6px;
            transition: $trans;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Description {
            max-width: 100%;
            font-size: 16px;
            line-height: 1.3;
            transition: $trans;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #B5BDC5;
            }

            &+& {
                margin-top: 5px;
            }

            & span {
                color: #9EA6AF;
                line-height: 1.3;
                padding-right: 4px;
                white-space: nowrap;
            }

            &Item {
                overflow: hidden;
                display: block;
                text-overflow: ellipsis;
                font-size: 16px;
                line-height: 1.3;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }
        }

        &Button {
            width: 100%;
            margin-top: 20px;
            height: 50px;
        }

        &Link {
            font-size: 16px;
            font-weight: 600;
            margin-top: 8px;
            display: block;
            transition: $trans;

            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: #AEC2FF;
            }

            &._alert {
                @include theme('light') {
                    color: $colorAlert;
                }

                @include theme('dark') {
                    color: $colorAlert;
                }
            }

            &Input {
                display: none;
            }
        }

        &Avatar {
            width: 100%;
            position: relative;

            &Item {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;

                    &._next {
                        transform: translate(0, -12px);
                    }

                    &._prev {
                        transform: translate(0, 12px);
                    }
                }
            }

            &._static &Item {
                position: relative;

                &:not(._show) {
                    display: none;
                }
            }
        }

        &Contract {
            width: 100%;
            margin-top: 8px;
        }
    }

    &__link {
        margin-top: auto;
    }

    &__pages {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__page {
        @extend %noscroll;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: $trans;
        overflow-y: auto;
        padding: calc(#{$padding} + 20px) $padding 30px $padding;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-64px, 0);
            }

            &._next {
                transform: translate(64px, 0);
            }
        }
    }

    &__button {
        width: 100%;
        height: 56px;
        margin-top: 12px;
    }

    &__fns {
        width: 100%;

        &Dynamics {
            width: 100%;
            position: relative;
            transition: $trans;

            &:not(._empty) {
                margin-top: 20px;
            }

            &Block {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }
            }

            &._static &Block {
                position: relative;

                &:not(._show) {
                    display: none;
                }
            }
        }

        &Block {
            width: 100%;

            &+& {
                margin-top: 20px;
            }
        }

        &Head {
            width: 100%;
            min-height: 64px;
            padding: 12px 20px;
            align-items: center;
            border-radius: 16px 16px 0 0;
            margin-bottom: 3px;
            transition: $trans;

            &._logo {
                padding-left: 14px;
                padding-right: 14px;
            }

            @include theme('light') {
                background: #FCFDFE;
            }

            @include theme('dark') {
                background: #25292C;
            }

            &Logo {
                width: 80px;
                height: 80px;
                object-fit: contain;
                margin-right: 14px;
            }

            &._logo &Title {
                max-width: calc(100% - 94px);
            }

            &Title {
                max-width: 100%;
                font-size: 18px;
                line-height: 1.3;
                font-weight: 600;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }
        }

        &Content {
            width: 100%;
            margin-bottom: 3px;
            padding: 16px 20px;
            transition: $trans;

            &._foot {
                border-radius: 0 0 16px 16px;
            }

            @include theme('light') {
                background: #FCFDFE;
            }

            @include theme('dark') {
                background: #25292C;
            }
        }

        &Status {
            width: 100%;
            justify-content: space-between;
            align-items: center;

            &Support {
                font-size: 16px;
                line-height: 1.3;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }

            &Item {
                padding: 8px 10px;
                border-radius: 9px;
                transition: $trans;

                @include theme('light') {
                    background: #FFF3F1;
                }

                @include theme('dark') {
                    background: rgba($colorAlert, .2);
                }

                &._process {
                    @include theme('light') {
                        background: $colorBlueBack;
                    }

                    @include theme('dark') {
                        background: $colorDarkTheme2;
                    }
                }

                &._active {
                    @include theme('light') {
                        background: #E0FFE2;
                    }

                    @include theme('dark') {
                        background: rgba($colorSuccess, .2);
                    }
                }

                &Inner {
                    font-size: 16px;
                    font-weight: 600;
                    color: $colorAlert;
                    white-space: nowrap;
                }

                &._process &Inner {
                    color: #B5BDC5;
                }

                &._active &Inner {
                    color: #5CC962;
                }
            }
        }

        &Data {
            width: 100%;
            justify-content: space-between;
            transition: $trans;

            @include theme('light') {
                border-color: #f5f7fa;
            }

            @include theme('dark') {
                border-color: #1C1F21;
            }

            &+& {
                margin-top: 10px;
                padding-top: 10px;
                border-top: 2px solid transparent;
            }

            &Text {
                font-size: 16px;
                line-height: 1.3;
                transition: $trans;

                &._support {
                    @include theme('light') {
                        color: #7E8892;
                    }

                    @include theme('dark') {
                        color: #B5BDC5;
                    }
                }

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }
        }

        &Foot {
            width: 100%;
            padding: 20px;
            border-radius: 0 0 16px 16px;
            transition: $trans;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @include theme('light') {
                background: #FCFDFE;
            }

            @include theme('dark') {
                background: #25292C;
            }
        }

        &Button {
            width: 100%;
            height: 56px;

            &+& {
                margin-top: 12px;
            }

            &Inner {
                width: 100%;
                height: 100%;
            }
        }

        &Error {
            transition: $trans;

            &:not(._empty) {
                margin-top: 8px;
            }

            &Inner {
                font-size: 15px;
                line-height: 1.4;
                color: $colorAlert;
                display: inline;
            }

            &Link {
                font-size: 15px;
                line-height: 1.4;
                transition: $trans;
                border-bottom: 1px solid transparent;
                display: inline;
                font-weight: 600;
                color: $colorAlert;
                border-color: rgba($colorAlert, .5);
            }
        }
    }

    &__wallet {
        width: 100%;
        border-radius: 16px;
        border: 1px solid transparent;
        transition: $trans;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &._current {
            border-color: #F24483;
        }

        &+& {
            margin-top: 20px;
        }

        &._current &Head {
            padding-top: 44px;
        }

        &:not(._current) &HeadCurrent {
            transform: translate(0, -100%);
        }

        &Head {
            width: 100%;
            border-radius: 16px 16px 0 0;
            transition: $trans;
            padding: 18px 20px;
            align-items: center;
            margin-bottom: 3px;
            position: relative;
            overflow: hidden;
            z-index: 1;

            @include theme('light') {
                background: #FCFDFE;
            }

            @include theme('dark') {
                background: #25292C;
            }

            &Current {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                font-size: 13px;
                font-weight: 700;
                color: #fff;
                background: #F24483;
                border-radius: 0 0 16px 0;
                padding: 7px 16px 8px 14px;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }
            }

            &Icon {
                width: 20px;
                height: 20px;
                object-fit: contain;
                object-position: left center;
                margin-right: 12px;
            }

            &Title {
                font-size: 20px;
                font-weight: 600;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }

            &Link {
                margin-left: auto;
                font-size: 16px;
                transition: $trans;

                @include theme('light') {
                    color: $colorMain;
                }

                @include theme('dark') {
                    color: #AEC2FF;
                }
            }
        }

        &Content {
            width: 100%;
            padding: 20px;
            border-radius: 0 0 16px 16px;
            transition: $trans;

            @include theme('light') {
                background: #FCFDFE;
            }

            @include theme('dark') {
                background: #25292C;
            }
        }

        &Details {
            width: 100%;

            &Item {
                width: 100%;
                font-size: 16px;
                line-height: 1.4;
                color: $colorDark2;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }

                & span {
                    color: #9EA6AF;
                }

                &+& {
                    margin-top: 2px;
                }
            }
        }

        &Button {
            width: 100%;
            transition: $trans;

            &:not(._empty) {
                margin-top: 8px;
            }

            & .button {
                height: 52px;
            }
        }
    }

    &__legal {
        width: 100%;

        &Inner {
            width: 100%;
            transition: $trans;
        }

        &:not(._ready) &Inner {
            opacity: 0;
        }

        &Title {
            font-size: 26px;
            line-height: 1.3;
            font-weight: 600;
            margin-bottom: 12px;
            transition: $trans;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Date {
            font-size: 16.5px;
            margin-bottom: 44px;
            transition: $trans;

            @include theme('light') {
                color: $colorGreyDark;
            }

            @include theme('dark') {
                color: $colorGreyDark;
            }
        }

        &Content {
            @extend %editorContent;
            width: 100%;

            & * {
                font-size: 16.5px;
                line-height: 1.4;
                font-weight: 300;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }

            & a {
                font-weight: 500;

                @include theme('light') {
                    color: $colorMain;
                    border-bottom-color: $colorMain;
                }

                @include theme('dark') {
                    color: #AEC2FF;
                    border-bottom-color: #AEC2FF;
                }
            }

            & b {
                font-weight: 500;
            }

            & h3 {
                font-weight: 500;
                font-size: 28px;
            }

            & ._DOC a {
                background: $colorBlueBack;

                @include theme('light') {
                    background: #fff;
                    border: 1px solid rgba($colorMain, .2);
                }

                @include theme('dark') {
                    background: #2B2F33;
                    border: 1px solid rgba(#AEC2FF, .2);
                    color: #fff;
                }
            }
        }
    }
}