.legal {
    width: 100%;

    &__cards {
        width: 100%;
        border-radius: 14px;
        box-shadow: 0 0 2px 0 rgba(216, 220, 228, 0.72);
        padding: 0 24px;

        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: $colorDarkTheme3;
        }

        @include forType('site') {
            box-shadow: none;
            background: none !important;
            border-radius: 0;
            padding: 0;
        }
    }

    &__card {
        width: 100%;
        position: relative;
        padding: 24px 0;

        @include forType('site') {
            border-radius: 14px;
            padding: 24px 24px;
        }

        @include forType('site', 'light') {
            background: #fff;
        }

        @include forType('site', 'dark') {
            background: $colorDarkTheme3;
        }

        &+& {
            border-top: 1px solid #DAE0E7;

            @include forType('site') {
                border: 0;
                margin-top: 10px;
            }
        }

        &Inner {
            width: 100%;
            position: relative;
            font-size: 16.5px;
            line-height: 1.3;
            padding: 0 100px 0 32px;

            @include media(0, $mediaM) {
                padding-right: 0;
            }

            @include forType('site', 'light') {
                color: $colorDark2;
            }

            @include forType('site', 'dark') {
                color: #fff;
            }
        }

        &Icon {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            width: 20px;
            height: 20px;
            @include icon(#9DA8B4);

            @include media(0, $mediaM) {
                top: 0;
                transform: translate(0, 0);
            }
        }

        &Link {
            position: absolute;
            font-size: 14px;
            padding-right: 22px;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);

            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: $colorMainTheme;
            }

            @include media(0, $mediaM) {
                position: relative;
                top: 0;
                transform: translate(0, 0);
                margin-top: 10px;
            }

            &Icon {
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: 1px;
                transform: translate(0, -50%);
                width: 15px;
                height: 15px;

                @include theme('light') {
                    @include icon($colorMain);
                }

                @include theme('dark') {
                    @include icon($colorMainTheme);
                }
            }
        }
    }
}