.pdfAgentPayAct {
    width: 100%;

    &__head {
        width: 100%;
        margin-bottom: 28px;

        &Content {
            width: 240px;
            margin-left: auto;
        }

        &Text {
            width: 100%;
            font-size: 14px;
            line-height: 1.3;
            color: $colorDark2;

            &+& {
                margin-top: 4px;
            }
        }
    }

    &__title {
        max-width: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 1.3;
        font-weight: 900;
        color: $colorDark2;
        margin-bottom: 28px;
    }

    &__text {
        max-width: 100%;
        font-size: 14px;
        line-height: 1.3;
        color: $colorDark2;
        text-align: justify;
        position: relative;

        &._indent {
            padding-left: 32px;
        }

        &+& {
            margin-top: 10px;
        }

        & ._counter {
            position: absolute;
            top: 0;
            left: 16px;
            font-size: 14px;
            line-height: 1.3;
            color: $colorDark2;
        }
    }

    &__table {
        @extend %editorContent;
        margin: 20px 0;

        & td {
            font-size: 14px;
            line-height: 1.3;
            color: $colorDark2;
            padding-top: 7px !important;
            padding-bottom: 7px !important;

            &:first-child {
                width: 70%;
            }

            &:last-child {
                width: 30%;
            }
        }
    }
}