.notificationPreview {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 16px;
    border: 1px solid transparent;
    padding: 16px;
    @include theme('light') {
        background: #FCFDFE;
    }
    @include theme('dark') {
        background: #25292C;
        border-color: #2B2F33;
    }
    &__content {
        width: 100%;
        position: relative;
    }
    &._new &__content {
        padding-left: 16px;
    }
    &__date {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 16px;
        color: #B5BDC5;
        line-height: 1.5;
        padding-top: 1px;
    }
    &._new &__title {
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translate(0, -50%);
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: $colorAlert;
        }
    }
    &__title {
        max-width: 100%;
        position: relative;
        padding-left: 28px;
        font-size: 20px;
        line-height: 1.3;
        font-weight: 600;
        margin-bottom: 10px;
        @include theme('light') {
            color: $colorDark2;
        }
        @include theme('dark') {
            color: #fff;
        }
        &Icon {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 0;
            width: 18px;
            height: 18px;
            object-fit: contain;
            @include icon(#B5BDC5);
        }
    }
    &._system &__titleIcon {
        transform: translate(0, -50%) scale(1.15);
    }
    &._fns &__titleIcon {
        transform: translate(0, -50%) scale(1.15);
    }
    &__description {
        max-width: 100%;
        font-size: 16px;
        line-height: 1.3;
        @include theme('light') {
            color: $colorDark2;
        }
        @include theme('dark') {
            color: #fff;
        }
    }
}