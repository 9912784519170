.corporationPreview {
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 16px;

    @include theme("light") {
        background: #fcfdfe;
    }

    @include theme("dark") {
        background: #25292c;
        border-color: #2b2f33;
    }

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__content {
        width: 100%;
    }

    &__logo {
        position: relative;
        z-index: 1;
        width: 48px;
        height: 48px;
        overflow: hidden;
        border-radius: 50%;

        @include theme("light") {
            background: rgba($colorBlue, 0.5);
        }

        @include theme("dark") {
            background: #1c1f21;
        }

        & .avatar__item {
            font-size: 20px;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }
        }
    }

    &__info {
        width: calc(100% - 48px);
        padding-left: 11px;
    }

    &__name {
        max-width: 100%;
        margin-bottom: 3px;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;

        @include theme("light") {
            color: $colorDark2;
        }

        @include theme("dark") {
            color: #fff;
        }
    }

    &__description {
        max-width: 100%;
        font-size: 16px;
        line-height: 1.3;

        @include theme("light") {
            color: $colorDark2;
        }

        @include theme("dark") {
            color: #fff;
        }
    }

    &__status {
        margin-top: 7px;
        padding: 6px 12px 8px;
        font-weight: 600;
        font-size: 15px;
        border-radius: 8px;

        &._active {
            color: $colorSuccess;

            @include theme("light") {
                background: #e0ffe1;
            }

            @include theme("dark") {
                background: rgba($colorSuccess, 0.2);
            }
        }

        &._process,
        &._invite {
            color: #ffb400;

            @include theme("light") {
                background: rgba(#ffb400, 0.2);
            }

            @include theme("dark") {
                background: rgba(#ffb400, 0.2);
            }
        }

        &._not-sign,
        &._not-sign-corporation {
            color: #98d400;

            @include theme("light") {
                background: rgba(#98d400, 0.2);
            }

            @include theme("dark") {
                background: rgba(#98d400, 0.2);
            }
        }

        &._inactive {
            color: $colorAlert;

            @include theme("light") {
                background: rgba($colorAlert, 0.2);
            }

            @include theme("dark") {
                background: rgba($colorAlert, 0.2);
            }
        }
    }

    &__button {
        width: 100%;
        height: 56px;
        margin-top: 12px;
    }
}
