.appOrderStatus {
    border-radius: 360px;
    transition: $trans;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: $trans;
        background: linear-gradient(0deg, #3E6AED 0%, #6BA7FF 100%);
        opacity: 0;
    }

    &._complete {
        background: $colorSuccess;
    }

    &._cancel {
        background: $colorAlert;
    }

    &._fast::before {
        opacity: 1;
    }

    &._wait {
        @include theme('light') {
            background: #B5BDC5;
        }

        @include theme('dark') {
            background: #3E454C;
        }

        &._active {
            background: $colorSuccess !important;
        }
    }

    &__inner {
        position: relative;
        transition: $trans;
    }

    &__item {
        padding: 5px 8px 5px 5px;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
        font-weight: 900;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        white-space: nowrap;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
            transform: translate(0, -6px);

            &._hide {
                transform: translate(0, 6px);
            }
        }

        &._fast {
            padding-right: 10px;
        }

        &._wait {
            padding-right: 10px;
        }

        &Icon {
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }

        &._fast &Icon {
            width: 14px;
            height: 14px;
            margin-right: 3px;
            margin-left: 1px;
        }

        &._wait &Icon {
            @include icon(#fff);
        }
    }

    &__inner._static &__item {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }
}