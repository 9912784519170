.codeBox {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    &._join {
        justify-content: center;
    }

    &._pays {
        justify-content: flex-start;
    }

    &__input {
        text-align: center;
        width: 22%;
        height: 44px;
        border-radius: 11px;
        border: 1px solid transparent;
        transition: $trans;
        font-size: 22px;
        font-weight: 500;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            display: none;
            -webkit-appearance: none;
            margin: 0;
        }

        @include forType('crm') {
            font-size: 20px;
            font-weight: 600;
        }

        @include forType('app') {
            font-size: 20px;
            font-weight: 600;
        }

        @include theme('light') {
            background: #fff;
            color: $colorMain;
        }

        @include theme('dark') {
            background: $colorDarkTheme3;
            color: $colorMainTheme;
        }

        &._error {
            @include theme('light') {
                background: rgba($colorAlert, .05);
                border-color: rgba($colorAlert, .05);
            }

            @include theme('dark') {
                background: rgba($colorAlert, .05);
                border-color: rgba($colorAlert, .05);
            }
        }

        &._errorAnim {
            animation: errorInput .7s linear 1;

            @keyframes errorInput {
                0% {
                    transform: translate(0, 0);
                }

                12% {
                    transform: translate(0, 4px);
                }

                24% {
                    transform: translate(0, 0);
                }

                36% {
                    transform: translate(0, -4px);
                }

                48% {
                    transform: translate(0, 0);
                }

                60% {
                    transform: translate(0, 4px);
                }

                72% {
                    transform: translate(0, 0);
                }

                84% {
                    transform: translate(0, -4px);
                }

                96% {
                    transform: translate(0, 0);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }
    }

    &._join &__input,
    &._pays &__input {
        width: 56px;
        height: 56px;
        font-size: 28px;
        background: #F2F4F7;
        color: $colorDark2;
        border-radius: 12px;
        margin: 0 5px;

        &._error {
            color: $colorError;
        }
    }

    &._pays &__input {
        margin: 0;
        background: $colorBlueBack;
    }

    &._pays &__input+&__input {
        margin-left: 12px;
    }

    &._forget &__input {
        width: 18%;

        @include forType('crm') {
            width: 16.5%;
            height: 46px;
        }

        @include theme('dark') {
            color: $colorMainTheme;
            background: $colorDarkThemeBorder;
        }

        @include theme('light') {
            color: $colorMain;
            background: #f7f8fb;
        }

        @include forType('crm', 'light') {
            color: $colorDark;
        }

        &._error {
            @include theme('dark') {
                background: rgba($colorAlert, .05);
                border-color: rgba($colorAlert, .05);
            }

            @include theme('light') {
                background: rgba($colorAlert, .05);
                border-color: rgba($colorAlert, .05);
            }
        }
    }
}