._SECTION {
    display: flex;
    flex-direction: column;
    align-items: center;
}

._INNER {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &._PUBLIC {
        width: 135rem;

        @include mob {
            width: 92vw;
        }
    }
}

._ERROR_WRAPPER {
    width: 100%;

    &._parent:not(._empty) {
        margin-top: 16px;
    }

    &._center & {
        text-align: center;
    }
}

._ROW {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    &._CENTER {
        justify-content: center;
    }

    &._ROW-ALIGN-CENTER {
        align-items: center;
    }

    &._ROWWRAP {
        flex-wrap: wrap;
    }
}

._COL {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &._CENTER {
        align-items: center;
    }
}

._CLICK {
    @include mediaMin($mediaM + 1px) {
        cursor: pointer;
    }

    user-select: none;
}

._CLICKFORCE {
    cursor: pointer !important;

    & * {
        cursor: pointer !important;
    }
}

._NOSCROLL {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
}

._LOADER {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: $trans;
    pointer-events: none;

    &._SCROLL {
        top: auto;
        bottom: 0;
        height: 25%;
        min-height: 100px;
        padding-top: 64px;
        background: linear-gradient(to top, #f3f7f9, rgba(#f3f7f9, 0));

        &._white {
            background: linear-gradient(to top, #fff, rgba(#fff, 0));
        }

        &._reverse {
            top: 0;
            bottom: auto;
            padding-top: 0;
            padding-bottom: 64px;
            background: linear-gradient(to bottom, #f3f7f9, rgba(#f3f7f9, 0));
        }

        &._white._reverse {
            background: linear-gradient(to bottom, #fff, rgba(#fff, 0));
        }
    }

    &:not(._show) {
        opacity: 0;
    }
}

._LOADERITEM {
    width: 22px;
    height: 22px;
    margin: auto;

    @include mob {
        width: 26px;
        height: 26px;
    }
}

._LOADER._FULL ._LOADERITEM {
    width: 100%;
    height: 100%;
}

._LOADERWAIT {
    z-index: 10;
    border-radius: 6px;
    animation: loaderWait 1s infinite ease-in-out;

    @keyframes loaderWait {
        0% {
            background: rgba($colorDark2, 0.072);
        }

        50% {
            background: rgba($colorDark2, 0.03);
        }

        100% {
            background: rgba($colorDark2, 0.072);
        }
    }
}

._ITEMS {
    position: relative;
    width: 100%;
    transition: $trans;
}

._ITEM {
    position: absolute;
    top: 0;
    left: 0;
    transition: $trans;

    &:not(._show) {
        opacity: 0;
    }
}

._parentForce ._ITEM,
._ITEMS._static ._ITEM {
    position: relative;

    &:not(._show) {
        display: none;
    }
}

._ANIMATE {
    transition: $trans;

    &:not(._show) {
        opacity: 0;
    }
}

._NOWRAP {
    white-space: nowrap;
}

._WRAP {
    word-break: break-all;
}

._DESKTOP {
    @include mob {
        display: none;
    }
}

._MOBILE {
    @include desktop {
        display: none;
    }
}

._TITLE {
    &._PUBLIC {
        color: $colorDark2;
        font-weight: 600;
        font-size: 5.2rem;
        line-height: 1.3;

        @include mob {
            font-size: 4rem;
        }

        &._MEDIUMSIZE {
            font-size: 4.4rem;

            @include mob {
                font-size: 3.6rem;
            }
        }

        & span {
            color: $colorDark2;
            font-weight: 300;
        }

        &._WHITE {
            color: #fff;
        }
    }
}

._TEXT {
    &._CENTER {
        text-align: center;
    }

    &._PUBLIC {
        color: $colorDark2;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.5;

        @include mob {
            font-size: 2rem;
        }

        &._WHITE {
            color: #fff;
        }
    }
}

._UPPER {
    text-transform: uppercase;
}

._POPUPBACK {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.2);

    &._pin,
    &._chats {
        background: none;
    }

    // @include blur(10px);

    &._filter {
        z-index: 1010;
    }

    &._calendar {
        z-index: 1010;
    }
}

._POPUPWRAPPER {
    &._pin,
    &._chats {
        pointer-events: none;
    }
}

@mixin hover() {
    @media (hover: hover) {
        @content;
    }
}

._INFOBLOCKWRAPPER {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin loader($color) {
    & .loader__itemInner {
        background: $color;
    }

    & .loader__icon {
        @include icon($color);
    }
}
