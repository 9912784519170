.pageHead {
    width: 100%;
    height: 100%;
    border-radius: 0 0 24px 24px;
    box-shadow: 0px 0px 3px 0px rgba(54, 60, 66, 0.20);
    padding: $padding;
    align-items: center;
    transition: $trans;

    @include theme('light') {
        background: #fff;
    }

    @include theme('dark') {
        background: #2B2F33;
    }

    &._empty {
        box-shadow: none;
        border-radius: 0;

        @include theme('light') {
            background: linear-gradient(to bottom, $colorBlueBack calc(100% - 16px), rgba($colorBlueBack, 0) 100%);
        }

        @include theme('dark') {
            background: linear-gradient(to bottom, #1C1F21 calc(100% - 16px), rgba(#1C1F21, 0) 100%);
        }
    }

    &__top {
        width: 100%;
        position: relative;
        justify-content: center;
        padding-top: 2px;
        margin-bottom: 18px;

        &._notMargin {
            margin-bottom: 0;
        }
    }

    &__back {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &__titles {
        width: 130px;
        height: 16px;
        position: relative;
    }

    &__title {
        max-width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 900;
        transition: $trans;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &__titles &__title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-50%, calc(-50% - 10px));
            }

            &._next {
                transform: translate(-50%, calc(-50% + 10px));
            }
        }
    }

    &__link {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        transition: $trans;

        &._dynamic:not(._show) {
            opacity: 0;
        }
    }

    &__balance {
        max-width: 100%;
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        letter-spacing: .4px;
        color: $colorSuccess;
        margin-bottom: 3px;
    }

    &__description {
        max-width: 100%;
        text-align: center;
        font-size: 16px;
        transition: $trans;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #B5BDC5;
        }
    }

    &__tabs {
        @extend %noscroll;
        width: 100%;
        height: 40px;
        position: relative;
        margin-top: 3px;

        &._scroll {
            overflow: hidden;
            overflow-x: auto;
            width: calc(100% + 40px);
            // margin-left: -20px;
            padding: 0 20px;
        }

        &Inner {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            position: relative;
            transition: $trans;
            white-space: nowrap;

            @include theme('light') {
                background: $colorBlueBack;
            }

            @include theme('dark') {
                background: #1C1F21;
            }
        }

        &._scroll &Inner {
            width: auto;
            display: inline-flex;
        }

        &._widthAuto &Inner {
            justify-content: space-between;
        }

        &Back {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            border-radius: 12px;
            background: $colorMain;
            transition: $trans;
        }

        &._widthAuto &Back {
            width: auto;
        }
    }

    &__tabs._widthAuto &__tab {
        width: auto;
        flex: 1 auto;

        &View {
            padding: 0 16px;
            min-width: 72px;
        }
    }

    &__tab {
        width: 50%;
        height: 100%;
        display: block;
        position: relative;
        z-index: 2;

        &Input {
            display: none;
        }

        &View {
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            line-height: 1.3;
            transition: $trans;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Input:checked~&View {
            color: #fff;
            font-weight: 600;
        }
    }
}