.windowPrompt {
    @extend %shadow;
    width: 100%;
    height: 100%;
    min-width: 210px;
    background: #fff;
    border-radius: 14px;
    padding: 16px;
    align-items: center;
    position: relative;
    z-index: 1;

    &._left {
        align-items: flex-start
    }

    &__head {
        width: 100%;
        align-items: center;
        margin-bottom: 12px;
    }

    &._left &__head {
        align-items: flex-start;
    }

    &__title {
        max-width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 1.3;
        font-weight: 900;
        color: $colorDark2;
    }

    &._left &__title {
        text-align: left;
    }

    &__description {
        max-width: 100%;
        font-size: 14px;
        line-height: 1.45;
        color: $colorDark2;
        text-align: center;
        margin-top: 6px;

        & b {
            font-weight: 600;
        }
    }

    &._left &__description {
        text-align: left;
    }

    &__area {
        width: 100%;
        height: 92px;
        margin-bottom: 8px;
    }

    &__actions {
        width: 100%;
        align-items: center;
    }

    &__action {
        &+& {
            margin-top: 10px;
        }

        &:not(._cancel) {
            width: 100%;
            height: 40px;
        }

        &._cancel {
            color: $colorAlert;
            font-size: 14px;
            font-weight: 500;
            transition: $trans;
            @include button(.95);
        }
    }
}