.appPopup {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: $trans;

    &._isDrag {
        transition: none;
    }

    &._order {
        @include theme('light') {
            background: #f4f4f4;
        }

        @include theme('dark') {
            background: #1C1F21;
        }
    }

    &__back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: $trans;
            pointer-events: visible;
        }

        &._hide::after {
            opacity: 0;
            pointer-events: none;
        }

        &._dark::after {
            background: rgba(#000, .2);
            @include blur(7px);
        }

        &Map {
            width: 100%;
            height: calc(100% - 80px);
            position: relative;
            z-index: 1;

            &Status {
                &._parent {
                    position: absolute;
                    top: 33px;
                    left: 20px;
                    z-index: 2;
                }
            }

            &Geo {
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 2;
                width: 50px;
                height: 50px;
                border-radius: 12px;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
                justify-content: center;
                align-items: center;

                @include theme('light') {
                    background: #fff;
                }

                @include theme('dark') {
                    background: #2F3439;
                }

                &Icon {
                    display: block;
                    width: 22px;
                    height: 22px;
                    margin: 1px 0 0 -1px;

                    @include theme('light') {
                        @include icon($colorMain);
                    }

                    @include theme('dark') {
                        @include icon(#B5BDC5);
                    }
                }
            }

            &Box {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__details {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        pointer-events: none;
    }

    &._details &__inner {
        @include theme('light') {
            box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.08);
        }

        @include theme('dark') {
            box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.2);
        }
    }

    &._order>&__inner {
        @include theme('dark') {
            // background: #1C1F21;
        }
    }

    &._orderDetails &__inner {
        @include theme('dark') {
            background: #1C1F21;
        }
    }

    &._appGps &__inner {
        @include theme('light') {
            background: #f7f7f7;
        }

        @include theme('dark') {
            background: #1C1F21;
        }
    }

    &._full &__inner {
        height: 100%;
        border-radius: 0;

        @include theme('light') {
            background: #f7f7f7;
        }

        @include theme('dark') {
            background: #1C1F21;
        }

        &Box {
            height: 100%;
            justify-content: center;
        }
    }

    &__inner {
        width: 100%;
        border-radius: 24px 24px 0 0;
        margin-top: auto;
        position: relative;
        pointer-events: visible;
        z-index: 1;

        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: #2B2F33;
        }

        &:not(._isDrag) {
            transition: $trans;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 0);
            width: 52px;
            height: 4px;
            border-radius: 360px;
            margin-bottom: 8px;
            background: rgba(#fff, .28);
            z-index: 2;
            transition: $trans;
        }

        &Box {
            @extend %noscroll;
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            transition: $trans;
            position: relative;
            border-radius: 24px 24px 0 0;
            z-index: 1;
            will-change: transform;
            overscroll-behavior: none;
        }
    }

    &._isDisabled &__inner::before {
        display: none;
    }

    &._withOutLine &__inner::before {
        opacity: 0;
    }

    &._order &__inner::before {
        background: rgba($colorDark2, .28);
    }

    .body__appPopup:not(._show):not(._full) &__inner,
    .body__page:not(._show):not(._order):not(._openOrderChat) &__inner {
        transform: translate(0, calc(100% + 12px)) !important;
    }

    .body__appPopup:not(._show):not(._full) &._application &__icon {
        transform: translate(-50%, 0);
    }

    &__loader {
        z-index: 10;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: $padding;
        padding-top: 26px;
        padding-bottom: 32px;
        align-items: center;
        position: relative;
        transition: $trans;
    }

    &._notification &__content {
        align-items: flex-start;
    }

    &._waitInit:not(._init) &__content {
        opacity: 0;
    }

    &._withFixFoot &__content {
        padding-bottom: 0;
    }

    &._order &__content {
        padding: 0;
    }

    &__icon {
        width: 60px;
        height: 60px;
        object-fit: contain;
        margin-bottom: 6px;
    }

    &._changeOrder &__icon {
        width: 40px;
        height: 40px;
        margin: 8px 0 10px 0;
    }

    &._signContract &__icon,
    &._signPay &__icon {
        width: 46px;
        height: 46px;
        margin: 8px 0 10px 0;
        @include icon(#B5BDC5);
    }

    &._webPush &__icon {
        width: 46px;
        height: 46px;
    }

    &._networkDisconnect &__icon {
        width: 60px;
        height: 40px;
        margin: 8px 0 12px 0;
    }

    &._fns &__icon {
        width: 66px;
        height: 66px;
        border-radius: 13px;
        box-shadow: 0 0 3px rgba(#000, .2);
        margin: 8px 0 12px 0;

        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: #1C1F21;
        }

        &Logo {
            width: 56px;
            height: 56px;
            object-fit: contain;
            margin: auto;
            display: block;
        }
    }

    &._application &__icon {
        width: 72px;
        height: 72px;
        border-radius: 16px;
        box-shadow: 0 0 3px rgba(#000, .2);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        transition: $trans;

        @include theme('light') {
            background: #fff;
            @include icon($colorDark2);
        }

        @include theme('dark') {
            background: #1C1F21;
            @include icon(#fff);
        }
    }

    &._joinCorporation &__icon {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        overflow: hidden;
        margin-bottom: 12px;

        @include theme('light') {
            background: $colorBlueBack;
        }

        @include theme('dark') {
            background: #1C1F21;
        }

        & .avatar__item {
            font-size: 32px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }
    }

    &._taxAbout &__icon {
        width: 72px;
        height: 58px;
    }

    &._serverNotAvailable &__icon {
        width: 48px;
        height: 48px;
        margin: 0 0 12px 0;
        @include icon(#FF7F28);
    }

    &._wallet &__icon {
        width: 40px;
        height: 40px;
        margin: 0 0 12px 0;
    }

    &__boxIcon {
        width: 64px;
        height: 64px;
        border-radius: 16px;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.10);
        margin-bottom: 12px;

        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: #2F3439;
        }

        &Item {
            width: 36px;
            height: 36px;
            display: block;
        }
    }

    &__notificationHead {
        width: 100%;
        margin-bottom: 10px;

        &Title {
            @extend %title;
            margin-right: 10px;
            display: inline-block;
        }

        &Date {
            font-size: 16px;
            color: #B5BDC5;
            line-height: 1.3;
            display: inline-block;
            position: relative;
            padding-left: 16px;

            &::before {
                content: "•";
                font-size: 16px;
                color: #B5BDC5;
                line-height: 1.3;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &__title {
        @extend %title;
        max-width: 320px;
        text-align: center;
        margin-bottom: 10px;
    }

    &._application &__title {
        margin-bottom: 12px;
        padding-top: 24px;
    }

    &._balance &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 1.2;
        color: $colorSuccess;
        margin-bottom: 4px;

        &._minus {
            color: $colorAlert;
        }
    }

    &._notification &__title {
        text-align: left;
    }

    &__description {
        @extend %text;
        max-width: 320px;
        text-align: center;
        margin-bottom: 20px;

        &._bold {
            font-weight: 600;

            @include theme('light') {
                // color: #000;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Link {
            font-weight: 600;
            border-bottom: 1px solid transparent !important;

            @include theme('light') {
                border-color: rgba(#B5BDC5, .5) !important;
            }

            @include theme('dark') {
                border-color: rgba(#fff, .5) !important;
            }
        }
    }

    &__description+&__description {
        margin-top: -10px;
    }

    &._notification &__description {
        text-align: left;
        margin-bottom: 0;

        &:last-child {
            margin-bottom: -4px;
        }
    }

    &._notification &__description+&__description {
        margin-top: 10px;
    }

    &._balance &__description {
        font-size: 20px;
        line-height: 1.3;

        & b {
            font-weight: 600;
        }
    }

    &__button {
        width: 100%;
        height: $fieldHeight;

        &._empty {
            height: auto;
        }

        &._margin {
            margin-top: 26px;
        }

        &+& {
            margin-top: 10px;
        }

        &+&._margin {
            margin-top: 16px;
        }

        & .button__icon {
            @include theme('light') {
                @include icon($colorMain);
            }

            @include theme('dark') {
                @include icon(#AEC2FF);
            }
        }
    }

    &__link {
        margin-top: 17px;
    }

    &__balance {
        width: 100%;
        margin-bottom: 20px;

        &Row {
            width: 100%;
            justify-content: space-between;
            padding-bottom: 7px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;

                @include theme('light') {
                    background: linear-gradient(to right, #3E454C, #3E454C 50%, transparent 50%, transparent);
                    background-repeat: repeat;
                    background-size: 10px 1px;
                    opacity: 0.7;
                }

                @include theme('dark') {
                    background: linear-gradient(to right, #fff, #fff 50%, transparent 50%, transparent);
                    background-repeat: repeat;
                    background-size: 10px 1px;
                    opacity: 0.5;
                }
            }

            &+& {
                margin-top: 11px;
            }
        }

        &Col {
            font-size: 16px;
            font-weight: 300;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            &._value {
                font-weight: 500;
            }
        }
    }

    &__top {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 14px;

        &Name {
            font-size: 16px;
            line-height: 1.3;
            font-weight: 600;
            transition: $trans;

            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: #AEC2FF;
            }

            &:not(._show) {
                opacity: 0;
                pointer-events: none;
            }
        }

        &Back {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            z-index: 2;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
                pointer-events: none;
            }
        }

        &Pagination {
            margin-left: auto;
        }
    }

    &__blocks {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        align-items: center;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;

            &._next {
                transform: translate(64px, 0);
            }

            &._prev {
                transform: translate(-64px, 0);
            }
        }
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__files {
        width: 100%;
        margin-bottom: 20px;
        justify-content: center;
    }

    &__file {
        width: 154px;
        height: 154px;
        display: block;

        &+& {
            margin-left: 12px;
        }

        &Input {
            display: none;
        }

        &View {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            border-radius: 18px;
            border: 1px solid transparent;
            transition: $trans;
        }

        &._upload:not(._isLoading) &View {
            @include theme('light') {
                border-color: $colorMain;
            }

            @include theme('dark') {
                border-color: #AEC2FF;
            }
        }

        &Border {
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: -1;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            transition: $trans;
        }

        &._upload:not(._isLoading) &Border {
            opacity: 0;
        }

        &._isLoading &Info {
            opacity: 0;
            pointer-events: none;
        }

        &Info {
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            transition: $trans;

            &Icon {
                width: 84px;
                height: 88px;
                margin-bottom: -8px;
            }

            &Name {
                max-width: 60%;
                text-align: center;
                font-size: 16px;
                line-height: 1.2;
                font-weight: 600;
                padding-bottom: 5px;

                @include theme('light') {
                    color: $colorMain;
                }

                @include theme('dark') {
                    color: #AEC2FF;
                }
            }
        }

        &Preview {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: $trans;
            border-radius: 17px;
            overflow: hidden;
            z-index: 1;

            &:not(._show) {
                opacity: 0;
                pointer-events: none;
            }

            &Image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &._isLoading &Preview {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__point {
        width: 100%;
        margin-bottom: 14px;
    }

    &__foot {
        width: 100%;
        z-index: 10;
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        align-items: center;
        padding-bottom: 20px;

        @include theme('light') {
            background: linear-gradient(to bottom, rgba(#fff, 0), #fff);
        }

        @include theme('dark') {
            background: linear-gradient(to bottom, rgba(#2B2F33, 0), #2B2F33);
        }
    }

    &__fields {
        width: 100%;
        margin: 12px 0 16px 0;
    }

    &__field {
        width: 100%;
        height: 56px;
        position: relative;

        @include theme('dark') {
            & .field {
                background: #1C1F21 !important;
            }
        }

        &+& {
            margin-top: 12px;
        }

        &Loader {
            z-index: 10;
            border-radius: 14px;

            @include theme('light') {
                background: #f5f7fa;
            }

            @include theme('dark') {
                background: #1C1F21;
            }
        }

        &Drop {
            @extend %noscroll;
            position: absolute;
            top: 100%;
            width: 100%;
            margin-top: 6px;
            z-index: 2;
            border-radius: 14px;
            padding: 6px 0;
            overflow-y: auto;
            max-height: 150px;

            @include theme('light') {
                background: #f5f7fa;
            }

            @include theme('dark') {
                background: #1C1F21;
            }

            &Item {
                width: 100%;
                font-size: 16px;
                line-height: 1.4;
                font-weight: 500;
                padding: 6px 16px;

                @include theme('light') {
                    color: $colorDark;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }
        }
    }

    &__list {
        width: 100%;

        &Inner {
            width: 100%;
            border-radius: 14px;
            padding: 16px 20px;

            @include theme('light') {
                background: $colorBlueBack;
            }

            @include theme('dark') {
                background: #25292C;
            }
        }

        &._withButton &Inner {
            border-radius: 14px 14px 0 0;
        }

        &+& {
            margin-top: 10px;
        }

        &Item {
            justify-content: space-between;

            &+& {
                margin-top: 12px;
                padding-top: 12px;
                border-top: 1px solid transparent;

                @include theme('light') {
                    border-color: rgba(#C5CCD3, .72);
                }

                @include theme('dark') {
                    border-color: #1C1F21;
                }
            }

            &Value {
                font-size: 15px;
                line-height: 1.3;
                text-align: right;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }

                &._support {
                    font-weight: 600;
                    color: #949BA2;
                    text-align: left;
                    padding-right: 20px;
                }
            }
        }

        &Button {
            margin-top: 2px;
            width: 100%;
            height: 56px;

            & .button {
                border-radius: 0 0 14px 14px !important;
            }
        }
    }

    &__list+&__button {
        margin-top: 16px;
    }

    &__errors {
        width: 100%;
        transition: $trans;

        &._parent:not(._empty) {
            margin-top: 10px;

            &._bottom {
                margin-bottom: 14px;
                margin-top: 0;
            }
        }
    }

    &__error {
        width: 100%;
        font-size: 15px;
        color: $colorAlert;
        line-height: 1.4;
        text-align: center;
    }

    &__checkboxes {
        width: 100%;
        margin-bottom: 16px;
    }

    &__checkbox {
        width: 100%;
        height: 56px;
        display: block;

        &+& {
            margin-top: 10px;
        }

        &Input {
            display: none;
        }

        &Inner {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            padding: 12px 40px 12px 20px;
            align-items: center;
            position: relative;
            font-size: 16px;
            line-height: 1.3;
            border: 1px solid transparent;
            transition: $trans;

            @include theme('light') {
                background: $colorBlueBack;
                color: $colorDark2;
            }

            @include theme('dark') {
                background: #1C1F21;
                color: #fff;
            }
        }

        &Input:checked~&Inner {
            border-color: $colorSuccess;
        }

        &Point {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 20px;
            transition: $trans;

            @include theme('light') {
                background: #DFE5EB;
            }

            @include theme('dark') {
                background: $colorDarkTheme2;
            }

            &Icon {
                display: block;
                width: 18px;
                height: 18px;
                margin: auto;
                transform: translate(0, 1px);
                @include icon(#fff);
                transition: $trans;
                opacity: 0;
            }
        }

        &Input:checked~&Inner &Point {
            background: $colorSuccess;

            &Icon {
                opacity: 1;
            }
        }
    }

    &__taxAmount {
        height: 77px;
        margin-bottom: 16px;
        position: relative;

        &Item {
            position: relative;
            transform-origin: center;
            z-index: 2;
            padding: 15px 40px 18px 18px;
            border-radius: 16px;
            border: 2px solid transparent;
            transform: translate(10px, 0) rotate(-8deg);
            min-width: 138px;
            height: 100%;

            @include theme('light') {
                background: #F0F4FF;
                border-color: #fff;
            }

            @include theme('dark') {
                background: #1C1F21;
                border-color: #2B2F33;
            }

            &._back {
                width: 100%;
                position: absolute;
                z-index: 1;
                transform: translate(-10px, 0) rotate(8deg);

                @include theme('light') {
                    background: #D3DEFF;
                }

                @include theme('dark') {
                    background: rgba(#1C1F21, .72);
                }
            }

            &Info {
                position: absolute;
                top: 9px;
                right: 9px;
                width: 16px;
                height: 16px;
                z-index: 2;

                @include theme('light') {
                    @include icon(rgba($colorMain, .2));
                }

                @include theme('dark') {
                    @include icon(#AEC2FF);
                }
            }

            &Title {
                max-width: 100%;
                font-size: 21px;
                font-weight: 600;
                line-height: 1.3;
                margin-bottom: 2px;
                white-space: nowrap;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }

            &Description {
                max-width: 100%;
                white-space: nowrap;
                font-size: 14px;
                font-weight: 300;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }
        }
    }

    &__walletCurrent {
        background: #F24483;
        border-radius: 360px;
        font-size: 13px;
        font-weight: 700;
        color: #fff;
        padding: 7px 14px 8px 14px;
        margin-bottom: 12px;
    }
}