.pdfUserProxy {
    width: 100%;
    &__title {
        width: 100%;
        font-size: 20px;
        line-height: 1.3;
        font-weight: 900;
        color: $colorDark2;
        margin-bottom: 20px;
        text-align: center;
    }
    &__block {
        width: 100%;
        &+& {
            margin-top: 24px;
        }
    }
    &__text {
        max-width: 100%;
        font-size: 14px;
        line-height: 1.3;
        color: $colorDark2;
        text-align: justify;
        position: relative;
        &._bold {
            font-weight: 600;
        }
        & i {
            border-bottom: 1px solid rgba($colorDark2, .64);
        }
        &+& {
            margin-top: 12px;
        }
    }
}