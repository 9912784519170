.v2cursorEmodziList {
    position: absolute;
    z-index: 10001;
    pointer-events: none;

    &__item {
        position: absolute;
        width: 240px;
        height: 200px;
        background: #fff;
        border-radius: 16px;
        transition: $trans;
        pointer-events: visible;

        &Back {
            position: absolute;
            z-index: -3;
            width: 100%;
            height: 100%;
            border-radius: 16px;
            box-shadow: 0 0 2px 0 #d8dce4;
        }

        &:not(._show) {
            opacity: 0;

            &._bottom {
                transform: translate(0, 3px);
            }

            &._top {
                transform: translate(0, -3px);
            }
        }

        &::before {
            position: absolute;
            z-index: -2;
            width: 12px;
            height: 12px;
            background: #fff;
            box-shadow: 0 0 2px 0 #d8dce4;
            content: "";
        }

        &::after {
            position: absolute;
            left: 50%;
            z-index: -1;
            width: 20px;
            height: 16px;
            background: #fff;
            transform: translate(-50%, 0);
            content: "";
        }

        &._bottom::before {
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &._bottom::after {
            top: 0;
        }

        &._top::before {
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%) rotate(45deg);
        }

        &._top::after {
            bottom: 0;
        }
    }
}
