.headPage {
    width: 100%;
    height: 100%;
    transition: $trans;

    @include theme('light') {
        background: $colorBlueBack;
    }

    @include theme('dark') {
        background: #1C1F21;
    }

    &__head {
        width: 100%;
        position: relative;
        z-index: 2;
    }

    &._empty &__inner._pages &__innerBox:not(._notPadding) {
        padding-top: 20px;
    }

    &__inner {
        width: 100%;
        margin-top: -20px;
        position: relative;
        z-index: 1;

        &Box {
            @extend %page;
            padding-top: calc(20px + #{$padding});

            &._noScroll {
                overflow: visible;
                padding: 0;
            }

            &._notPadding {
                padding: 0;
            }

            &Content {
                @extend %page;
                padding-top: calc(20px + #{$padding});
            }

            &._notPadding &Content {
                padding: 0;
            }
        }

        &._pages &Box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: $trans;

            &:not(._notPadding):not(._noScroll) {
                padding-top: 40px;
            }

            &:not(._show) {
                opacity: 0;

                &._prev {
                    transform: translate(-64px, 0);
                }

                &._next {
                    transform: translate(64px, 0);
                }
            }
        }
    }

    &__empty {
        @extend ._loader;
        z-index: 9;
    }
}