.appOrderShortDetails {
    width: 100%;
    height: 100%;
    padding: 0 $padding;

    &__head {
        width: 100%;
        height: 58px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        align-items: center;
        position: relative;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }

        &::after {
            content: "";
            border: 3px solid transparent;
            transform: translate(0, -1px) rotate(45deg);
            transition: $trans;
            margin-left: auto;

            @include theme('light') {
                border-right-color: $colorDark2;
                border-bottom-color: $colorDark2;
            }

            @include theme('dark') {
                border-right-color: #fff;
                border-bottom-color: #fff;
            }
        }
    }

    &._full &__head {
        &::after {
            transform: translate(0, 2px) rotate(-135deg);

            @include theme('light') {
                border-right-color: $colorMain;
                border-bottom-color: $colorMain;
            }

            @include theme('dark') {
                border-right-color: #F5F7FA;
                border-bottom-color: #F5F7FA;
            }
        }
    }

    &__content {
        width: 100%;
    }

    &__points {
        width: 100%;
        counter-reset: pointCounter;
    }

    &__point {
        width: 100%;
        padding: 16px 12px;
        position: relative;
        counter-increment: pointCounter;
        transition: $trans;

        @include theme('light') {
            background: #F5F7FA;
        }

        @include theme('dark') {
            background: #3E454C;
        }

        &:not(._current) {
            opacity: .72;
        }

        &._current {
            @include theme('light') {
                background: #EAF2FF;
            }

            @include theme('dark') {
                background: rgba($colorMain, .2);
            }
        }

        &:first-child {
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
        }

        &:last-child {
            border-bottom-left-radius: 14px;
            border-bottom-right-radius: 14px;
        }

        &+& {
            margin-top: 3px;
        }

        &._prev &Inner::before {
            @include theme('light') {
                border-color: $colorSuccess;
                color: $colorSuccess;
            }

            @include theme('dark') {
                border-color: $colorSuccess;
                color: $colorSuccess;
            }
        }

        &._current &Inner::before {
            @include theme('light') {
                border-color: $colorMain;
                color: $colorMain;
            }

            @include theme('dark') {
                border-color: #AEC2FF;
                color: #AEC2FF;
            }
        }

        &Inner {
            width: 100%;
            position: relative;
            padding-left: 48px;

            &::before {
                display: flex;
                flex-direction: column;
                content: counter(pointCounter);
                position: absolute;
                top: 0;
                left: 0;
                width: 36px;
                height: 36px;
                border: 1px solid transparent;
                border-radius: 50%;
                font-size: 20px;
                font-weight: 600;
                justify-content: center;
                align-items: center;
                z-index: 2;

                @include theme('light') {
                    border-color: #3E454C;
                    color: #3E454C;
                }

                @include theme('dark') {
                    border-color: #B5BDC5;
                    color: #B5BDC5;
                }
            }
        }

        &Address {
            width: 100%;
            height: 20px;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 3px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Description {
            max-width: 100%;
            font-size: 12px;
            line-height: 19px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            & span {
                font-weight: 600;
                transition: $trans;
                color: #B5BDC5;
            }
        }

        &._prev &Description span {
            @include theme('light') {
                color: $colorSuccess;
            }

            @include theme('dark') {
                color: $colorSuccess;
            }
        }

        &._current &Description span {
            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: #AEC2FF;
            }
        }

        &Other {
            width: 100%;
            margin-top: 8px;
            margin-left: -4px;
        }

        &Comment {
            width: 100%;
            border-radius: 7px;
            padding: 2px 10px 2px 23px;
            position: relative;

            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: #2B2F33;
            }

            &Icon {
                position: absolute;
                top: 5px;
                left: 6px;
                width: 11px;
                height: 11px;
                z-index: 2;
            }

            &Inner {
                font-size: 13px;
                line-height: 1.4;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }
        }
    }

    &__info {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 18px;
        padding-bottom: 12px;

        &Item {
            margin-bottom: 8px;
            padding: 12px 14px 10px 14px;
            border-radius: 12px;
            width: calc(calc(100% / 3) - calc(16px / 3));

            @include theme('light') {
                background: #F5F7FA;
            }

            @include theme('dark') {
                background: #3E454C;
            }

            &._services {
                width: 100%;
                padding-top: 10px;
                padding-bottom: 12px;
            }

            &Value {
                max-width: 100%;
                font-size: 20px;
                line-height: 1.15;
                font-weight: 900;
                margin-bottom: -1px;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }

            &._services &Value {
                margin-bottom: 4px;
            }

            &Support {
                max-width: 100%;
                font-size: 14px;
                line-height: 1.2;
                color: $colorDark2;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #B5BDC5;
                }
            }
        }
    }
}