﻿%reset {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-family: PTRoot;
    font-style: normal;
    line-height: 1;
    letter-spacing: unset;
    text-decoration: none;
    list-style-type: none;
    background: none;
    border: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    appearance: none;
    -webkit-font-smoothing: antialiased;
}

@mixin mediaMin($w) {
    @media screen and (min-width: $w) {
        @content;
    }
}

@mixin media($w1, $w2) {
    @media screen and (min-width: $w1) and (max-width: $w2) {
        @content;
    }
}

* {
    @extend %reset;
}

html {
    width: 100%;
    font-size: calc(100vw / 179);
    transition: $trans;

    &[data-theme="dark"] {
        background: $colorDarkTheme;
    }

    &._hidden {
        overflow: hidden;
    }

    @include mediaMin(1920px) {
        // font-size: 11px;
    }

    @include media(0, $mediaM) {
        font-size: calc(100vw / 52);
    }
}

@mixin bg($url, $size, $pos) {
    background-image: url($url);
    background-repeat: no-repeat;
    background-position: $pos;
    background-size: $size;
}

@mixin theme($theme) {
    .body__content._#{$theme} & {
        @content;
    }
}

@mixin icon($color, $colorFill: null, $colorStroke: null, $colorAll: null) {
    @if $colorFill {
        & .icon__fill {
            fill: $colorFill;
        }
    }

    @if $colorStroke {
        & .icon__stroke {
            stroke: $colorStroke;
        }
    }

    @if $colorAll {
        & .icon__all {
            fill: $colorAll;
            stroke: $colorAll;
        }
    }

    @if $colorFill==null and $colorStroke==null and $colorAll==null {
        & .icon__fill {
            fill: $color;
        }

        & .icon__stroke {
            stroke: $color;
        }
    }
}

@mixin blur($val) {
    & {
        backdrop-filter: saturate(180%) blur($val);

        //
        -webkit-backdrop-filter: saturate(180%) blur($val);
    }
}

@mixin forType($type, $theme: "") {
    @if $theme {
        .body__content._#{$type}._#{$theme} & {
            @content;
        }
    }

    @if $theme== "" {
        .body__content._#{$type} & {
            @content;
        }
    }
}

@mixin button($value: 0.96, $centerY: false) {
    will-change: transform;

    & {
        transition: $trans;
    }

    &:active {
        @if $centerY {
            transform: translate(0, -50%) scale(#{$value});
        }

 @else {
            transform: scale(#{$value});
        }
    }
}

@mixin mob {
    @include media(0, $mediaM) {
        @content;
    }
}

@mixin desktop {
    @include mediaMin($mediaM + 1px) {
        @content;
    }
}

%MEDIA {
    &._mobile {
        @include mediaMin($mediaM + 1px) {
            display: none;
        }
    }

    &._desktop {
        @include media(0, $mediaM) {
            display: none;
        }
    }
}

br {
    @extend %MEDIA;
}

%section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

%col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

%noscroll {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
}

._desktop {
    @include media(0, $mediaM) {
        display: none;
    }
}

._mobile {
    @include mediaMin($mediaM + 1px) {
        display: none;
    }
}

@mixin close-circle($transform: null) {
    & {
        transition: $trans;
        will-change: transform;

        @include theme("dark") {
            @include icon($colorDarkTheme);
        }

        @include theme("light") {
            @include icon(#e1e1e1);
        }

        @include mediaMin($mediaM + 1px) {
            &:hover {
                transform: $transform rotate(90deg);

                @include theme("light") {
                    @include icon($colorAlert);
                }

                @include theme("dark") {
                    @include icon($colorAlert);
                }
            }
        }
    }
}

@mixin circle-add() {
    @include theme("light") {
        @include icon($colorMain);
    }

    @include theme("dark") {
        @include icon($colorMainTheme);

        & path {
            fill: $colorDarkTheme;
            stroke: $colorDarkTheme;
        }
    }
}

._noWrap {
    white-space: nowrap;
}

._wrap {
    word-break: break-all;
}

.ymaps-2-1-79-map-copyrights-promo,
.ymaps-2-1-79-copyright_logo_no {
    display: none !important;
}

%backBorderDashed {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}

%shadow {
    @include theme("light") {
        box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
    }

    @include theme("dark") {
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);

        @include media(0, $mediaM) {
            box-shadow: 0 0 4px 0 rgb(0 0 0 / 60%);
        }
    }

    @include forType("crm", "light") {
        box-shadow: 0 0 24px rgb(62 69 76 / 12%);
    }
}

@mixin popupBack() {
    & {
        @include theme("dark") {
            background: rgba($colorDarkTheme, 0.97);
        }

        @include theme("light") {
            background: rgba(#fff, 0.95);
        }
    }
}

@mixin mobilePopup($parent) {
    max-height: calc(100vh - 100px);

    @include media(0, $mediaM) {
        position: relative;
        width: 100%;
        min-height: 180px;
        margin-top: auto;
        border-radius: 24px 24px 0 0;
        transform: translate3d(0, calc(100% + var(--popupOffsetTablet)), 0);
        transition: $transLong;
        will-change: transform;

        #{$parent}._show & {
            transform: translate3d(0, 0, 0);
        }

        @include theme("dark") {
            background: $colorDarkThemeBorder;
            box-shadow: 0 0 4px 0 rgb(0 0 0 / 30%);
        }

        @include theme("light") {
            background: #fff;
            box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
        }

        &::before {
            position: absolute;
            bottom: 100%;
            left: 50%;
            z-index: 2;
            width: 48px;
            height: 4px;
            margin-bottom: 12px;
            border-radius: 360px;
            transform: translate(-50%, 0);
            opacity: 0;
            transition: $trans;
            content: "";

            @include theme("light") {
                background: #ddd;
            }

            @include theme("dark") {
                background: rgba(#fff, 0.32);
            }
        }

        #{$parent}._show &::before {
            opacity: 1;
        }
    }

    @include media(440px, $mediaM) {
        margin-bottom: 20px;
        border-radius: 24px;
    }
}

[class*="ymaps-2"][class*="-ground-pane"] {
    @include theme("dark") {
        // -ms-filter: invert(100%);
        // -webkit-filter: invert(100%);
        // -moz-filter: invert(100%);
        // -o-filter: invert(100%);
        // filter: invert(100%);
        filter: grayscale(100%) !important;
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    }
}

@mixin browser($name) {
    .body._#{$name} & {
        @content;
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    9% {
        transform: translate(-1px, -1px) rotate(-1deg);
    }

    18% {
        transform: translate(-1px, 0) rotate(1deg);
    }

    27% {
        transform: translate(0, 1px) rotate(0deg);
    }

    36% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    45% {
        transform: translate(-1px, 1px) rotate(-1deg);
    }

    54% {
        transform: translate(-1px, 1px) rotate(0deg);
    }

    63% {
        transform: translate(1px, 1px) rotate(-1deg);
    }

    72% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    81% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    90% {
        transform: translate(1px, -1px) rotate(-1deg);
    }

    100% {
        transform: translate(1px, 1px) rotate(0deg);
    }
}

%editorContent {
    color: $colorDark2;

    & * {
        line-height: 1.4;
    }

    & b {
        font-weight: 600;

        & * {
            font-weight: 600;
        }
    }

    & i {
        font-style: italic;

        & * {
            font-style: italic;
        }
    }

    & ._alignLeft {
        text-align: left;
    }

    & ._alignCenter {
        text-align: center;
    }

    & ._alignRight {
        text-align: right;
    }

    & ._alignJustify {
        text-align: justify;
    }

    & ._underline {
        text-decoration: underline;
    }

    & ._uppercase {
        text-transform: uppercase;
    }

    & ._quote {
        padding: 16px;
        background: rgba($colorMain, 0.12);
        border-radius: 14px;
    }

    & ._ancor {
        cursor: pointer;
    }

    & ._indent {
        padding-left: 20px;
    }

    & p {
        line-height: 1.4;
    }

    & p + p {
        // margin-top: 20px;
    }

    & ol,
    & ul {
        padding-left: 0;
    }

    & ol {
        counter-reset: listCounter;
    }

    & ol > li {
        counter-increment: listCounter;
    }

    & ._listPoint {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1.4;
    }

    & li {
        position: relative;
        padding-left: 26px;
        line-height: 1.4;

        &._remove::before {
            content: none !important;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            line-height: 1.4;
            content: "•";
        }

        & > ul,
        & > ol {
            margin-top: 12px;
        }
    }

    & ol > li::before {
        content: counter(listCounter) ".";
    }

    & li + li {
        margin-top: 12px;
    }

    & img {
        position: relative;
        z-index: 1;
        display: block;
        max-width: 100%;
        overflow: hidden;
        border: 1px solid $colorBlue;
        border-radius: 16px;
    }

    & table {
        width: 100%;
        border: 1px solid rgba($colorDark2, 0.24);
        border-spacing: 0;

        & tr {
            display: table-row;
        }

        & td {
            display: table-cell;
            padding: 10px;
            vertical-align: top;

            &:empty {
                height: 41px;
            }

            &:not(:last-child) {
                border-right: 1px solid rgba($colorDark2, 0.24);
            }
        }

        & tr:not(:last-child) td {
            border-bottom: 1px solid rgba($colorDark2, 0.24);
        }
    }

    & a {
        color: $colorMain;
        border-bottom: 1px solid $colorMain;
    }

    & ._DOC a {
        display: inline-block;
        padding: 6px 10px;
        background: $colorBlueBack;
        border: 1px solid rgba($colorMain, 0.1);
        border-radius: 8px;
    }
}

%static {
    position: relative;
}

%staticItem {
    position: absolute;
    top: 0;
    left: 0;
    transition: $trans;

    &:not(._show) {
        opacity: 0;
    }
}

%static._static %staticItem {
    position: relative;

    &:not(._show) {
        display: none;
    }
}

%publicInner {
    @extend %col;

    width: $widthPublicInner;

    @include media(0, $mediaM) {
        width: 92vw;
    }
}

@mixin inPublic() {
    .public & {
        @content;
    }
}
