.avatar {
    width: 100%;
    height: 100%;
    position: relative;
    transition: $trans;

    &._static {
        width: auto;
        height: auto;
    }

    &._circle {
        background: $colorBlueBack;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        overflow: hidden;
        border: 1px solid $colorBlue;

        &._white {
            background: #fff;
        }
    }

    &._static &__item {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0, 0);

        &:not(._show) {
            display: none;
        }
    }

    &__item {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: 500;
        color: $colorDark2;
        transition: $trans;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        &._empty {
            width: 0 !important;
            height: 0 !important;
        }

        &:not(._show) {
            opacity: 0;
        }
    }

    &__icon {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__item._contain &__icon {
        object-fit: contain;
    }

    &__item._svg &__icon {
        &._parent {
            transform: scale(.8);
        }
    }
}