.button {
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 14px 16px 15px;
    overflow: hidden;
    text-align: center;
    background: $colorMain;
    border-radius: 12px;
    transition: $trans;

    @include button;

    @include forType("app") {
        border: 0;
        border-radius: 12px;
        box-shadow: 0 2px 10px 0 rgb(62 106 237 / 64%);

        &._notShadow {
            box-shadow: none;
        }

        &._blue {
            box-shadow: none;
        }
    }

    @include media(0, $mediaM) {
        padding: 12px 16px;
        border-radius: 16px;
    }

    @include theme("light") {
        border-color: $colorLight;
    }

    @include theme("dark") {
        border-color: $colorDarkThemeBorder;
    }

    @include mediaMin($mediaM + 1px) {
        &:hover {
            background: $colorMainDark;
        }
    }

    &._crm {
        padding: 8px 14px 9px;
        border: 0;
        border-radius: 9px;
        box-shadow: none;
    }

    &._mainNotBorder {
        border: 0;
        box-shadow: 0 2px 4px 0 rgb(62 106 237 / 56%);
    }

    &._notShadow {
        border: 0;
        box-shadow: none;
    }

    &._shadow {
        box-shadow: 0 2px 4px 0 rgb(62 106 237 / 56%);

        &._public {
            box-shadow: 0 0.2rem 0.4rem 0 rgb(62 106 237 / 56%);
        }
    }

    &._mainEmpty {
        border-width: 1px;
        box-shadow: none;

        @include theme("light") {
            background: none;
            border-color: $colorMain;
        }
    }

    &._blue {
        border: none;
        box-shadow: none;

        @include theme("light") {
            background: #eef2ff;
        }

        @include theme("dark") {
            background: #1c1f21;
        }

        @include mediaMin($mediaM + 1px) {
            &:hover {
                background: $colorBlueActive;
            }
        }
    }

    &._white {
        background: #fff;
        border: none;
        box-shadow: none;

        @include mediaMin($mediaM + 1px) {
            &:hover {
                background: #fff;
            }
        }
    }

    &._alert {
        padding: 0 !important;
        background: none;
        border: 0;
        box-shadow: none;
    }

    &._cancel {
        background: #f25130;
        border: 0;
        box-shadow: 0 2px 8px 0 rgb(242 81 48 / 56%);
    }

    &._alertLight {
        @include theme("light") {
            color: $colorAlert;
            background: #fff2ef;

            & .loader__itemInner {
                background: $colorDark2;
            }
        }

        @include theme("dark") {
            color: $colorAlert;
            background: #43302d;

            & .loader__itemInner {
                background: #fff;
            }
        }
    }

    &._cancelEmpty {
        background: none;
        border: 1px solid transparent;
        box-shadow: none;

        @include theme("light") {
            border-color: $colorAlert;
        }
    }

    &._disabled {
        box-shadow: none;

        @include theme("light") {
            background: $colorGreyLight;
        }

        @include theme("dark") {
            background: $colorDarkTheme;
        }

        @include forType("crm", "light") {
            background: $colorBlueBack;
        }

        @include forType("app") {
            background: $colorMain !important;
            opacity: 0.32;
        }

        &._disabledWhite {
            @include theme("light") {
                background: #fff;
            }

            @include forType("crm", "light") {
                background: #fff;
            }
        }

        &._disabledDark {
            @include theme("light") {
                background: $colorGrey;
            }

            @include forType("crm", "light") {
                background: $colorGreyDark;
            }
        }
    }

    &._purple {
        background: #f24483;
        box-shadow: none !important;
    }

    &._bigSizeRadius {
        border-radius: 14px;
    }

    &._extraMinSize {
        padding: 6px 16px 7px;
        border-width: 1px;
        border-radius: 6px;
    }

    &._minSize {
        padding: 6px 13px 7px;
        border-width: 1px;
        border-radius: 7px;
    }

    &._mediumSize {
        padding: 6px 14px 7px;
        border-width: 1px;
        border-radius: 9px;

        @include media(0, $mediaM) {
            padding: 5px 14px 6px;
        }

        &._bigPaddings {
            padding-right: 18px;
            padding-left: 18px;
        }
    }

    &._medium2Size {
        padding: 13px 24px 14px;
        border-radius: 11px;

        @include media(0, $mediaM) {
            border-radius: 12px;
        }
    }

    &._medium3Size {
        padding: 13px 24px 14px;
        border-radius: 8px;
    }

    &._normalSize {
        padding: 7px 15px 8px;
        border-radius: 10px;
    }

    &._crm._normalSize {
        border-radius: 13px;
    }

    &._orderInfo {
        border-radius: 12px;
    }

    &._onlyIcon {
        padding: 0;
    }

    &._notPadding {
        padding: 0 0 2px;
    }

    &._crm &__content {
        font-size: 14px;
    }

    &._crm._normalSize &__content {
        font-size: 16px;
    }

    &__content {
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        transition: $trans;

        @include forType("app") {
            font-size: 16px;
        }

        @include media(0, $mediaM) {
            font-size: 18px;
        }

        &Item {
            display: inline-block;
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            line-height: 0.65;

            @include media(0, $mediaM) {
                font-size: 18px;
            }

            &._left {
                margin-left: 4px;
            }
        }
    }

    &._extraMinSize &__content {
        font-size: 12px;
    }

    &._minSize &__content {
        font-size: 13px;
    }

    &._mediumSize &__content {
        font-weight: 600;
        font-size: 14px;
    }

    &._mediumSize._fontLight &__content {
        font-weight: 500;
    }

    &._normalSize &__content {
        font-size: 14px;
    }

    &._medium2Size &__content {
        font-size: 15px;

        @include media(0, $mediaM) {
            font-size: 16.5px;
        }
    }

    &._bigSize &__content {
        font-size: 18px;

        @include media(0, $mediaM) {
            font-size: 20px;
        }

        &Item {
            font-size: 18px;

            @include media(0, $mediaM) {
                font-size: 20px;
            }
        }
    }

    &._disabled &__content {
        @include theme("light") {
            color: $colorGreyDark;
        }

        @include forType("app") {
            color: #fff;
        }

        &Item {
            @include theme("light") {
                color: $colorGreyDark;
            }

            @include forType("app") {
                color: #fff;
            }
        }
    }

    &._disabled._disabledDark &__content {
        @include theme("light") {
            color: #fff;
        }

        &Item {
            @include theme("light") {
                color: #fff;
            }
        }
    }

    &._alert &__content {
        color: $colorAlert;
    }

    &._alertLight &__content {
        color: $colorAlert;
    }

    &._cancelEmpty &__content {
        color: $colorAlert;
    }

    &._orderInfo &__content {
        font-size: 17px;
    }

    &._blue &__content {
        @include theme("light") {
            color: $colorMain;
        }

        @include theme("dark") {
            color: #aec2ff;
        }
    }

    &._white &__content {
        color: $colorMain;
    }

    &._weightNormal &__content {
        font-weight: 500;
    }

    &._mainEmpty &__content {
        @include theme("light") {
            color: $colorMain;
        }
    }

    &__icon {
        width: 18px;
        height: 18px;
        transform: translate(0, 1px);
        transition: $trans;

        @include icon(#fff);

        @include media(0, $mediaM) {
            width: 26px;
            height: 26px;
        }

        &._start {
            margin-right: 8px;
            margin-left: -8px;

            @include media(0, $mediaM) {
                margin-right: 10px;
            }
        }

        &._end {
            margin-right: -8px;
            margin-left: 8px;

            @include media(0, $mediaM) {
                margin-left: 10px;
            }

            @include forType("app") {
                margin-left: 4px;
            }
        }

        &._app-order-camera {
            width: 20px;
            height: 16px;
            margin-right: 0 !important;
            margin-left: 12px !important;
        }
    }

    &._withIcon:hover &__icon._start {
        @include mediaMin($mediaM + 1px) {
            transform: translate(-4px, 1px);
        }
    }

    &._withIcon:hover &__icon._end {
        @include mediaMin($mediaM + 1px) {
            transform: translate(4px, 1px);
        }
    }

    &._onlyIcon &__icon._end {
        margin-left: 0;
    }

    &._disabled &__icon {
        @include theme("light") {
            @include icon($colorGreyDark);
        }

        @include forType("app") {
            @include icon(#fff);
        }
    }

    &._blue &__icon {
        @include icon($colorMain);
    }

    &._white &__icon {
        @include icon($colorMain);
    }

    &__loader {
        background: inherit;
        cursor: default;
        opacity: 0;
        pointer-events: none;

        &Item {
            width: 22px;
            height: 22px;

            @include media(0, $mediaM) {
                width: $sizeLoaderMob;
                height: $sizeLoaderMob;
            }
        }
    }

    &._showLoader &__loader {
        opacity: 1;
    }

    &._extraMinSize &__loaderItem {
        width: 16px;
        height: 16px;
    }

    &._minSize &__loaderItem {
        width: 16px;
        height: 16px;
    }

    &._mediumSize &__loaderItem {
        width: 20px;
        height: 20px;
    }

    &._normalSizeLoader &__loaderItem {
        width: 18px;
        height: 18px;
    }

    &._blue .loader__itemInner {
        @include forType("app", "light") {
            background: $colorMain;
        }

        @include forType("app", "dark") {
            background: #aec2ff;
        }
    }
}
