.notificationCounter {
    // width: 100%;
    min-width: 14px;
    height: 100%;
    padding: 0 4px;
    border-radius: 360px;
    background: $colorAlert;
    transition: $trans;
    opacity: 0;
    transform: scale(.5);

    @include forType('crm') {
        min-width: 16px;
        border-radius: 6px;
    }

    @include forType('app') {
        min-width: 21px;
        border-radius: 7px;
    }

    &._minSize {
        padding: 0 4px;

        @include forType('crm') {
            min-width: 14px;
        }
    }

    &._show {
        opacity: 1;
        transform: scale(1)
    }

    &__box {
        justify-content: center;
        align-items: center;
        // width: 100%;
        height: 100%;
    }

    &__inner {
        justify-content: center;
        align-items: center;
        width: auto;
        // width: 100%;
        // height: 100%;
        font-size: 11px;
        font-weight: 900;
        color: #fff;
        text-align: center;

        @include forType('crm') {
            font-size: 12px;
        }

        @include forType('app') {
            font-size: 13px;
        }

        @include media(0, $mediaM) {
            font-size: 13px;
        }

        &._child {
            // width: 100%;
        }
    }

    &._minSize &__inner {
        font-size: 10px;
    }
}