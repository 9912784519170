.legalContent {
    width: 100%;

    @include forType('crm') {
        padding: 24px;
    }

    @include forType('site') {
        padding: 24px;

        @include media(0, $mediaM) {
            padding: 0;
        }
    }

    @include inPublic() {
        padding: 0 !important;
    }

    .body__popup & {
        padding: 0 !important;
    }

    @include forType('app') {
        padding: 20px;
        border-radius: 16px;
        border: 1px solid transparent;
        transition: $trans;
    }

    @include forType('app', 'light') {
        background: #FCFDFE;
        border-color: #fff;
    }

    @include forType('app', 'dark') {
        background: #25292C;
        border-color: #2B2F33;
    }

    & h1,
    & h2 {
        font-size: 20px;
        line-height: 1.4;
        font-weight: 700;
        letter-spacing: .5px;
        text-align: center;
        margin-bottom: 20px;
        transition: $trans;
        text-transform: uppercase;

        @include inPublic() {
            font-size: 2.5rem;
        }

        @include forType('app') {
            font-size: 16px;
            margin-bottom: 12px;
            text-align: left;
        }

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    & ol+h1 {
        margin-top: 20px;

        @include forType('app') {
            margin-top: 12px;
        }

        @include inPublic() {
            margin-top: 2rem;
        }
    }

    & h2 {
        text-align: left;
        position: relative;
        margin-top: 20px;

        @include forType('app') {
            margin-top: 12px;
        }

        @include inPublic() {
            margin-top: 2rem;
        }
    }

    & ol,
    & ul {
        padding-left: 20px;

        @include inPublic() {
            padding-left: 2rem;
        }
    }

    & li {
        font-size: 16px;
        line-height: 1.4;
        text-align: justify;
        padding-left: 12px;
        position: relative;
        transition: $trans;

        @include forType('app') {
            text-align: left;
        }

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #B5BDC5;
        }

        @include inPublic() {
            font-size: 2rem;
            padding-left: 1.2rem;

            @include media(0, $mediaM) {
                font-size: 2.3rem;
            }
        }
    }

    & p {
        font-size: 16px;
        line-height: 1.4;
        text-align: justify;
        margin: 14px 0;

        @include inPublic() {
            font-size: 2rem;
            margin: 1.5rem 0;

            @include media(0, $mediaM) {
                font-size: 2.3rem;
            }
        }
    }

    & b {
        font-weight: 600;
    }

    & ul li {
        list-style-type: lower-alpha;

        &::marker {
            font-weight: 600;
        }
    }

    & ul._dashed li {
        list-style-type: none;

        &::before {
            position: absolute;
            top: 0;
            left: -18px;
            content: '—';
        }
    }

    & ul._dashed li+li {
        margin-top: 4px;

        @include inPublic() {
            margin-top: 0.5rem;
        }
    }

    & ol {
        counter-reset: listCounter;
        padding-left: 6px;

        @include forType('app') {
            padding-left: 0;
        }

        @include inPublic() {
            padding-left: 0.8rem;
        }
    }

    & ol>li {
        counter-increment: listCounter;
        padding-left: 26px;

        @include forType('app') {
            padding-left: 22px;
        }

        @include inPublic() {
            padding-left: 3rem;
        }
    }

    & ol>li ul>li {
        padding-left: 14px;

        @include inPublic() {
            padding-left: 1.5rem;
        }
    }

    &>ol._titles>li::before {
        font-size: 20px;
        line-height: 1.4;
        font-weight: 700;

        @include forType('app') {
            font-size: 16px;
        }

        @include forType('app', 'dark') {
            color: #fff;
        }

        @include inPublic() {
            font-size: 2.5rem;
        }
    }

    &>ol>li>ol {
        padding-left: 2px;

        @include forType('app') {
            padding-left: 0;
        }

        @include inPublic() {
            padding-left: 0.2rem;
        }
    }

    &>ol>li>ol>li {
        padding-left: 40px;

        @include forType('app') {
            padding-left: 36px;
        }

        @include inPublic() {
            padding-left: 5rem;
        }
    }

    &>ol>li>ol>li>ol {
        padding-left: 0;
    }

    &>ol>li>ol>li>ol>li {
        padding-left: 42px;

        @include forType('app') {
            padding-left: 44px;
        }

        @include inPublic() {
            padding-left: 6rem;
        }
    }

    &>ol>li ol,
    &>ol>li ul {
        margin-top: 12px;

        @include inPublic() {
            margin-top: 1.5rem;
        }
    }

    & ol>li {
        &::before {
            content: counters(listCounter, ".")". ";
            position: absolute;
            top: 0;
            left: 0;
            font-weight: 700;
            transition: $trans;

            @include forType('app', 'light') {
                color: $colorDark2;
            }

            @include forType('app', 'dark') {
                color: #B5BDC5;
            }
        }
    }

    &>ol>li>ol,
    &>ol>li>ul,
    &>ol>li>p {
        @include forType('app') {
            width: calc(100% + 22px);
            margin-left: -22px;
        }
    }

    &>ol>li>ol>li>ol,
    &>ol>li>ol>li>ul,
    &>ol>li>ol>li>p {
        @include forType('app') {
            width: calc(100% + 36px);
            margin-left: -36px;
        }
    }

    & li+li {
        margin-top: 16px;

        @include forType('app') {
            margin-top: 12px;
        }

        @include inPublic() {
            margin-top: 2rem;
        }
    }

    & a {
        font-size: 16px;
        line-height: 1.4;
        border-bottom: 1px solid transparent;
        transition: $trans;

        @include theme('light') {
            color: $colorMain;
            border-color: $colorMain;
        }

        @include theme('dark') {
            color: #AEC2FF;
            border-color: #AEC2FF;
        }

        @include inPublic() {
            font-size: 2rem;

            @include media(0, $mediaM) {
                font-size: 2.3rem;
                word-break: break-all;
            }
        }
    }

    &__table {
        @extend %noscroll;
        width: 100%;
        border: 1px solid $colorDark2;
        margin-top: 20px;

        @include forType('app') {
            position: relative;
            z-index: 1;
            overflow: hidden;
            overflow-x: auto;
        }

        @include inPublic() {
            margin-top: 2.5rem;

            @include media(0, $mediaM) {
                position: relative;
                z-index: 1;
                overflow: hidden;
                overflow-x: auto;
            }
        }
    }

    & table {
        width: 100%;
        border-spacing: 0;

        & tr:not(:last-child) th,
        & tr:not(:last-child) td {
            border-bottom: 1px solid $colorDark2;
        }

        & th,
        & td {
            padding: 12px;
            text-align: center;
            font-size: 16px;
            line-height: 1.3;
            transition: $trans;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            &:not(:last-child) {
                border-right: 1px solid $colorDark2;
            }

            @include inPublic() {
                padding: 1.5rem;
                font-size: 2rem;

                @include media(0, $mediaM) {
                    font-size: 2.3rem;
                }
            }
        }
    }
}