.pays {
    width: 100%;
    height: 100%;
    position: relative;

    &__pages {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-64px, 0);
            }

            &._next {
                transform: translate(64px, 0);
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
            position: relative;
        }

        &Box {
            @extend %noscroll;
            width: 100%;
            height: 100%;
            position: relative;
            overflow-y: auto;
            padding: calc(20px + #{$padding}) $padding 30px $padding;
            transition: $trans;
        }

        &Inner:not(._init) &Box {
            opacity: 0;
        }

        &Empty {
            z-index: 9;
        }

        &Previews {
            width: 100%;
            position: relative;
            transition: $trans;
        }

        &Preview {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: $trans;

            &Inner {
                width: 100%;
                transition: $trans;
            }

            &:not(._show) &Inner {
                opacity: 0;
            }
        }

        ._parentCalc &Preview,
        &Previews._static &Preview {
            position: relative;

            &:not(._show) {
                display: none;
            }

            &:not(._last) {
                margin-bottom: 10px;
            }
        }

        &Button {
            position: absolute;
            width: 100%;
            padding: 20px;
            z-index: 20;
            bottom: 0;
            left: 0;
            transition: $trans;
            pointer-events: none;

            @include theme('light') {
                background: linear-gradient(to bottom, rgba($colorBlueBack, 0), $colorBlueBack 50%);
            }

            @include theme('dark') {
                background: linear-gradient(to bottom, rgba(#1C1F21, 0), #1C1F21 50%);
            }

            &:not(._show) {
                opacity: 0;
            }

            &Inner {
                width: 100%;
                height: 56px;
                pointer-events: visible;
            }
        }
    }

    &__pages._static &__page {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }
}