.pagination {
    display: flex;
    align-items: center;

    &._dynamic {
        position: relative;
    }

    &._dynamic &__item {
        position: absolute;
        top: 0;
        left: 0;

        &:not(._show) {
            opacity: 0;
        }
    }

    &._dynamic &__item+&__item {
        margin-left: 0;
    }

    &._dynamic._static &__item {
        position: relative;

        &:not(._show) {
            display: none;
        }

        &:not(._last) {
            margin-right: 6px;
        }
    }

    &__item {
        width: 8px;
        height: 8px;

        &+& {
            margin-left: 6px;
        }

        &Inner {
            width: 100%;
            height: 100%;
            border-radius: 2.8px;
            transition: $trans;
            will-change: transform;

            @include forType('app', 'light') {
                background: $colorMain;
            }

            @include forType('app', 'dark') {
                background: #AEC2FF;
            }
        }

        &:not(._current) &Inner {
            opacity: .28;
        }

        &._current &Inner {
            transform: rotate(45deg);
        }
    }
}