.animateChange {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;

    &._static {
        width: auto;
    }

    &._init {
        transition: $trans;
    }

    &__elem {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: $trans;

        &._show {
            opacity: 1;
        }

        &._prev {
            opacity: 0;
        }
    }

    &._rightX &__elem {
        right: 0;
        left: auto;
    }

    ._parentForce &__elem,
    &._static &__elem {
        position: relative;

        &._prev {
            display: none;
        }
    }

    &._centerX > &__elem {
        left: 50%;
    }

    &:not(._init) > &__elem {
        transition: none !important;
    }

    &._translateMedium > &__elem:not(._hard) {
        transform: translate(0, -6px);

        &._show {
            transform: translate(0, 0);
        }

        &._prev {
            transform: translate(0, 6px);
        }
    }

    &._translate > &__elem:not(._hard) {
        transform: translate(0, -12px);

        &._show {
            transform: translate(0, 0);
        }

        &._prev {
            transform: translate(0, 12px);
        }
    }

    &._translateLarge > &__elem:not(._hard) {
        transform: translate(0, -24px);

        &._show {
            transform: translate(0, 0);
        }

        &._prev {
            transform: translate(0, 24px);
        }
    }

    &._translateMax > &__elem:not(._hard) {
        transform: translate(0, -100%);

        &._show {
            transform: translate(0, 0);
        }

        &._prev {
            transform: translate(0, 100%);
        }
    }

    &._centerX._translateMedium > &__elem:not(._hard) {
        transform: translate(-50%, -6px);
        transition: $trans;

        &._show {
            transform: translate(-50%, 0);
        }

        &._prev {
            transform: translate(-50%, 6px);
        }
    }

    &._centerX._translate > &__elem:not(._hard) {
        transform: translate(-50%, -12px);

        &._show {
            transform: translate(-50%, 0);
        }

        &._prev {
            transform: translate(-50%, 12px);
        }
    }

    &._centerX._translateLarge > &__elem:not(._hard) {
        transform: translate(-50%, -24px);

        &._show {
            transform: translate(-50%, 0);
        }

        &._prev {
            transform: translate(-50%, 24px);
        }
    }
}
