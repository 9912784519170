.v2cursorSupport {
    position: absolute;
    z-index: 10001;
    pointer-events: none;

    &__item {
        position: absolute;
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._bottom {
                transform: translate(0, 3px);
            }

            &._top {
                transform: translate(0, -3px);
            }
        }

        &::before {
            position: absolute;
            width: 8px;
            height: 8px;

            // background: $colorDark;
            background: #a4a7ac;
            content: "";
        }

        &._bottom::before {
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &._top::before {
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%) rotate(45deg);
        }

        &._upper &Inner {
            padding: 3px 8px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: .2px;
            text-transform: uppercase;

            & b {
                font-size: 12px;
                letter-spacing: 0.1px;
            }
        }

        &._wide &Inner {
            padding: 8px 14px 7px;
            line-height: 1.3;
        }

        &Inner {
            padding: 4px 8px;
            color: #fff;
            font-weight: 600;
            font-size: 13px;
            line-height: 1.25;
            white-space: nowrap;
            text-align: center;

            // background: rgba(#4a525b, 0.9);
            background: #a4a7ac;
            border-radius: 8px;

            & b {
                color: #fff;
                font-weight: 600;
                font-size: 14px;
                line-height: 1.25;
            }
        }
    }
}
