.pdfNonResidentPayAct {
    width: 100%;

    &__title {
        width: 100%;
        font-size: 20px;
        line-height: 1.3;
        font-weight: 900;
        color: $colorDark2;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($colorDark2, .2);
        margin-bottom: 20px;
        text-align: center;
    }

    &__head {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 32px;

        &Item {
            font-size: 14px;
            line-height: 1.3;
            color: $colorGreyDark;
        }
    }

    &__text {
        max-width: 100%;
        font-size: 14px;
        line-height: 1.3;
        color: $colorDark2;
        text-align: justify;
        position: relative;

        &._indent {
            padding-left: 24px;
        }

        &+& {
            margin-top: 16px;
        }

        &Counter {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 14px;
            line-height: 1.3;
            color: $colorDark2;
        }

        &Inner+.pdf__table,
        .pdf__table+&Inner {
            margin-top: 16px;
        }
    }

    &__footer {
        width: 100%;
        align-items: stretch;
        margin-top: 50px;

        &Block {
            width: 50%;
            border: 1px solid rgba($colorDark2, .2);
            padding: 20px;

            &+& {
                border-left: 0;
            }
        }

        &Text {
            font-size: 14px;
            line-height: 1.3;
            margin-bottom: 8px;

            &._head {
                margin-bottom: 20px;
            }

            &._foot {
                margin-top: auto !important;
            }

            & b {
                font-weight: 600;
            }
        }
    }
}