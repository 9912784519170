.legendMap {
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 6px 8px;
    border-radius: 7px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.09);

    @include media(0, $mediaM) {
        padding: 8px 12px;
        border-radius: 8px;
    }

    @include theme('light') {
        background: #fff;
    }

    @include theme('dark') {
        background: $colorDarkTheme2;
    }

    &__item {
        align-items: center;
        font-size: 12px;
        font-weight: 500;

        @include media(0, $mediaM) {
            font-size: 14px;
        }

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }

        &::before {
            content: "";
            display: block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-right: 4px;

            @include media(0, $mediaM) {
                width: 8px;
                height: 8px;
            }
        }

        &._ttk::before {
            background: $colorTTK;
        }

        &._sk::before {
            background: $colorSK;
        }

        &+& {
            margin-left: 9px;
        }
    }
}