.settingsTax {
    width: 100%;
    padding-top: 40px;

    &__actions {
        width: 100%;
        position: relative;
        transition: $trans;
        z-index: 2;

        &:not(._empty) {
            margin-bottom: 26px;
        }
    }

    &__actions._static &__action {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__action {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: $trans;
        padding: 0 20px;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-64px, 0);
            }

            &._next {
                transform: translate(64px, 0);
            }
        }

        &Inner {
            width: 100%;
            border-radius: 24px;
            box-shadow: 0px 1px 6px 0px rgba(62, 69, 76, 0.04);
            padding: 28px;
            transition: $trans;

            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: $colorDarkTheme2;
            }
        }

        &._check &Inner {
            @include theme('light') {
                box-shadow: 0px 6px 40px 0px rgba(255, 178, 0, 0.32);
            }

            @include theme('dark') {
                box-shadow: 0px 6px 30px 0px rgba(255, 178, 0, 0.2);
            }
        }

        &Icon {
            width: 42px;
            height: 38px;
            margin-bottom: 7px;
        }

        &._add &Icon {
            @include icon($colorMain);
        }

        &._check &Icon {
            width: 27px;
            height: 25px;
        }

        &Title {
            max-width: 100%;
            font-size: 20px;
            line-height: 1.3;
            font-weight: 600;
            transition: $trans;
            margin-bottom: 4px;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Description {
            max-width: 100%;
            font-size: 14.5px;
            line-height: 1.4;
            font-weight: 300;
            transition: $trans;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }
        }

        &Description+&Button,
        &Error+&Button {
            margin-top: 15px;
        }

        &Button {
            width: 100%;
            height: 42px;

            &+& {
                margin-top: 8px;
            }
        }

        &Error {
            font-size: 14px;
            line-height: 1.3;
            color: $colorAlert;
            transition: $trans;

            &._parent {
                width: 100%;
            }

            &._parent:not(._empty) {
                margin-bottom: 10px;
            }
        }
    }

    &._disabled &__info {
        opacity: 0.64;
        pointer-events: none;
    }

    &__info {
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 20px;
        margin-bottom: 26px;
        transition: $trans;
        position: relative;

        &:not(._ready) &Block {
            opacity: 0;
        }

        &Block {
            width: calc(50% - 5px);
            margin-bottom: 10px;
            border-radius: 18px;
            padding: 22px;
            position: relative;
            transition: $trans;

            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: $colorDarkTheme2;
            }

            &._full {
                width: 100%;
            }

            &._alert {
                box-shadow: 0 0 16px 0 rgba($colorAlert, .16);
            }

            &Info {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 18px;
                height: 18px;
                z-index: 2;

                @include theme('light') {
                    @include icon(rgba($colorMain, .2));
                }

                @include theme('dark') {
                    @include icon(#AEC2FF);
                }
            }

            &Title {
                max-width: 100%;
                font-size: 21px;
                font-weight: 600;
                transition: $trans;
                margin-bottom: 3px;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }

            &._alert &Title {
                color: $colorAlert;
            }

            &._full &Title {
                font-size: 24px;
            }

            &Description {
                max-width: 100%;
                font-size: 16px;
                line-height: 1.3;
                font-weight: 300;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }

            &Button {
                width: 100%;
                height: 42px;
                margin-top: 14px;
                justify-content: center;
                align-items: center;
                border-radius: 11px;
                font-size: 16px;
                transition: $trans;

                @include theme('light') {
                    color: $colorMain;
                    background: rgba($colorMain, .075);
                }

                @include theme('dark') {
                    color: #AEC2FF;
                    background: rgba($colorMain, .2);
                }

                &Icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 4px;

                    @include theme('light') {
                        @include icon($colorMain);
                    }

                    @include theme('dark') {
                        @include icon(#AEC2FF);
                    }
                }
            }
        }
    }

    &._disabled &__history {
        opacity: 0.64;
        pointer-events: none;
    }

    &__history {
        width: 100%;
        transition: $trans;

        &Head {
            height: 40px;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
        }

        &Title {
            font-size: 20px;
            font-weight: 600;
            transition: $trans;

            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            &Item {
                font-weight: 500;
                display: inline-block;
                font-size: 20px;
                line-height: 0.7;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }
        }

        &Content {
            width: 100%;
            position: relative;
            z-index: 1;

            &Inner {
                width: 100%;
                padding: 12px 20px 20px 20px;
                transition: $trans;
            }

            &:not(._ready) &Inner {
                opacity: 0;
            }
        }

        &Cards {
            width: 100%;
        }

        &Card {
            width: 100%;
            transition: $trans;
            border-radius: 18px;

            @include theme('light') {
                background: #fff;
            }

            @include theme('dark') {
                background: $colorDarkTheme2;
            }

            &+& {
                margin-top: 12px;
            }

            &Head {
                width: 100%;
                padding: 18px 22px;
                align-items: center;
                border-bottom: 1px solid transparent;
                transition: $trans;

                @include theme('light') {
                    border-color: rgba(#B5BDC5, .5);
                }

                @include theme('dark') {
                    border-color: #1C1F21;
                }
            }

            &Amount {
                font-size: 20px;
                font-weight: 600;
                color: #B5BDC5;

                &._positive {
                    color: $colorSuccess;
                }
            }

            &Date {
                margin-left: auto;
                font-size: 16px;
                color: #B5BDC5;
            }

            &Content {
                width: 100%;
                padding: 18px 22px;
            }

            &Title {
                max-width: 100%;
                font-size: 18px;
                line-height: 1.3;
                font-weight: 600;
                margin-bottom: 3px;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #ffff;
                }
            }

            &Description {
                max-width: 100%;
                font-size: 14px;
                line-height: 1.4;
                transition: $trans;

                @include theme('light') {
                    color: $colorDark2;
                }

                @include theme('dark') {
                    color: #ffff;
                }
            }
        }
    }
}