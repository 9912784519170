.search {
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 12px;
    cursor: text;
    transition: $trans;

    @include theme('light') {
        background: $colorGreyLight;
        border-color: $colorGrey;
    }

    @include theme('dark') {
        background: #2f333c;
        border-color: #363b45;
    }

    &._chatList {
        border-radius: 8px;
    }

    @include forType('crm', 'light') {
        border-color: transparent;
        background: $colorBlueBack2;
    }

    @include forType('crm', 'dark') {
        border-color: transparent;
        background: $colorBlueBack;
    }

    &._select {
        background: none !important;
    }

    &._filter {
        border: 0;
        border-radius: 10px;

        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: $colorDarkTheme3;
        }

        @include forType('crm', 'light') {
            background: #fff;
        }

        @include forType('crm', 'dark') {
            background: $colorDarkTheme3;
        }

        &._grey {
            @include forType('app', 'light') {
                background: $colorBlueBack2;
            }

            @include forType('app', 'dark') {
                background: $colorBlueBack2;
            }

            @include forType('crm', 'light') {
                background: $colorBlueBack2;
            }

            @include forType('crm', 'dark') {
                background: $colorBlueBack2;
            }
        }
    }

    &._lightBlue {
        @include forType('crm', 'light') {
            border-color: transparent;
            background: #f9fbfd;
        }

        @include forType('crm', 'dark') {
            border-color: transparent;
            background: $colorBlueBack;
        }
    }

    &._chatList {
        @include theme('light') {
            background: #f8f8f8;
            border-color: transparent;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
            border-color: transparent;
        }

        @include forType('crm', 'light') {
            background: #fbfcfd;
        }

        @include forType('crm', 'dark') {
            background: #fff;
        }
    }

    &._chatFix {
        @include theme('light') {
            border-color: transparent;
            background: #f8f8f8;
        }

        @include theme('dark') {
            border-color: transparent;
            background: $colorDarkTheme3;
        }
    }

    &._notBack {
        @include theme('light') {
            background: none;
            border-color: transparent;
        }

        @include theme('dark') {
            background: none;
            border-color: transparent;
        }
    }

    &._emptyGrey {
        @include theme('light') {
            background: none;
            border-color: #eeeff1;
        }

        @include theme('dark') {
            background: none;
            border-color: transparent;
        }
    }

    &._mediumSize {
        border-radius: 10px;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 9px;
        width: 24px;
        height: 24px;
        transform: translate(0, -50%);

        @include theme('light') {
            @include icon($colorDark);
        }

        @include theme('dark') {
            @include icon(#fff);
        }
    }

    &._select &__icon {
        width: 20px;
        height: 20px;
        left: 5px;
        @include icon($colorGreyDark);
    }

    &._widget &__icon {
        @include theme('light') {
            @include icon(#76808a);
        }

        @include theme('dark') {
            @include icon($colorDarkThemeText);
        }
    }

    &._chatList &__icon {
        width: 16px;
        height: 16px;
        left: 5px;

        @include forType('crm') {
            width: 20px;
            height: 20px;
            left: 7px;
        }

        @include theme('light') {
            @include icon(#76808a);
        }

        @include theme('dark') {
            @include icon($colorDarkThemeText);
        }
    }

    &._filter &__icon {
        width: 22px;
        height: 22px;
        left: 8px;
    }

    &._grey &__icon {
        @include theme('light') {
            @include icon($colorGreyDark);
        }

        @include theme('dark') {
            @include icon($colorGreyDark);
        }
    }

    &._crm &__icon {
        width: 22px;
        height: 22px;
        left: 5px;

        @include theme('light') {
            @include icon(#75808b);
        }

        @include theme('dark') {
            @include icon(#75808b);
        }
    }

    &._mediumSize &__icon {
        width: 20px;
        height: 20px;
        left: 6px;
    }

    &._emptyGrey &__icon {
        @include theme('light') {
            @include icon(#a5a8af);
        }

        @include theme('dark') {
            @include icon(#75808b);
        }
    }

    &__input {
        width: 100%;
        height: 100%;
        padding: 8px 10px 8px 40px;
        font-weight: 500;
        font-size: 12.5px;
        transition: $trans;
        font-family: 'PTRoot';

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: $colorLight;
        }
    }

    &._select &__input {
        padding: 2px 8px 2px 29px;
        font-size: 14px;
    }

    &._chatList &__input {
        padding-left: 26px;

        @include forType('crm') {
            padding-left: 32px;
        }
    }

    &._chatFix &__input {
        font-size: 14px;
    }

    &._filter &__input {
        font-size: 14px;
        padding-left: 34px;

        @include theme('light') {
            color: $colorDark2;
        }
    }

    &._crm &__input {
        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: $colorDark2;
        }
    }

    &._crm &__input,
    &._crm &__support {
        padding-left: 30px;
    }

    &._mediumSize &__input {
        padding: 6px 10px 7px 30px;
    }

    &._mediumSize &__input,
    &._mediumSize &__support {
        padding-left: 30px;
    }

    &._mediumSize &__support {
        padding-bottom: 1px;
    }

    &__support {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        align-items: center;
        height: 100%;
        padding-left: 40px;
        font-weight: 500;
        font-size: 12.5px;
        cursor: text;
        transition: $trans;
        user-select: none;
        will-change: opacity;

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: $colorLight;
        }
    }

    &._select &__support {
        padding-left: 29px;
        color: $colorGreyDark !important;
        font-size: 14px;
    }

    &._chatList &__support {
        padding-left: 26px;

        @include forType('crm') {
            padding-left: 32px;
        }

        @include theme('light') {
            color: #76808a;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._widget &__support {
        @include theme('light') {
            color: #76808a;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._chatFix &__support {
        font-size: 14px;
    }

    &._focus &__support {
        visibility: hidden;
        opacity: 0;
    }

    &._filter &__support {
        font-size: 14px;
        padding-left: 34px;

        @include theme('light') {
            color: $colorDark2;
        }
    }

    &._grey &__support {
        @include theme('light') {
            color: $colorGreyDark;
        }

        @include theme('dark') {
            color: $colorGreyDark;
        }
    }

    &._widget &__support,
    &._widget &__input {
        font-size: 14px;
    }

    &._widget &__support {
        @include theme('light') {
            color: #75808b;
        }

        @include theme('dark') {
            color: #75808b;
        }
    }

    &._emptyGrey &__support {
        @include theme('light') {
            color: #a5a8af;
        }

        @include theme('dark') {
            color: #a5a8af;
        }
    }

    &__loader {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 8px;
        width: 18px;
        height: 18px;
        z-index: 2;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Item {
            width: 100%;
            height: 100%;
        }
    }

    &._showLoader &__input {
        padding-right: 30px !important;
    }

    &._public {
        border-radius: 1.3rem;

        @include media(0, $mediaM) {
            border-radius: 2rem;
        }
    }

    &._public &__icon {
        width: 2rem;
        height: 2rem;
        left: 1.5rem;

        @include theme('light') {
            @include icon(#B4BEC8);
        }

        @include theme('dark') {
            @include icon(#B4BEC8);
        }

        @include media(0, $mediaM) {
            width: 2.5rem;
            height: 2.5rem;
            left: 2.2rem;
        }
    }

    &._public &__input,
    &._public &__support {
        font-size: 1.7rem;
        padding-left: 4.5rem;

        @include media(0, $mediaM) {
            padding-left: 6.5rem;
            font-size: 2.3rem;
        }
    }
}