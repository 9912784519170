.infoPopup {
    width: 100%;
    height: 100%;

    @include theme('light') {
        background: #fff;
    }

    @include theme('dark') {
        background: #1C1F21;
    }

    &__head {
        width: 100%;
        height: 82px;
        padding: 20px 60px 0 20px;
        position: relative;
        z-index: 2;

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            margin-top: -1px;
            height: 21px;
            z-index: 2;

            @include theme('light') {
                background: linear-gradient(to bottom, #fff, #fff 6px, rgba(#fff, 0));
            }

            @include theme('dark') {
                background: linear-gradient(to bottom, #1C1F21, #1C1F21 6px, rgba(#1C1F21, 0));
            }
        }
    }

    &__title {
        max-width: 100%;
        font-size: 24px;
        line-height: 1.3;
        font-weight: 900;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &__close {
        position: absolute;
        top: 18px;
        right: 20px;
        width: 46px;
        height: 46px;
        border-radius: 16px;
        border: 1px solid transparent;

        @include theme('light') {
            background: #fff;
            border-color: #ECEFF3;
        }

        @include theme('dark') {
            border-color: #2B2F33;
            background: #25292C;
        }

        &Icon {
            width: 20px;
            height: 20px;
            margin: auto;
            display: block;

            @include theme('light') {
                @include icon($colorDark2);
            }

            @include theme('dark') {
                @include icon(#fff);
            }
        }
    }



    &__content {
        @extend %noscroll;
        @extend %editorContent;
        width: 100%;
        height: calc(100% - 82px);
        overflow-y: auto;
        position: relative;
        z-index: 1;
        padding-bottom: 20px;

        & .legalContent {
            background: none !important;
            border: 0 !important;
        }

        & * {
            font-size: 16.5px;
            line-height: 1.4;
            font-weight: 300;
        }

        & a {
            font-weight: 500;
        }

        & b {
            font-weight: 500;
        }

        & h3 {
            font-size: 20px;
            font-weight: 600;

            & * {
                font-weight: 600;
                font-size: 20px;
            }
        }

        &Inner {
            padding: 20px;
            transition: $trans;
        }

        &._hide &Inner {
            opacity: 0;
        }
    }
}