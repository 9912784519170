.v2popupFoot {
    width: 100%;
    padding: 28px;
    border-top: 1px solid $colorBlue;

    &__inner {
        align-items: center;
        width: 100%;

        &._COL {
            align-items: flex-start;
        }
    }

    &__button {
        width: 200px;

        &._full {
            width: 100%;
        }
    }

    &__inner._COL &__info {
        max-width: 100%;
        margin-top: 12px;
        padding-left: 0;
    }

    &__info {
        max-width: calc(100% - 200px);
        padding-left: 20px;

        &Title {
            margin-bottom: 6px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 15px;
        }

        &Text {
            color: $colorGreyDark;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.4;

            &._min {
                font-size: 12px;
            }

            &._dark {
                color: $colorDark2;
            }

            &._dynamic {
                width: 100%;
                white-space: nowrap;
            }

            &._link {
                color: $colorMain;
            }
        }
    }

    &__error {
        width: 100%;
        color: $colorAlert;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.3;
        transition: $trans;

        &._parent:not(._empty) {
            margin-top: 12px;
        }
    }
}
