.v2lazyImage {
    position: relative;
    width: 100%;
    height: 100%;

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);

        @include loader($colorMain);
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: $trans;
    }

    &:not(._ready) &__image {
        opacity: 0;
    }

    &__preview {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 12px;

        &Icon {
            width: 22px;
            height: 22px;
        }

        &Name {
            max-width: 100%;
            margin-top: 6px;
            overflow: hidden;
            color: $colorGreyDark;
            font-size: 14px;
            line-height: 1.4;
            white-space: nowrap;
            text-align: center;
            text-overflow: ellipsis;
        }
    }
}
