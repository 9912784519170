.switch {
    width: 100%;
    height: 100%;
    display: block;
    transition: $trans;

    &._disabled {
        // filter: grayscale(1);
        // opacity: 0.48;
    }

    &__input {
        display: none;
    }

    &__view {
        width: 100%;
        height: 100%;
        transition: $trans;
        position: relative;
        border-radius: 360px;
        @include button(.95);

        @include theme('dark') {
            background: #4c525d;
        }

        @include theme('light') {
            background: #e3e3e3;
        }

        @include forType('crm', 'dark') {
            background: $colorBlue;
        }

        @include forType('crm', 'light') {
            background: $colorBlue;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 3px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            transform: translate(0, -50%);
            transition: $trans;
            will-change: transform;

            @include theme('dark') {
                background: #383d46;
            }

            @include theme('light') {
                background: #fff;
            }

            @include media(0, $mediaM) {
                width: 14px;
                height: 14px;
                left: 5px;
            }
        }
    }

    &._disabled &__view {
        @include theme('dark') {
            background: #383d46;
        }

        @include theme('light') {
            background: $colorBlue !important;
        }
    }

    &._size2 &__view {
        &::before {
            left: 4px;
        }
    }

    &._size3 &__view {
        &::before {
            left: 4px;
            width: 12px;
            height: 12px;
        }
    }

    &._manual &__view {
        @include theme('dark') {
            background: #4c525d;
        }

        @include theme('light') {
            background: $colorBlueBack;
        }
    }

    &._editmode._isEdit &__input:not(:checked)~&__view {
        @include theme('dark') {
            background: #4c525d;
        }

        @include theme('light') {
            background: $colorBlueActive;
        }
    }

    &__input:checked~&__view {
        @include theme('dark') {
            background: $colorBorderHover;
        }

        @include theme('light') {
            background: $colorMain;
        }
    }

    &._green &__input:checked~&__view {
        @include theme('dark') {
            background: #5cc962;
        }

        @include theme('light') {
            background: #5cc962;
        }
    }

    &__input:checked~&__view::before {
        left: calc(100% - 3px);
        transform: translate(-100%, -50%);

        @include media(0, $mediaM) {
            left: calc(100% - 5px);
        }
    }

    &._size2 &__input:checked~&__view::before {
        left: calc(100% - 4px);
    }

    &._size3 &__input:checked~&__view::before {
        left: calc(100% - 4px);
    }

    // &._manual &__input:checked~&__view::before {
    //     left: calc(100% - 5px);
    // }
}