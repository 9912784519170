.auth {
    width: 100%;
    height: 100%;
    position: relative;

    &__top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        align-items: center;
        padding: $padding;
        pointer-events: none;

        &Back {}

        &Pagination {
            margin-left: auto;
            pointer-events: visible;
        }
    }

    &__inner {
        @extend %page;
        position: relative;
        overflow: hidden;

        @include theme('light') {
            background: #fff;
        }
    }

    &__blocks {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__block {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;

            &._next {
                transform: translate(64px, 0);
            }

            &._prev {
                transform: translate(-64px, 0);
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
        }
    }

    &__blocks:not(._static) &__block {
        width: 100%;
        height: 100%;
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__icon {
        width: 52px;
        height: 52px;
        object-fit: contain;
        margin-bottom: 7px;
    }

    &__title {
        @extend %title;
        max-width: 100%;
        text-align: center;
        margin-bottom: 9px;
    }

    &__description {
        @extend %text;
        max-width: 100%;
        text-align: center;
        margin-bottom: 17px;
    }

    &__field {
        width: 100%;
        height: $fieldHeight;
        margin-bottom: 12px;
    }

    &__errors {
        width: 100%;
        transition: $trans;
        position: relative;
    }

    &__error {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 14px;
        font-size: 12px;
        line-height: 17px;
        color: $colorAlert;
        transition: $trans;
        font-weight: 600;
        text-align: center;
        padding-bottom: 12px;
        margin-top: -4px;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__errors._static &__error {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__button {
        width: 100%;
        height: $fieldHeight;
        margin-bottom: 17px;
    }

    &__policy {
        max-width: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 19px;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }

        & span {
            border-bottom: 1px solid transparent;
            display: inline-block;
            line-height: 0.85;

            @include theme('light') {
                color: $colorMain;
                border-color: rgba($colorMain, .7);
            }

            @include theme('dark') {
                color: #AEC2FF;
                border-color: rgba(#AEC2FF, .7);
            }
        }
    }

    &__link {}
}