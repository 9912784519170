.select {
    width: 100%;
    height: 100%;
    position: relative;

    &._fullWidthList &__viewInner {
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100% !important;
    }

    &__view {
        align-items: center;
        padding: 4px 20px 4px 8px;
        height: 100%;
        position: relative;
        transition: $trans;
        border-radius: 8px;
        border: 1px solid transparent;

        @include theme('light') {
            background: #fafafa;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        @include forType('crm', 'light') {
            background: $colorBlueBack;
        }

        @include media(0, $mediaM) {
            @include theme('dark') {
                background: $colorDarkTheme3;
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
            margin-top: -1px;
            right: 10px;
            border: 2px solid transparent;
            transition: $trans;

            @include theme('light') {
                border-right-color: #929292;
                border-bottom-color: #929292;
            }

            @include theme('dark') {
                border-right-color: $colorDarkThemeText;
                border-bottom-color: $colorDarkThemeText;
            }

            @include forType('crm', 'light') {
                border-right-color: $colorGreyDark;
                border-bottom-color: $colorGreyDark;
            }
        }

        &Inner {
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            transition: $trans;

            @include theme('light') {
                color: $colorDark;
            }

            @include theme('dark') {
                color: #fff;
            }

            @include media(0, $mediaM) {
                font-size: 16px;
            }
        }
    }

    &._active:not(._reverse) &__view {
        border-bottom-color: transparent !important;
    }

    &._active &__view {
        @include forType('crm') {
            // border: 0;
            border-color: $colorBlue;
        }

        &::after {
            transform: translate(0, -50%) rotate(-135deg);
            margin-top: 1px;
        }
    }

    &._join &__view {
        border: 0;
        padding-left: 20px;
        padding-right: 20px;

        // font-size: 18px !important;
        &::after {
            right: 20px;
            border-width: 3px;
        }

        &Inner {
            font-size: 16px;
        }
    }

    &._reverse &__view {
        &::after {
            transform: translate(0, -50%) rotate(-135deg);
            margin-top: 2px;
        }
    }

    &._active._reverse &__view {
        border-radius: 0 0 8px 8px;
        border-top-color: transparent !important;

        &::after {
            transform: translate(0, -50%) rotate(45deg);
            margin-top: 0;
        }
    }

    &._center &__view {
        &Inner {
            text-align: center;
            justify-content: center;
        }
    }

    &._bigSize &__view {
        @include forType('crm') {
            border-radius: 12px;
        }

        @include forType('crm', 'light') {
            background: $colorBlueBack2;
        }

        @include forType('crm', 'dark') {
            background: $colorBlueBack2;
        }

        &::after {
            @include media(0, $mediaM) {
                border-width: 3px;
            }

            @include forType('crm') {
                right: 16px;
            }
        }

        &Inner {
            @include forType('crm') {
                font-size: 15.5px;
            }
        }
    }

    &:not(._editmode._isEdit)._successColor &__view {
        &Inner {
            font-weight: 600;
            color: $colorSuccess !important;
        }
    }

    &:not(._editmode._isEdit)._mediumColor &__view {
        &Inner {
            font-weight: 600;
            color: $colorMedium !important;
        }
    }

    &:not(._editmode._isEdit)._errorColor &__view {
        &Inner {
            font-weight: 600;
            color: $colorError !important;
        }
    }

    &._error:not(._whiteBack) &__view {
        background: $colorAlertBack !important;
        border-color: rgba($colorAlert, .2) !important;
    }

    &._error &__view {
        &::after {
            border-right-color: $colorAlert !important;
            border-bottom-color: $colorAlert !important;
        }

        &Inner {
            color: $colorAlert !important;
        }
    }

    &._center &__view {
        justify-content: center;
    }

    &._point &__view {
        @include media(0, $mediaM) {
            padding: 0;
            border-radius: 0;
            height: 32px;

            @include theme('light') {
                background: none;
            }

            @include theme('dark') {
                background: none;
            }
        }

        &Inner {
            @include media(0, $mediaM) {
                font-size: 18px;
            }
        }
    }

    &._editmode &__view {
        padding: 4px 8px;
    }

    &._editmode._isEdit &__view {
        padding-right: 20px;

        @include theme('light') {
            background: $colorBlueActive;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        &::after {
            @include theme('light') {
                border-right-color: $colorMain;
                border-bottom-color: $colorMain;
            }

            @include theme('dark') {
                border-right-color: $colorMainTheme;
                border-bottom-color: $colorMainTheme;
            }
        }

        &Inner {
            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: $colorMainTheme;
            }
        }
    }

    &._emptyList &__view {
        cursor: default;
        padding-right: 10px;

        &::after {
            display: none;
        }
    }

    &._mediumFontView &__viewInner {
        font-weight: 600;
    }

    &._notBack &__view {
        background: none;

        @include forType('crm', 'light') {
            background: none;
        }
    }

    &._notBack._editmode._isEdit &__view {
        @include theme('light') {
            background: $colorBlueActive;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }
    }

    &._disabled &__view {
        cursor: text;
        user-select: text;
        padding-right: 10px;
    }

    &._disabled &__view::after {
        opacity: 0;
    }

    &._empty &__viewInner {
        @include theme('light') {
            color: #a1a5b0;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &._editmode._isEdit._empty &__viewInner {
        @include theme('light') {
            color: rgba($colorMain, .5);
        }

        @include theme('dark') {
            color: rgba($colorMainTheme, .5);
        }
    }

    &._editmode._isEdit .field__value {
        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: $colorMain;
        }
    }

    &._end &__view {
        border-radius: 0 8px 8px 0;
    }

    &._end._active &__view {
        border-radius: 0 8px 0 0;
    }

    &._active &__view {
        border-radius: 8px 8px 0 0;
    }

    &._bigSize._active &__view {
        @include forType('crm') {
            border-radius: 12px 12px 0 0;
        }
    }

    &._detailsCard:not(._isEdit) &__view {
        padding-left: 0;
    }

    &._detailsCard &__view {
        &Inner {
            font-size: 15px;
        }
    }

    &._colorBlue &__view {
        @include theme('light') {
            background: $colorGreyLight;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        @include forType('crm', 'light') {
            background: $colorBlueBack;
        }

        @include forType('crm', 'dark') {
            background: $colorDarkTheme2;
        }
    }

    &._checkComplete._complete &__view {
        &Inner {
            @include theme('light') {
                color: $colorDark;
            }

            @include theme('dark') {
                color: #fff;
            }

            @include media(0, $mediaM) {
                @include theme('light') {
                    color: $colorMain;
                }

                @include theme('dark') {
                    color: $colorMainTheme;
                }
            }
        }
    }

    &._editmode._isEdit._complete &__view {
        &Inner {
            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: $colorMainTheme;
            }
        }
    }

    &._manualField &__view {
        border-radius: 6px;
    }

    &._manualTariff &__view {
        border-radius: 6px;
    }

    &._manualTariff &__viewInner {
        font-size: 15px;
    }

    &._manualField._active &__view,
    &._manualTariff._active &__view {
        border-radius: 6px 6px 0 0;
    }

    &._manualField._reverse._active &__view,
    &._manualTariff._reverse._active &__view {
        border-radius: 0 0 6px 6px;
    }

    &._manualDoc &__view {
        padding: 3px 12px 3px 3px;
        border-radius: 4px;

        &::after {
            right: 5px;
        }

        &Inner {
            width: 100% !important;
            font-size: 11px;
        }
    }

    &._editmode._isEdit._manualDoc &__view {
        &Inner {
            font-weight: 600;
        }
    }

    &._manualDoc._active &__view {
        border-radius: 4px 4px 0 0;
    }

    &._manualDoc._reverse._active &__view {
        border-radius: 0 0 4px 4px;
    }

    &._manualDoc._empty:not(._isEdit) &__view {
        &Inner {
            @include theme('light') {
                color: $colorDark2;
            }

            @include theme('dark') {
                color: $colorDarkThemeText;
            }
        }
    }

    &._manualDoc._empty._isEdit &__view {
        &Inner {
            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: $colorDarkThemeText;
            }
        }
    }

    &._editmode._isEdit:not(._notBack)._whiteBack &__view {
        @include theme('light') {
            background: #fff;
        }
    }

    &._manualMain &__view {
        border-radius: 6px;
        padding: 1px 6px;

        &::after {
            right: 6px;
        }

        &Inner {
            font-size: 13px;
        }
    }

    &._manualMain._editmode._isEdit &__view {
        padding-right: 16px;
    }

    &._manualMain._markCar &__viewInner {
        font-weight: 600;
        font-size: 15px;
    }

    &._manualMain._modelCar &__viewInner {
        font-weight: 600;
    }

    &._manualMain._active &__view {
        border-radius: 6px 6px 0 0;
    }

    &._manualMain._editmode._isEdit._empty &__viewInner {
        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: rgba($colorMainTheme, .5);
        }
    }

    &._manualServices &__view {
        padding: 4px 6px 4px 6px;
        border-radius: 6px;
    }

    &._manualServices._editmode._isEdit &__view {
        padding-right: 16px;
    }

    &._manualServices._active &__view {
        border-radius: 6px 6px 0 0;
    }

    &._reverse._manualServices._active &__view {
        border-radius: 0 0 6px 6px;
    }

    &._fontMedium &__viewInner {
        font-weight: 600;
    }

    &._bankCard &__view {
        border-radius: 9px;
        padding-left: 10px;
    }

    &._bankCard._active &__view {
        border-radius: 9px 9px 0 0;
    }

    &__times {
        align-items: center;

        &Item {
            align-items: center;

            &+& {
                margin-left: 2px;
            }
        }

        &Support {
            font-size: 14px;
            transition: $trans;
            margin-right: 4px;

            @include theme('light') {
                color: $colorGreyDark2;
            }

            @include theme('dark') {
                color: #fff;
            }

            @include media(0, $mediaM) {
                font-size: 18px;

                @include theme('light') {
                    color: $colorGreyDark2;
                }

                @include theme('dark') {
                    color: $colorDarkThemeText;
                }
            }
        }

        &._complete &Support {
            @include theme('light') {
                color: $colorDark;
            }

            @include theme('dark') {
                color: #fff;
            }

            @include media(0, $mediaM) {
                @include theme('light') {
                    color: $colorMain;
                }

                @include theme('dark') {
                    color: $colorMainTheme;
                }
            }
        }

        &Input {
            width: 36px;
        }
    }

    &._editmode._isEdit &__times {
        &Support {
            @include theme('light') {
                color: rgba($colorMain, .5);
            }

            @include theme('dark') {
                color: rgba($colorMainTheme, .5);
            }
        }
    }

    &._editmode._isEdit &__times._complete &__times {
        &Support {
            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: $colorMainTheme;
            }
        }
    }

    &._fullWidthList &__drop {
        min-width: 100%;
        width: auto;
        left: auto;
        right: 0;
    }

    &._join &__drop {
        border: 1px solid $colorBlue !important;
        border-radius: 12px !important;
        margin-top: 6px;
        background: #fff !important;

        &Inner {
            max-height: 150px;
        }

        &Item {
            padding: 16px 20px;
            font-size: 16px;

            &Check {
                top: 50%;
                transform: translate(0, -50%);
                width: 20px;
                height: 20px;
                right: 16px;
            }
        }
    }

    &._join._reverse &__drop {
        margin-top: 0;
        margin-bottom: 6px;
    }

    &__drop {
        position: absolute;
        top: 100%;
        left: 0;
        // padding: 0 0 4px 0;
        // min-width: 100%;
        width: 100%;
        z-index: 20;
        border-radius: 0 0 8px 8px;
        transition: $trans;
        border: 1px solid transparent;
        margin-top: -1px;
        // border-top: 0;
        overflow: hidden;

        @include theme('light') {
            background: #fafafa;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        @include forType('crm', 'light') {
            background: $colorBlueBack2;
            border-color: $colorBlue;
        }

        @include forType('crm', 'dark') {
            background: $colorBlueBack2;
        }

        @include media(0, $mediaM) {
            @include theme('dark') {
                background: $colorDarkTheme3;
            }
        }

        &:not(._show) {
            opacity: 0;
            transform: translate(0, -4px);
            pointer-events: none;
        }

        &Wrap {
            width: 100%;
            position: relative;
            transition: $trans;
        }

        &Wrap & {
            top: 0;
        }

        &Inner {
            width: 100%;
            overflow-y: auto;
            max-height: 100px;
            @extend %noscroll;
        }

        &Select {
            width: 100%;
            border-bottom: 1px solid $colorBlue;
        }

        &._empty &Select {
            border-bottom: 0;
        }

        &Item {
            padding: 5px 24px 5px 8px;
            font-size: 14px;
            line-height: 1.2;
            font-weight: 500;
            transition: $trans;
            position: relative;
            border-color: $colorBlue;

            &+& {
                border-top: 1px solid $colorBlue;
            }

            @include theme('light') {
                color: $colorDark;
            }

            @include theme('dark') {
                color: #fff;
            }

            @include media(0, $mediaM) {
                font-size: 16px;
            }

            &._current {
                @include forType('crm') {
                    background: #fff;
                }
            }

            @include mediaMin($mediaM + 1px) {
                &:not(._current):hover {
                    background: $colorMain;
                    color: #fff !important;
                }
            }

            &Check {
                position: absolute;
                top: 6px;
                right: 6px;
                width: 12px;
                height: 12px;
                @include icon($colorMain);
                z-index: 2;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }
            }
        }
    }

    &._error &__dropItem {
        border-color: rgba($colorAlert, .2) !important;
    }

    &__color {
        width: 100%;
        height: 14px;
        justify-content: center;

        &Inner {
            width: 24px;
            height: 3px;
            border-radius: 360px;
        }
    }

    &._reverse &__drop {
        top: auto;
        bottom: 100%;
        border-radius: 8px 8px 0 0;

        &:not(._show) {
            transform: translate(0, 4px);
        }
    }

    &._manualDoc &__drop {
        border-radius: 0 0 4px 4px;

        &Item {
            padding: 2px 3px;
            font-size: 11px;
        }
    }

    &._reverse._manualDoc &__drop {
        border-radius: 4px 4px 0 0;
    }

    &._bigSize &__drop {
        @include forType('crm') {
            border-radius: 0 0 12px 12px;
        }

        &Item {
            @include forType('crm') {
                padding: 14px 8px;
                font-size: 15.5px;
            }
        }
    }

    &._center &__dropItem {
        // text-align: center;
    }

    &._isEdit &__drop {
        @include theme('light') {
            background: $colorBlueActive;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        &Item {
            @include theme('light') {
                color: $colorMain;
            }

            @include theme('dark') {
                color: $colorMainTheme;
            }
        }
    }

    &._detailsCard &__drop {
        padding-bottom: 1px;

        &Item {
            font-size: 15px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &._colorBlue &__drop {
        @include theme('light') {
            background: $colorGreyLight;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        @include forType('crm', 'light') {
            background: $colorBlueBack;
        }

        @include forType('crm', 'dark') {
            background: $colorDarkTheme2;
        }
    }

    &._point &__drop {
        @include media(0, $mediaM) {
            border: 1px solid transparent;
            border-radius: 0 0 20px 20px;

            @include theme('light') {
                background: #f7f7f7;
                border-color: #eee;
            }

            @include theme('dark') {
                background: $colorDarkTheme3;
                border-color: $colorDarkTheme;
            }
        }

        &Item {
            @include media(0, $mediaM) {
                font-size: 18px;
                padding: 12px 12px;

                @include theme('light') {
                    color: $colorGreyDark2;
                }

                @include theme('dark') {
                    color: $colorDarkThemeText;
                }
            }
        }
    }

    &._active._point &__drop {
        &Wrap {
            @include media(0, $mediaM) {
                margin-top: 8px;
            }
        }
    }

    &._point &__dropItem+&__dropItem {
        @include media(0, $mediaM) {
            border-top: 1px solid transparent;

            @include theme('light') {
                border-color: #eee;
            }

            @include theme('dark') {
                border-color: $colorDarkTheme;
            }
        }
    }

    &._error:not(._whiteBack) &__drop {
        background: $colorAlertBack !important;
        border-color: rgba($colorAlert, .2);
    }

    &._error &__drop {
        &Item:not(:hover) {
            color: $colorAlert !important;
        }
    }

    &._manualTariff &__drop {
        &Item {
            font-size: 15px;
        }
    }

    &._manualField &__drop,
    &._manualTariff &__drop {
        border-radius: 0 0 6px 6px;
    }

    &._reverse._manualField &__drop,
    &._reverse._manualTariff &__drop {
        border-radius: 6px 6px 0 0;
    }

    &._manualMain &__drop {
        border-radius: 0 0 6px 6px;

        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: #fff;
        }

        &Item {
            padding: 3px 6px;
            font-size: 13px;
        }
    }

    &._reverse._manualMain &__drop {
        border-radius: 6px 6px 0 0;
    }

    &._manualMain._markCar &__drop {
        &Item {
            font-size: 15px;
        }
    }

    &._manualServices &__drop {
        border-radius: 0 0 6px 6px;

        &Item {
            padding: 4px 6px;
            font-size: 14px;
        }
    }

    &._reverse._manualServices &__drop {
        border-radius: 6px 6px 0 0;
    }

    &._bankCard &__drop {
        border-radius: 0 0 9px 9px;

        &Item {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}