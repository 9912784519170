.v2filter {
    position: relative;
    width: 100%;
    height: 100%;

    &__scroll {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 28px;
        overflow: hidden;
        overflow-y: auto;
        transition: $trans;
    }

    &._withButton &__scroll {
        padding-bottom: 98px;
    }

    &__block {
        width: 100%;

        & + & {
            margin-top: 26px;

            @include mob {
                margin-top: 28px;
            }
        }

        &Head {
            position: relative;
            width: 100%;
            margin-bottom: 12px;
            font-weight: 300;
            font-size: 14px;

            @include mob {
                margin-bottom: 14px;
                font-size: 16px;
            }

            &Delete {
                position: absolute;
                top: 50%;
                right: 0;
                z-index: 2;
                width: 16px;
                height: 16px;
                transform: translate(0, -50%);

                @include mob {
                    width: 20px;
                    height: 20px;
                }

                @include icon(#e1e7ee);
                @include button(0.9, true);

                &::before {
                    top: 50%;
                    left: 50%;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                }

                @include hover {
                    &:hover {
                        @include icon($colorAlert);
                    }
                }
            }
        }

        &Content {
            width: 100%;
        }

        &Input {
            width: 100%;
            background: $colorBlueBack;
            border-radius: 12px;
        }

        &Items {
            flex-wrap: wrap;
            width: calc(100% + 6px);
            margin-bottom: -6px;
        }

        &Item {
            margin: 0 6px 6px 0;
            padding: 6px 12px;
            color: $colorDark2;
            font-weight: 500;
            font-size: 14px;
            background: $colorBlueBack;
            border-radius: 360px;

            @include mob {
                padding: 8px 14px;
                font-size: 16px;
            }

            @include hover {
                &:hover {
                    color: #fff;
                    background: $colorMain;
                }
            }

            @include button(0.97);

            &._current {
                color: #fff;
                font-weight: 500;
                background: $colorMain;
            }
        }
    }

    &__buttonWrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 98px;
        padding: 24px;
        background: linear-gradient(to top, #fff, rgba(#fff, 0));
        opacity: 0;
        transition: $trans;
        pointer-events: none;
    }

    &._withButton &__buttonWrapper {
        opacity: 1;
    }

    &__button {
        width: 100%;
        height: 100%;

        &:not(._empty) {
            pointer-events: visible;
        }

        &._parent {
            z-index: 10;
            width: 100%;
            transition: $trans;
        }

        &Inner {
            width: 100%;
            height: 100%;
        }
    }
}
