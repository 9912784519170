.start {
    width: 100%;
    height: 100%;

    @include theme("light") {
        background: #fff;
    }

    @include theme("dark") {
        background: #1c1f21;
    }

    &__inner {
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 50px $padding 42px;
    }

    &__content {
        align-items: center;
        width: 100%;
        margin: auto 0;
        padding-bottom: 30px;
    }

    &__icon {
        width: 60px;
        height: 60px;
        margin-bottom: 8px;
    }

    &__title {
        @extend %title;

        margin-bottom: 8px;
        text-align: center;
    }

    &__description {
        @extend %text;

        margin-bottom: 18px;
        text-align: center;
    }

    &__button {
        width: 100%;
        height: 56px;
    }

    &__date {
        max-width: 100%;
        color: #b5bdc5;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
    }
}
