.v2codeInputs {
    width: 100%;

    &__item {
        width: 52px;
        height: 52px;
        background: #f2f4f7;
        border-radius: 12px;

        & + & {
            margin-left: 8px;
        }

        &Input {
            width: 100%;
            height: 100%;
            color: $colorDark2;
            font-weight: 600;
            font-size: 22px;
            text-align: center;
        }
    }
}
