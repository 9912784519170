.checkbox {
    width: 100%;
    height: 100%;
    display: block;

    &__input {
        display: none;
    }

    &__view {
        width: 100%;
        height: 100%;
        position: relative;
        padding-left: 18px;
    }

    &._empty &__view {
        height: 12px;
    }

    &._square &__view {
        padding-left: 25px;
    }

    &._iconOut &__view {
        padding-left: 18px;
    }

    &._empty._square &__view {
        height: 14px;
    }

    &._square._mediumSize &__view {
        height: 12px;
    }

    &__point {
        position: absolute;
        top: 3px;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid transparent;
        transition: $trans;

        @include theme('light') {
            border-color: $colorDark;
        }

        @include theme('dark') {
            border-color: #fff;
        }

        &Icon {
            position: absolute;
            top: -3px;
            left: 0;
            width: 14px;
            height: 14px;
            transition: $trans;
            opacity: 0;
            transform: translate(0, -1px);

            @include theme('light') {
                @include icon($colorMain);
            }

            @include theme('dark') {
                @include icon($colorMainTheme);
            }
        }
    }

    &._square &__point {
        width: 16px;
        height: 16px;
        top: 2px;
        border-radius: 5px;
        border: 1px solid transparent;

        @include theme('light') {
            border-color: $colorDark2;
        }

        @include theme('dark') {
            border-color: #fff;
        }

        &Icon {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
        }
    }

    &._export &__point {
        width: 15px;
        height: 15px;
        border-radius: 5px;

        &Icon {
            width: 8px;
            height: 8px;
        }
    }

    &._square._mediumSize &__point {
        width: 12px;
        height: 12px;

        &Icon {
            width: 12px;
            height: 12px;
            margin: -1.5px 0 0 2.5px;
        }
    }

    &._square._bigSize &__point {
        width: 18px;
        height: 18px;
        border-radius: 6px;

        &Icon {
            width: 12px;
            height: 12px;
            margin: 0;
        }
    }

    &._square._green &__point {
        border-color: $colorGreyDark !important;

        &Icon {
            @include icon(#fff);
        }
    }

    &._square._blue &__point {
        border-color: $colorGreyDark !important;

        &Icon {
            @include icon(#fff);
        }
    }

    &._error &__view &__point {
        border-color: $colorAlert !important;
    }

    &._iconOut &__point {
        width: 13px;
        height: 13px;

        &Icon {
            width: 17px;
            height: 17px;
            margin: -2px 0 0 3px;
        }
    }

    &._empty &__point {
        top: 0;
    }

    &__input:checked~&__view &__pointIcon {
        transform: translate(0, 0);
        opacity: 1;
    }

    &._square._green &__input:checked~&__view &__point {
        background: #5cc962;
        border-color: #5cc962 !important;
    }

    &._square._blue &__input:checked~&__view &__point {
        background: $colorMain;
        border-color: $colorMain !important;
    }

    &._square &__input:checked~&__view &__point {
        &Icon {
            transform: translate(-50%, -50%);
        }
    }

    &__content {
        width: 100%;
        font-size: 13px;
        line-height: 1.4;
        font-weight: 300;

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }

        & span {
            font-weight: 600;
            border-bottom: 1px dashed transparent;
            transition: $trans;

            &:hover {
                opacity: .5;
            }

            @include theme('light') {
                border-color: $colorDark;
            }

            @include theme('dark') {
                border-color: #fff;
            }
        }
    }

    &._square &__content {
        font-weight: 500;
        font-size: 14px;
    }

    &._weightLight &__content {
        font-weight: 300;
    }
}