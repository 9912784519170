.v2emodziList {
    width: 100%;
    height: 100%;

    &__inner {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    &__items {
        flex-wrap: wrap;
        width: 100%;
        padding: 12px 12px 8px;
    }

    &__item {
        align-items: center;
        width: calc(100% / 6);
        margin-bottom: 4px;

        &Inner {
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            font-size: 20px;
            background: $colorBlueBack;
            border-radius: 50%;
            transition: $trans;

            @include button(0.94);
        }
    }
}
